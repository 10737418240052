import { TimePassed } from '@/functions/timeDiff';
import { Provider_LOGIN_STATE } from '@/layouts/beforeRender/loginState';
import { IsLike } from '@/services/forum';
import { Avatar, List, Progress, Skeleton, Typography } from 'antd';
import { PureComponent } from 'react';
import { Link } from 'umi';
import { Dislike } from "./ListVotes";
const { Text } = Typography;

export type TypeBlockItemList = {
  id: number
  section_id: number
  section_name: string
  title: string
  is_like: IsLike
  view_count: number
  comment_count: number
  like_count: number
  sticky: boolean
  good: boolean
  status: boolean
  is_locked: boolean
  create_date: Date
  latest_comment_timestamp: Date
  latest_action?: {
    type: string
    author: string
    time: Date
  }
  author?: {
    name: string
    id: number
    picture: string
  }
}

type Props = {
  list?: TypeBlockItemList[]
}
class PostListItem extends PureComponent<Props, Props> {
  constructor(props: Props) {
    super(props);
    this.state = {
    };
  }
  goPersonalInfo(e, id) {
    id.nativeEvent.stopImmediatePropagation();
    id.stopPropagation()
  }
  render() {
    return <div>
      <List
        className="forum-search-list"
        loading={!(this.props.list || this.state.list)}
        itemLayout="horizontal"
        dataSource={(this.props.list || this.state.list)}
        renderItem={item => (
          <Provider_LOGIN_STATE.Consumer>
            {CONTEXT => {
              return <List.Item key={item.id}
                >
                  <Skeleton avatar title={false} loading={!(this.props.list || this.state.list)} active>
                    
                    <List.Item.Meta
                      style={{ display: "flex", alignItems: "center" }}
                      avatar={
                        <Progress
                          type="circle"
                          width={44}
                          percent={0}
                          // percent={Math.ceil(Math.random() * 100)}
                          // strokeColor={item.themeColor}
                          // format={() =>CONTEXT.uid != item.author.id ? <Link to={{pathname:`/user`,  query: { "uid": item?.author?.id}}}><Avatar
                          format={() =>CONTEXT.uid != item.author.id ? <Link to={{pathname:`/user/${item?.author?.id}`,  query: { "uid": item?.author?.id}}}><Avatar
                          src={item?.author?.picture}
                          style={{ backgroundColor: item.themeColor}}
                          size={38}
                        /></Link> :<Link to={{pathname:`/user`}} ><Avatar src={item?.author?.picture} style={{ backgroundColor: item.themeColor}} size={38}/></Link>
                      } />
                      }
                      title={<Link className='overHidden overHidden-postListItem ' to={{pathname:`/discussions/post/${item.id}`}} style={{color:"#333333"}} >{item.title}</Link>}
                      description={<>
                        <Link to={{pathname:`/discussions/post/${item.id}`}} >
                        <span style={{color: '#333'}}>
                        <Text >
                          {item?.author?.name}
                        </Text>
                        发表于
                        <Text >
                          {item.section_name}
                        </Text>
                        , 最后由
                        <Text >
                          {item?.latest_action?.author}
                        </Text>
                        于
                        <Text >
                        {TimePassed(new Date(item?.latest_action?.time || 0), {
                              sec2JustNow: true,
                              afterfix: "前"
                            }) || "刚刚"}

                          </Text>
                          发表
                          {item?.latest_action?.type == 'post_create' ? '主题' : '评论'}
                        </span>

                      </Link>
                    </>}
                  />
                  <div className="like-icons">
                    <Dislike style={{ display: "block" }} targetId={item.id} targetType={"post"} defaultState={item.is_like} votes={item.like_count} />

                    {/* <ListVotes votes={item.like_count} type={"post"} info={item} /> */}
                    <span>{`${item.comment_count} 条评论`}</span>
                  </div>
                </Skeleton>
              </List.Item>
            }
            }
          </Provider_LOGIN_STATE.Consumer>
        )}
      />
    </div>
  }
}

export { PostListItem };

