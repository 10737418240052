import { PureComponent } from 'react';
import { history } from 'umi';
// import { APIRequest } from '../../gateway/index';
import styles from './index.less';
// import config from '../../../config';
import { getUserInfo } from '../../services/user';
import { getPowQuestion,  getSmsCodeWithPowAnswer, verifySmsCode } from '../../services/login';
import { Input, Button, Space, Checkbox, Typography, message, Form, Modal } from 'antd';
import { PrivacyPolicy, UserAgreement } from "../../layouts/textList/text"
import { NationPhone } from '@/layouts/nation-phone';
import { parsePhoneNumber } from 'react-phone-number-input'
import ReactSimpleVerify from 'react-simple-verify'
import { LoadingOutlined, CheckOutlined } from '@ant-design/icons';

import 'react-simple-verify/dist/react-simple-verify.css'

export type PowMesage = {
  done: false;
  takes: number;
  total: number;
  speed: string;
} | {
  done: true;
  takes: number;
  total: number;
  speed: string;
  result: string;
  key: string;
}
type State = {
  gobackUrl?: string;
  SMSCodeSentTime: Date
  SMSCodeSending?: boolean
  INPUT_phoneNumber?: string
  INPUT_country?: string
  INPUT_countryCallingCode?: string
  INPUT_nationalNumber?: string
  INPUT_smsCode?: string
  INPUT_Checkbox: boolean
  Authenticating?: boolean
  ModalVisible?: boolean
  /**是否显示伪人机验证滑动条 */
  showReactSlider?: boolean
  modalType?: string
  modalTitle?: string
  /**短信倒计时的时间
   * 
   * 单位 秒
   * 
   * kan0518wei, 写的老代码
   */
  time?: string | number
  smsSendCoolDownTime?: string | Date
  //pow
  powDone?: boolean
  powResolveKey?: string
  waitingPost?: boolean
};
export class LoginPage extends PureComponent<{}, State> {
  constructor(props: {}) {
    super(props);
    this.state = {
      SMSCodeSentTime: new Date(0),
      INPUT_Checkbox: false,
      ModalVisible: false,
      showReactSlider: false,
      modalType: '',
      modalTitle: '',
      time: '60'
    };
  }
  /**短信间隔发送时间 */
  SMSLimit = 60

  render() {
    return (
      <div className={styles.body} >
        <div className={styles.login} style={{ backgroundColor: "#fff" }
        }>
          <Space direction={'vertical'} size={[32, 32]} >
            <div onDoubleClick={this.cancelLogout.bind(this)}>
              <h1 style={{ textAlign: 'center', fontSize: 32, userSelect: "none" }} > {'登录'} </h1>
            </div>
            < Form
              name="normal_login"
              className="login-form"
              hideRequiredMark
              layout="vertical" >
              {/* 手机号 */}
              < Form.Item
                label=""
                style={{ display: 'none' }}
                name="phone" >
                <Input
                  disabled={this.state.Authenticating}
                  value={this.state.INPUT_phoneNumber}
                  style={{ borderRadius: 4 }}
                  placeholder={'请输入手机号'}
                  size={'large'}
                  suffix={< Button type="link" style={{ pointerEvents: 'none' }}> </Button>} />
              </Form.Item>
              < Form.Item
                label=""
                name="phone1" >
                <NationPhone
                  size="large"
                  rightStyle={{ width: `calc(100% - 89px)` }}
                  onChange={this.phone_change.bind(this)}
                />
              </Form.Item>
              < Form.Item
                label=""
                required
                name="INPUT_smsCode" >
                <Input
                  disabled={this.state.Authenticating}
                  value={this.state.INPUT_smsCode}
                  style={{ borderRadius: 4 }}
                  placeholder={'请输入验证码'}
                  size={'large'}
                  suffix={<Button
                    disabled={this.state.Authenticating || this.state.SMSCodeSending}
                    key={"smsCodeButton"} type="link"
                    onClick={this.onSendSMSButtonClick.bind(this)} >
                    {this.state.SMSCodeSending ?
                      `${(this.SMSLimit - (new Date().valueOf() - new Date(this.state.smsSendCoolDownTime || 0).valueOf()) / 1000).toFixed(0)}s后重发`
                      : '获取验证码'}</Button>}
                  onChange={(event) => {
                    this.setState({
                      INPUT_smsCode: event.target.value
                    })
                  }}
                />
              </Form.Item>
            </Form>
            {this.state.showReactSlider ? <ReactSimpleVerify ref="verify" success={this.success.bind(this)} /> : ''}
            <Checkbox
              disabled={this.state.Authenticating}
              checked={this.state.INPUT_Checkbox}
              onChange={(checked) => {
                this.setState({
                  INPUT_Checkbox: checked.target.checked
                })
              }}>
              <span style={{ fontSize: 12, textAlign: "left", display: "inherit" }}>
                <span>
                  {'本人已阅读及同意九坤投资有关「'}
                  < Button type='link' onClick={() => { this.setState({ ModalVisible: true, modalType: "PrivacyPolicy", modalTitle: '隐私政策' }) }}
                    style={{ padding: "0", fontSize: 12 }}> {"隐私政策"} </Button>
                  {'」与「'}
                  <Typography.Link>
                    <Button type='link' onClick={() => { this.setState({ ModalVisible: true, modalType: "UserAgreement", modalTitle: '用户协议' }) }} style={{ padding: "0", fontSize: 12 }}> {"用户协议"} </Button>
                  </Typography.Link>
                  {'」'}
                </span><br></br >
                {'首次登录将自动为您完成注册'}
              </span>
            </Checkbox>
            {/* <div  style={{ fontSize: 12 }}></div> */}


            {this.renderCheckingBar()}
            <Button
              loading={this.state.Authenticating}
              disabled={!(this.state.INPUT_smsCode && this.state.INPUT_phoneNumber)}
              type="primary"
              size={'large'}
              onKeyDown={(e) => this.handleKeyDown(e)}
              style={{ width: '100%' }}
              onClick={this.login.bind(this)}
            >
              {'登录/注册'}
            </Button>

          </Space>
          {
            (() => {
              if (localStorage.getItem("access-token") !== null || true) {
                return <div style={{ height: 0, opacity: 0 }}>
                  <Button
                    type="primary"
                    size={'large'}
                    style={{ width: '100%', display: "block", position: "relative", top: 50 }
                    }
                    onClick={() => {
                      localStorage.setItem("access-token", localStorage.getItem("access-token_bak") || "")
                      document.location.pathname = "/user"
                    }
                    }
                  >
                    {'撤销注销'}
                  </Button>
                </div>
              }
            })()}
        </div>
        < Modal
          title={this.state.modalTitle}
          centered
          visible={this.state.ModalVisible}
          // onOk={() => this.hidePopup()}
          width={800}
          className={'modalStyle'}
          onCancel={() => this.hidePopup()}
          destroyOnClose={true}
          footer={null}
        >
          {this.renderAuthButton()}
        </Modal>

      </div>
    );
  }

  renderCheckingBar() {
    const successStyle = {
      background: `rgb(67,184,104)`,
      borderColor: "transparent"
    }
    const progressStyle = {
      background: `rgb(67 125 184)`,
      borderColor: "transparent"
    }
    return <>
      {/* <Button type="primary" shape="round"
        icon={this.state.powDone ? <CheckOutlined /> : <LoadingOutlined />}
        style={{ pointerEvents: "none", ...this.state.powDone ? successStyle : progressStyle }}>
        {this.state.powDone ? "环境安全" : "正在检查浏览器环境..."}
      </Button> */}
      <Modal open={this.state.waitingPost && !this.state.powDone} footer={null} title={null} closable={false}>
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
          <LoadingOutlined style={{ fontSize: 48, marginBottom: 24 }} />
          <div>{"正在检查浏览器环境..."}</div>
        </div>
      </Modal>
    </>
  }
  async componentDidMount() {
    const gobackUrl = new URL(document.location.href).searchParams.get(
      'goback',
    );
    console.log('gobackUrl', gobackUrl);
    if (gobackUrl) {
      this.setState({ gobackUrl });
    }
    document.addEventListener('keydown', this.handleKeyDown);


    this.powRound()
  }
  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown);
  }
  //keyCode 38=up arrow  40=down arrow   13=Enter
  handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      console.log("按下了Enter键")
      this.login();
    }
  }

  renderAuthButton = () => {
    if (this.state.modalType === 'PrivacyPolicy') {
      return <PrivacyPolicy></PrivacyPolicy>
    } else {
      return <UserAgreement>Login </UserAgreement>
    }
  }
  phoneValidator = (_, value, callback) => {
    if (value) {
      // let reg = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
      // if (reg.test(value)) {
      this.setState({
        INPUT_phoneNumber: value
      })
      return callback();
      // }
      // return Promise.reject(new Error('手机号无效'));
    } else {
      return Promise.reject(new Error('手机号不得为空'));
    }

  }

  phone_change(phone) {
    // phoneChange
    if (phone) {
      const phoneNumberParse = parsePhoneNumber(phone) || {};
      this.setState({
        INPUT_phoneNumber: phone,
        INPUT_country: phoneNumberParse['country'],
        INPUT_countryCallingCode: phoneNumberParse['countryCallingCode'],
        INPUT_nationalNumber: phoneNumberParse['nationalNumber']
      })
    }

  }

  success() {
    const $verify = this.refs.verify as ReactSimpleVerify;
    // this.setState({
    //   SMSCodeSending: true
    // })
    this.sendSMSCode()
    // const _this = this;
    setTimeout(() => {
      $verify.reset()
      this.setState({ showReactSlider: false })
    }, 2000)
  }

  goback() {
    if (this.state.gobackUrl) {
      const currnetOrigin = document.location.origin;
      const targetOrigin = new URL(this.state.gobackUrl).origin;
      if (currnetOrigin === targetOrigin) {
        const relativePath = this.state.gobackUrl.replace(targetOrigin, '');
        history.push(relativePath);
      } else {
        document.location.href = this.state.gobackUrl;
      }
    }
  }
  async onSendSMSButtonClick() {
    const phone = this.state.INPUT_phoneNumber;
    if (!phone) {
      message.error('请先输入手机号!'); return
    }
    if (!this.state.INPUT_Checkbox) {
      message.error('请阅读并勾选确认隐私政策与用户协议'); return
    }
    if (!this.state.SMSCodeSending) {
      this.setState({ showReactSlider: true }); return
    }
  }
  async sendSMSCode() {
    const limitTime = this.SMSLimit
    const phone = this.state.INPUT_phoneNumber;


    const answer = await this.getPowAnswer()
    console.log('phone: %s', phone);
    console.log('answer: %s', answer);


    this.setState({ smsSendCoolDownTime: new Date(), SMSCodeSending: true })
    const timer = setInterval(() => {
      if (this.state.smsSendCoolDownTime) {
        if ((new Date().valueOf() - new Date(this.state.smsSendCoolDownTime).valueOf()) > limitTime * 1000) {
          clearInterval(timer)
          this.setState({ SMSCodeSending: false })
        }
      }
      this.setState({ time: performance.now() })
    }, 1000)

    // return
    try {
      let res = await getSmsCodeWithPowAnswer({
        "phone": phone || "",
        "countryCallingCode": this.state.INPUT_countryCallingCode || "",
        "nationalNumber": this.state.INPUT_nationalNumber || "",
        key: answer
      });
      console.log("getSmsCodeWithPowAnswer response", res)
    } catch (error) {
      console.log(error);
      this.setState({
        SMSCodeSending: false
      })
      return
    }

    setTimeout(() => {
      this.setState({
        //SMSCodeSending: false,
        SMSCodeSentTime: new Date()
      })
    }, 3000);

  }

  async login() {
    const phone = this.state.INPUT_phoneNumber
    const code = this.state.INPUT_smsCode
    if (!phone) {
      message.error('请先输入手机号')
      return
    }
    if (!code) {
      message.error('请输入验证码')
      return
    }
    if (!this.state.INPUT_Checkbox) {
      message.error('请阅读并勾选确认隐私政策与用户协议')
      return
    }
    this.setState({
      Authenticating: true
    })
    try {
      let response = await verifySmsCode({
        phone: phone,
        countryCallingCode: this.state.INPUT_countryCallingCode,
        nationalNumber: this.state.INPUT_nationalNumber,
        code: code
      });
      if (UF_CONFIG.auth_mode == 'jwt' && response.data && response.data.token) {
        localStorage.setItem('access-token', 'Bearer ' + response.data.token);
        console.log('success to set user session, token: %s', response.data.token);
      }
      if (!response.success) {
        this.setState({
          Authenticating: false
        })
        return
      }
      this.setState({
        Authenticating: false
      })
      message.info("登录成功")

      /** 重定向 */
      const href = `http://www.example.com${history.location.pathname}${history.location.search}`
      const redirect = new URL(href).searchParams.get("redirect")
      setTimeout(() => {
        if (redirect) {
          document.location.href = redirect
        } else {
          document.location.href = "/home"
        }
      }, 1000);
      console.log("redirecting", redirect)
      getUserInfo().then((res) => {
        console.log('success to get user info: %s', res)
      }).catch((err) => {
        console.log('user fetch err: %s', err.toString());
      });

    } catch (error) {
      this.setState({
        Authenticating: false
      })
    }

  }
  hidePopup() {
    this.setState({
      ModalVisible: false
    })
  }
  cancelLogout() {
    localStorage.setItem("access-token", localStorage.getItem("access-token_bak") || "");
    const origin = document.location.origin
    const redirect = new URL(document.location.href).searchParams.get("redirect")
    const pathname = redirect || "/user"
    document.location.href = origin + pathname
  }
  async powRound() {
    const question = await getPowQuestion()
    const { prefix, salt } = question.data

    const powWorker = new Worker("/workers/pow.js?v=4", { type: "module" });
    console.log("powWorker", powWorker)

    powWorker.postMessage({
      action: "findAnswer",
      data: {
        salt, answerPrefix: prefix
        // salt, answerPrefix: "12345"
      },
    });
    powWorker.addEventListener("message", (event: MessageEvent<PowMesage>) => {
      console.log("event,message", event.data)
      if (event.data.done) {
        this.setState({
          powDone: true,
          powResolveKey: event.data.key,
        })
        powWorker.terminate()
      }

    })



  }

  getPowAnswer(): Promise<string> {
    return new Promise((resolve, reject) => {
      if (this.state.powDone && this.state.powResolveKey) {
        resolve(this.state.powResolveKey)
        this.powRound()
        this.setState({ waitingPost: false, powResolveKey: undefined, powDone: undefined })
      } else {
        this.setState({ waitingPost: true })
        setInterval(() => {
          if (this.state.powDone && this.state.powResolveKey) {
            resolve(this.state.powResolveKey)
            this.powRound()
            this.setState({ waitingPost: false, powResolveKey: undefined, powDone: undefined })
          }
        }, 100)
      }
    })
  }

}

export default LoginPage;
