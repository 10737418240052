import { Icon } from "@/functions/IconLab";
import { TimePassed } from "@/functions/timeDiff";
import { Provider_LOGIN_STATE } from '@/layouts/beforeRender/loginState';
import { DiscussionSubComment } from '@/types/comment';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Avatar, Button, List, Modal, Tag } from 'antd';
import { PureComponent } from 'react';
import { Link } from "umi";
import { deleteComment } from "../../../../services/forum";
import { Dislike } from "../../ListVotes";
import { CommentForEdit, CommentModalReEdit } from '../reedit';

import "./sub.less";
type SubCommentState = {
  editing?: CommentForEdit
}

type SubCommentProps = {
  /**主楼id */
  tid: number
  /**楼层id, 一级评论id */
  parentCommentId: number
  data: DiscussionSubComment[]
  onChange: () => void
}
export class SubCommentRender extends PureComponent<SubCommentProps, SubCommentState> {
  constructor(props: SubCommentProps) {
    super(props);
    this.state = {
    };
  }
  render() {
    if (!this.props.data || this.props.data.length === 0) {
      return ""
    }
    return <div className='SubCommentRender-main' style={{ background: "#F4F4F4", position: "relative", marginTop: 24 }}>
      <div style={{ background: "#F4F4F4", height: 14, width: 14, position: "absolute", top: -7, left: 14, transform: "rotate(45deg)" }}></div>
      <List
        className="SubCommentRender_list"
        loading={!this.props.data}
        itemLayout="vertical"
        dataSource={this.props.data}
        renderItem={(item, itemId) => <Provider_LOGIN_STATE.Consumer>
          {CONTEXT => {
            const replyToText = item.reply ? `回复 @${item.reply.name}: ` : ""
            const content = `${replyToText}${item.content}`

            const TimeDisplays = <>{TimePassed(new Date(item.created || 0), { sec2JustNow: true, afterfix: "前" }) || "刚刚"}</>
            const Reply = <Button type="text" icon={<Icon id="MessageFilled" />} onClick={() => this.action(item, "reply")}>{"回复"}</Button>
            const Delete = <Button type="text" icon={<Icon id="DeleteFilled" />} onClick={() => this.action(item, "delete")}>{"删除"}</Button>
            const Edit = <Button type="text" icon={<Icon id="EditFilled" />} onClick={() => this.action(item, "edit")}>{"编辑"}</Button>

            return <>
              {itemId !== 0 ? <div style={{ height: 2, background: "#D9D9D9", marginLeft: 32, marginRight: 32 }} /> : undefined}
              <List.Item className="SubCommentRender_list" id={item.id}>
                <List.Item.Meta
                  avatar={
                    <div style={{ position: "relative", textAlign: 'center' }}>
                      {CONTEXT.uid != item.author.id ? <Link to={{ pathname: `/user/${item?.author?.id}`, query: { "uid": item?.author?.id } }}><Avatar
                        src={item?.author?.picture}
                        size={50}
                      /></Link> : <Link to={{ pathname: `/user` }} ><Avatar src={item?.author?.picture} size={50} /></Link>
                      }
                      <br></br>
                      <Tag style={{ margin: 5 }}><span>{item.author.name}</span></Tag>
                      <br></br>
                      <Tag style={{ margin: 5 }}><span>{item.author.tiers}</span></Tag>
                    </div>
                  }
                  title={<div style={{ marginRight: 10, position: "relative", width: "100%" }}>
                    {TimeDisplays}
                    {CONTEXT.login ? Reply : undefined}
                    {CONTEXT.login && CONTEXT.uid === item.author.id ? Delete : undefined}
                    {CONTEXT.login && CONTEXT.uid === item.author.id ? Edit : undefined}
                    <Dislike style={{ position: "absolute", right: 25 }} targetId={item.id} targetType={"comment"} defaultState={item.is_like} votes={item.like_count} />
                  </div>}
                  description={<>
                    <div className="SubCommentRender_item_content"
                      style={{ display: 'flex', color: '#666', wordBreak: "break-all", flexDirection: "column", fontSize: 16, }}
                      dangerouslySetInnerHTML={{ __html: content }} />
                  </>}
                >
                </List.Item.Meta>
              </List.Item>
            </>
          }}
        </Provider_LOGIN_STATE.Consumer>}
      />
      <CommentModalReEdit
        editing={this.state.editing}
        onCancel={this.clearEditing.bind(this)}
        onDone={this.onEditDone.bind(this)}
      />
    </div>
  }
  private action(comment: DiscussionSubComment, action: "reply" | "delete" | "edit") {
    console.log("comment", comment)
    switch (action) {
      case "delete":
        Modal.confirm({
          getContainer: '#root',
          icon: <ExclamationCircleOutlined />,
          content: '是否确认删除当前评论？',
          okText: '确认',
          cancelText: '取消',
          onOk: async () => {
            await deleteComment(this.props.tid, comment.id)
            this.props.onChange()
          }
        });
        break;
      case "edit":
        this.setState({
          editing: {
            behavior: "edit",
            content: comment.content,
            commentId: comment.id,
            postId: this.props.tid,
          }
        })
        break;
      case "reply":
        this.setState({
          editing: {
            behavior: "new",
            content: "",
            commentId: this.props.parentCommentId,
            postId: this.props.tid,
            replyToUserId: comment.author.id,
          }
        })
        break;
      default:
        break;
    }
  }
  clearEditing() {
    this.setState({ editing: undefined })
  }
  onEditDone() {
    this.setState({ editing: undefined })
    this.props.onChange()
  }
}

