import { PureComponent } from 'react';
import { Row, Col,Divider,Space } from 'antd';
// import * as 九坤logo from '../../../public/九坤logo-标准版.png';
import { Typography, List, Skeleton, Avatar, Dropdown } from 'antd';

import { SmileOutlined, MailOutlined,LinkedinOutlined } from '@ant-design/icons';
import BottomFooter from './../footer/bottomContent'
import "./index.less"
import ForumFooter from '../footer';
import React from 'react';

const { Title, Text, Link } = Typography;

type State = {
  voted?: boolean
}


const ForumFooterBlack = ()=>{
  return <ForumFooter black={true}/>
}
class ForumFooter2 extends PureComponent<{}, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
    };
  }
  onVote() {
    this.setState({
      voted: !this.state.voted
    })
  }
  render() {
    return <>
  <div className="forum-footer">
    <div className="forum-footer-box">
      <div className="box-left">
        <img src="/assets/images/homepage/jiukun-logo.png" alt="" />
      </div>
      <div className="box-right">
          <div className="forum-foot-container">
            <div className="f-item">
              <Link href="https://www.ubiquant.com" target="_blank">
                九坤官网
              </Link>
            </div>
            <div className="f-item">
              <Link href="https://ubiquant.gllue.com/portal/socialposition" target="_blank">
                社会招聘
              </Link>
            </div>
            <div className="f-item">
              <Link href="https://ubiquant.gllue.com/portal/campusposition " target="_blank">
                校园招聘
              </Link>
            </div>
          </div>
      </div>
    </div>
    <Divider className="home-footer-divider" />
    <div className="forum-footer-box">
      <div className="box-left">
        <div className="footer-wechat-outer">
          <div className="wechat-item">
            <div className="footer-wechat-box">
              <img src="/assets/images/hire-assisant.png" alt="" />
              <p>九坤招聘公众号</p>
            </div>
          </div>
          <div className="wechat-item">
            <div className="footer-wechat-box">
              <img src="/assets/images/wechat-subscribe.png" alt="" />
              <p>坤坤招聘助手</p>
            </div>
          </div>
        </div>
      </div>
      <div className="box-right">
          <div className="forum-foot-container">
            <div className="f-item">
              <Link href="mailto:hackathon@ubiquant.com" target="_blank">
                <div className="footer-contact-box">
                    <div className="ft-box-circle">
                      <MailOutlined  className="footer-icon-circle" style={{ fontSize: '18px', color: 'white' }} />
                    </div>
                    <div className="ft-box-contact">
                      <p>赛事答疑</p>
                      <p>hackathon@ubiquant.com</p>
                    </div>
                </div>
              </Link>
            </div>
            <div className="f-item">
              <Link href="mailto:recruiter@ubiquant.com" target="_blank">
                <div className="footer-contact-box">
                  <div className="ft-box-circle">
                    <MailOutlined  className="footer-icon-circle"  style={{ fontSize: '18px', color: 'white' }} />
                  </div>
                  <div className="ft-box-contact">
                    <p>招聘答疑</p>
                    <p>recruiter@ubiquant.com</p>
                  </div>
                </div>
              </Link>
            </div>
            <div className="f-item">
              <div className="footer-contact-box">
                <Link href="https://www.linkedin.com/company/%E4%B9%9D%E5%9D%A4%E6%8A%95%E8%B5%84-%E5%8C%97%E4%BA%AC-%E6%9C%89%E9%99%90%E5%85%AC%E5%8F%B8/" target="_blank">
                  <div className="ft-box-circle">
                    <LinkedinOutlined className="footer-icon-circle" style={{ fontSize: '17px', color: 'white' }} />
                  </div>
                  <div className="ft-box-contact">
                    <p>领英</p>
                    <p>linkedin/九坤投资</p>
                  </div>
                </Link>
              </div>
            </div>
          </div>
      </div>
    </div>
  </div>

  <BottomFooter name='forum-footer-box'></BottomFooter>
</>
  }
}

export default ForumFooterBlack
