import { Avatar, Button, Input, message, Modal, Progress, Select, Tabs, Tag, Typography, Upload } from 'antd';
import BraftEditor from 'braft-editor';
// 引入编辑器样式
import { Provider_LOGIN_STATE } from '@/layouts/beforeRender/loginState';
import store from '@/redux/store/store';
import { PictureOutlined } from '@ant-design/icons';
import 'braft-editor/dist/index.css';
import { ContentUtils } from 'braft-utils';
import { PureComponent } from 'react';
import { history, Link } from 'umi';
import { createForumPost, editPost, getForumSection } from '../../../../src/services/forum';
import { User } from "../../../functions/UserCentral/type";
import {
  API_uploadImage
} from "../../../services/files";
import './global.less';
import "../../../component/Editor/content.less"
import styles from './index.less';



type TypeBlockItemList = {
  title: string
  postTitle: string
  userName: string
  votes: number
  commentsCount: number
  themeColor: string
  icon?: URL
  description: string
  article: string
  lastPost?: {
    time: Date
    user: User
  }
  topics?: {
    user: User
  }[]
}

type Section = {
  name: string
  id: number
}
type ImgListType = {
  name: string
  uid: string
  status: string
  url: string
  imgUrl: string
}
type State = {
  list?: TypeBlockItemList[]
  sections?: Section[]
  sectionId?: number
  sectionTitle?: string
  editorState?: any
  sending?: boolean
  imgList?: ImgListType
  previewVisible?: boolean
  previewImage?: string
}


class Page extends PureComponent<{}, State> {
  editorInstance?: BraftEditor | null;
  constructor(props: {}) {
    super(props);
    console.log('props.match.params.sid: ', props.match.params.sid);
    let sectionId = props.match.params.sid || '';
    var editData = {}, content = null; 
    if(history.location.query.isEdit){
      editData = JSON.parse(sessionStorage.getItem('params'));
      content = editData.article
    }

    this.state = {
      editorState: BraftEditor.createEditorState(content),
      sectionId: sectionId,
      editData: editData
    };
  }

  componentDidMount() {
    // console.log('componentDidMount props.match.params.sid: ', this.props.match.params.sid);
    console.log('componentDidMount in editor');
     this.updateInfo()
    // this.forceUpdate()
  }
  componentDidUpdate() {
    console.log('componentDidUpdate in editor');
     // if (!this.state.sectionId) {
       // this.updateInfo()
     // }
  }
  updateInfo() {
    const lists: TypeBlockItemList[] = []
    let { sections } = store.getState() 
    sections = sections || []
    if (sections.length > 0) {
       this.setSectionList(sections)
    }else{
      setTimeout(() => {
        let { sections } = store.getState()
        sections = sections || []
         this.setSectionList(sections)
      }, 2000);
    }    
  }


  async setSectionList(slists) {
    console.log("load versionList data");
    let versionList = []
    if (slists.length > 0) {
      versionList = slists
    }else{
      const res = await getForumSection();
      versionList = res.data;
    }
    // const versionList = slists.length > 0 ?  slists : (await getForumSection())
    console.log("versionList", versionList);
    // const versionListData = versionList.data
    // const versionListId = []
    const sections: Section[] = Array.from(versionList, (item) => {
      return {
        name: item.name,
        id: item.id
      }
    })

    let sectionTitle = ''
    let sectionId = ''
    for(let st of versionList){
      if (st.id == this.props.match.params.sid) {
        // sectionTitle = st.name
        sectionId = st.id
      }
    }
    // 主题编辑
    if(history.location.query.isEdit){
      sectionTitle = this.state.editData.title;
      sectionId = this.props.match.params.sid;
    }
    // 比赛 谈论区创建 id
    if(this.props.location.query.from === "competition" ) {
      sectionId = this.props.match.params.sid
    }
    this.setState({
      sections,
      sectionId,
      sectionTitle,
    })
    // this.forceUpdate()
  }
  async sendPost() {
    const html = this.state.editorState.toHTML() || ""
    // const postBosy = btoa(encodeURIComponent(html))
    if(html === '<p></p>') {
      message.warn('请先输入内容');
      return
    }
    this.setState({
      sending: true
    })
    console.log("html", html)
    let returnId = 0
    try {
      if (this.state.sectionTitle && this.state.sectionId) {
        // 主题编辑
        if(history.location.query.isEdit){
          const returnContent = await editPost({
            "title": this.state.sectionTitle,
            "content": html,
            "section": this.state.sectionId  //版块ID
          }, this.state.editData.tid)
          returnId = returnContent.data.id;
          sessionStorage.setItem('content', JSON.stringify({
            "title": this.state.sectionTitle,
            "content": html
          }))
          message.info("修改成功");
          history.replace(`/discussions/post/${returnId}?rouondTime=${new Date().getTime()}`)
        } else {
          const returnContent = await createForumPost({
            "title": this.state.sectionTitle,
            "content": html,
            "section": this.state.sectionId  //版块ID
          })
          // console.log(returnContent.data.id);
          returnId = returnContent.data.id;
          message.info("创建完成");
          console.log(returnId);
          // 比赛 谈论区创建 id
          // if(this.props.location.query.from === "competition") {
          //   history.goBack()
          // } else {
            history.push(`/discussions/post/${returnId}`)
          // }
        }
      }
    } catch (error) {
      message.info("创建失败")
      this.setState({
        sending: false
      })
      return
    }

  }

  handleCancel = () => this.setState({ previewVisible: false });

  // 方法：图片预览
  handlePreview = (file) => {
    console.log(' handlePreview:' + JSON.stringify(file));
    this.setState({
      previewImage: file.url || file.thumbUrl,
      previewVisible: true,
    });
  };

  // 参考链接：https://www.jianshu.com/p/f356f050b3c9
  handleBeforeUpload = (file) => {
    console.log(' handleBeforeUpload file:' + JSON.stringify(file));
    console.log(' handleBeforeUpload file.file:' + JSON.stringify(file.file));
    console.log(' handleBeforeUpload file type:' + JSON.stringify(file.type));

    //限制图片 格式、size、分辨率
    const isJPG = file.type === 'image/jpeg';
    const isJPEG = file.type === 'image/jpeg';
    const isGIF = file.type === 'image/gif';
    const isPNG = file.type === 'image/png';
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!(isJPG || isJPEG || isPNG)) {
      Modal.error({
        getContainer: '#root',
        title: '只能上传JPG、JPEG、PNG格式的图片~',
      });
    } else if (!isLt2M) {
      Modal.error({
        getContainer: '#root',
        title: '图片超过2M限制，不允许上传~',
      });
    }
    return (isJPG || isJPEG || isPNG) && isLt2M;
  };

  doImgUpload = (options) => {
    const { onSuccess, onError, file, onProgress } = options;
    console.log('calling doImgUpload');
    console.log(file);
    console.log(file.name);
    // start：进度条相关

    const reader = new FileReader();
    // reader.readAsBinaryString(file); // 读取图片文件
    // reader.readAsDataURL(file); // 读取图片文件
    reader.readAsArrayBuffer(file); // 读取图片文件

    reader.onload = (progress) => {
      console.log("progress", progress);
      // console.log(file.name);
      // console.log(progress.target.result);

      // 上传图片的binariy编码，调接口后，返回 imageId
      API_uploadImage(progress.target.result, encodeURIComponent(file.name))
      // uploadImage(file, file.name)
        .then((res) => {
          console.log(res);
          console.log('doImgUpload:' + JSON.stringify(res));
          if (res.success && res.data.uri.length < 1) {
            console.log('图片上传失败:' + JSON.stringify(res));
            message.error('图片上传失败，请重试');
          }else{
            let image_uri = res.data['uri'][0]['url'];
            console.log('图片上传成功：' + image_uri);
            let image_url = UF_CONFIG.api_url + image_uri;
            const imgItem = {
                uid: file.uid,
                name: file.name,
              status: 'done',
              url: image_url, // url 是展示在页面上的绝对链接
              imgUrl: image_url, // imgUrl 是存到 db 里的相对链接
              // response: '{"status": "success"}',
            };
            this.setState({
              imgList: [imgItem],
            }); // 更新 imgList
            this.setState({
              editorState: ContentUtils.insertMedias(this.state.editorState, [{
                type: 'IMAGE',
                url: image_url
              }])
            })
          }
        })
        .catch((e) => {
          console.log('smyhvae 图片上传失败:' + JSON.stringify(e || ''));
          message.error('图片上传失败，请重试');
        });
    };
  };

  handleChange = ({ file, fileList }) => {
    console.log('smyhvae handleChange file:' + JSON.stringify(file));
    console.log('smyhvae handleChange fileList:' + JSON.stringify(fileList));

    if (file.status == 'removed') {
      this.setState({
        imgList: [],
      });
    }
  };

  handleEditorChange = (editorState) => {
    console.log(this.state, 'wqewq');
    
    this.setState({ editorState })
  }

  render() {
    const extendControls = [
      {
        key: 'antd-uploader',
        type: 'component',
        component: (
          <Upload
            accept="image/*"
            showUploadList={false}
            customRequest={this.doImgUpload}
            fileList={this.state.imgList}
            onPreview={this.handlePreview}
            beforeUpload={this.handleBeforeUpload}
            onChange={this.handleChange}
          >
{/*            <button type="button" className="control-item button upload-button" data-title="插入图片">
              <Icon type="picture" theme="filled" />
            </button>
*/}            
            <button type="button" className="control-item button upload-button" data-title="插入图片">
              <PictureOutlined />
            </button>
          </Upload>
        )
      }
    ]    
    const controls = [
      // 'headings',
      'undo', 'redo', 'separator',
      'font-size', 'line-height', 'letter-spacing', 'separator',
      'text-color', 'bold', 'italic', 'underline', 'strike-through', 'separator',
      'superscript', 'subscript', 'remove-styles', 'emoji', 'separator', 'text-indent', 'text-align', 'separator',
      'list-ul', 'list-ol', 'blockquote', 'code', 'separator',
      'link', 'separator', 'hr', 'separator',
      // 'media',
      'separator',
      'clear'
    ]

    const first = this.state.list?.shift()
    const { Option } = Select;
    const hooks = {
      'toggle-link': ({ href, target }) => {
          href = href.indexOf('http') === 0 ? href : `http://${href}`;
          // target = '_blank'
          return { href, target }
      }
    }
    return <Provider_LOGIN_STATE.Consumer>
      {CONTEXT => {
        return (
          <div className={styles["discussions_post_new-main"]} style={{ paddingLeft:  20,paddingRight:  20 }}>
            <div id="post_content_main">
              <sub>
                <Progress
                  type="circle"
                  width={64}
                  // percent={Math.ceil(Math.random() * 100)}
                  percent={0}
                  format={() => <Link to={{pathname:`/user/${CONTEXT.id}`,  query: { "uid": CONTEXT.id}}}><Avatar
                    src={CONTEXT.avatar.href}
                    size={50}
                  /></Link> } />
                <Tag style={{ margin: 5 }}>
                    <span>{CONTEXT?.userName}</span></Tag>
                  <Tag style={{ margin: 5 }}>
                    <span>{CONTEXT?.tiers}</span></Tag>
              </sub>
              <section>
                <Input maxLength={80} showCount={true} placeholder="主题标题" value={this.state.sectionTitle} onChange={((event) => {
                  this.setState({
                    sectionTitle: event.target.value.trim()
                  })
                  console.log(event.target.value);
                })}>
                </Input>
                
                <Select placeholder="选择版块" 
                  fieldNames={{ label: "label", value: "value", options: "options" }} 
                  value={this.state.sectionId} 
                  // labelInValue={true}
                  options={Array.from(this.state.sections || [], (item,) => {
                    return {label: item.name, value: item.id}
                    })}
                  style={{ width: 120, display: ((history.location.query.from || history.location.query.isEdit) ? "none": "block")}} onChange={((value) => {
                    this.setState({
                      sectionId: value
                    })
                })}>
                </Select>
                <header>
                  <div >
                    <BraftEditor
                      value={this.state.editorState}
                      className={"comment_BraftEditor"}
                      placeholder={"向社区分享一般公告、资源或进行有趣的讨论。"}
                      // ref={instance => this.editorInstance = instance}
                      extendControls={extendControls}
                      controls={controls}
                      hooks={hooks}
                      onChange={this.handleEditorChange}
                    />
                  </div>
                </header>
                <footer>
                  <div style={{ backgroundColor: '#fff' }}>
                    <span style={{color: '#808695',marginLeft: 15}}>此主题一经发布即公开。</span>
                    {/* <div>发布主题</div> */}
                    <Button
                  loading={this.state.sending}
                      shape="round"
                      size={'large'}
                      onClick={this.sendPost.bind(this)}
                      disabled={this.state.sectionTitle && this.state.sectionId ? false : true}>
                       {history.location.query.isEdit? "更新主题": "发布主题"}
                    </Button>
                  </div>
                </footer>

              </section>
            </div>
          </div>
        );
      }}
    </Provider_LOGIN_STATE.Consumer>

  }
}



export default Page
