import request, { ErrorCode } from '@/utils/request';

// 获取用户token for debug, 
export async function getHomeInfo() {
  return request('/api/home/info',
    {
      method: 'get',
    });
}

