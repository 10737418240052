import React from 'react';
// import PropTypes from 'prop-types';
import {CaretRightOutlined} from '@ant-design/icons';
import './index.less'
// 展开收起组件
class ArrowSlide extends React.Component {
  static defaultProps = {
    itemLable: true, // 是否展开
    itemsName: '' // 检查项目名称
  };
  constructor(props) {
    super(props);
    const { itemLable } = props; // 是否展开收起列表项
    this.state = {
      itemLable
    };
  }

  /**
   * 展开收起切换
   * @memberof EleItem
   */
  handleToggleCondition = () => {
    const { itemLable } = this.state;
    this.setState({ itemLable: !itemLable });
  }

  render() {
    const { itemsName } = this.props;
    const { itemLable } = this.state;

    return (
      <div style={{ width: '100%'}}>
        <div style={{ transition: 'all .3s', maxHeight: itemLable ? '10000px' : '0px', overflow: 'hidden' }}>{this.props.children}</div>
        <div className="arrow-alide" onClick={this.handleToggleCondition}>
          {itemLable ? '收起'  : '展开'} <CaretRightOutlined rotate={itemLable ? 270 : 90} />
        </div>
      </div>
    );
  }
}

export default ArrowSlide;