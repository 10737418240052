import request from '@/utils/request';
import { API } from './default';

type GetSmsCodeParams = {}

// 比赛排名
export async function getCompetictionList(params): Promise<API<{}>> {
  return request(`/api/user/competiction-points?size=${String(params.size)}&page=${String(params.page)}`,
    {
      method: 'GET',
    });
}

// 论坛积分排名
export async function getForumpPointsList(params): Promise<API<{}>> {
  return request(`/api/user/forum-points?size=${String(params.size)}&page=${String(params.page)}`,
    {
      method: 'GET',
    });
}

// 论坛积分排名
export async function getListCount(): Promise<API<{}>> {
  return request(`/api/v1/site/degree`,
    {
      method: 'GET',
    });
}

