import request, { ErrorCode } from '@/utils/request';
import { API } from './default';
import { UserInfo } from '@/types/user';


export type APIPages<T> = {
  totalNum: number;
  page: number;
  size: number;
  data: T[];
}



// 获取用户信息接口
export async function getUserInfo(): Promise<API<UserInfo>> {
  return request('/api/user/infoNoCache', {
    method: 'post',
    data: {}
  });
}
// 获取用户信息接口2
export async function getUserInfo2(): Promise<{
  success: boolean,
  errorCode: ErrorCode,
  message: string,
  data: {
    uid: string,
    full_name: string,
    phone: string,
    email: string
    picture: string
    points: string
    join_time: string
  }
}> {
  return request('/api/user/info');
}


type NewUserInfo = { email: string } | { full_name: string } | { phone: string, code: string } | { user_picture: number }
// 修改用户信息接口
export async function updateUserInfo(input: NewUserInfo): Promise<API<{}>> {
  return request('/api/user/info', {
    method: 'POST',
    data: input,
  });
}

// 获取用户token for debug, 
export async function getUserToken(uid: string) {
  return request('/uf_api/auth/' + uid,
    {
      method: 'get',
    });
}

/**获取用户所有参加的比赛 */
export async function getUserCompetictions(): Promise<API<{
  success: boolean,
  errorCode: ErrorCode,
  message: string,
  data: {
    totalNum: number,
    page: number,
    size: number,
    data: any,
  }

}>> {
  return request(`/api/user/competictions`);
}


export type Posts = {
  "id": number
  "section_id": number
  "section_name": string
  "title": string
  "comment_count": number
  "like_count": number
  "sticky": boolean,
  "good": boolean,
  "is_locked": boolean,
  "create_date": string
  "latest_comment_timestamp": string
  "new_message": 0 | 1
}
/**获取用户所有帖子接口 */
export async function getUserPosts(params): Promise<API<Posts[]>> {
  return request(`/api/user/posts`, { method: 'GET', params: params });
}
// 获取用户token for debug, 
export async function getInvitationLists() {
  return request('/api/user/invitations',
    {
      method: 'get',
    });
}


export interface Competition_range_array {
  position: number;
  total: number;
}

export interface All_award {
  bronze: number;
}

export interface simpAward {
  bronze?: number;
  silver?: number;
  gold?: number;
}

export interface Forum_range_array {
  position?: number;
  total?: number;
}

export type UserPublicInfo = {
  uid: number;
  full_name: string;
  picture: string;
  points: number;
  competition_points: number;
  competition_range: string;
  competition_range_array: Competition_range_array;
  all_awards: All_award;
  com_awards?: simpAward;
  dis_awards: simpAward;
  forum_points: number;
  forum_range: string;
  forum_range_array: Forum_range_array;
  tiers: string;
  tiers_image_key: number;
  join_time: string;
}


// 获取用户信息,
export async function getPersonalInfo(uid: number): Promise<API<UserPublicInfo>> {
  return request(`/api/user/info-public/${uid}`,
    {
      method: 'get',
    });
}



export type UserCompetition = {
  /**comp 比赛orgid */
  _id: string;
  created: string;
  competition_id: string;
  competition_Name: string;
  position: number;
  competition_users_count: number;
  competition_teams_count: number;
  award: Award;
}


// 获取用户比赛列表数据,
export async function getCompetictionList(params: {
  uid: number, page?: number, size?: 10
}): Promise<API<APIPages<UserCompetition>>> {
  return request(`/api/user/competiction-sort`,
    {
      method: 'get',
      params: {
        ...params,
      }
    });
}


export type Award = {
  metal_type: number;
  metal_text: string;
  post_id: string;
  created: string;
} | ""

export type UserActivityDataAtDiscussion = UserActivityDataAtDiscussion_Post | UserActivityDataAtDiscussion_Comment

export type UserActivityDataAtDiscussion_Post = {
  award: Award;
  comment_count: number
  create_date: string
  good: boolean
  id: number
  is_locked: boolean
  like_count: number
  sticky: boolean
  title: string
  type: "post"
  uid: number
}
export type UserActivityDataAtDiscussion_Comment = {
  award: Award;
  comment_body: string
  create_date: string
  id: number
  like_count: number
  post_id: number
  post_title: string
  subject: null
  type: "comment"
  uid: number
}


// 获取用户论坛活跃数据
export async function getPostList(params: {
  // uid: number,
   page?: number, size?: number
}): Promise<API<UserActivityDataAtDiscussion[]>> {
  return request(`/api/user/post_sorts`,
    {
      method: 'get',
      params
    });
}
