import { Icon } from '@/functions/IconLab';
import { Input, Typography } from 'antd';
import { PureComponent } from 'react';
import ReactMarkdown from "react-markdown"
import remarkMath from 'remark-math'
import rehypeKatex from 'rehype-katex'
import gfm from 'remark-gfm'
import './index.less';
import { Row, Col, Tabs, Steps, Popover, Button, message } from 'antd';
import { Link } from 'umi';
import clockSvg from '@/pages/icons/com-detail/clock.svg';
import jiukunLogoSvg from '@/pages/icons/com-detail/jiukun-logo-2.svg';
import scheduleSvg from '@/pages/icons/com-detail/schedule.svg';
import starSvg from '@/pages/icons/com-detail/star.svg';
import { StarFilled } from '@ant-design/icons';
import submitTimesSvg from '@/pages/icons/com-detail/submit-times.svg';
import summarySvg from '@/pages/icons/com-detail/summary.svg';
import teamSvg from '@/pages/icons/com-detail/team.svg';
import userSvg from '@/pages/icons/com-detail/user.svg';
import IconAnt from '@ant-design/icons';
import { Provider_LOGIN_STATE } from '@/layouts/beforeRender/loginState';
import Timer from '@/component/Timer';
import { FlashingButton } from '@/component/FlashingButton';
import RankList from "./rankList/index";
// 讨论区
import Zone from "../../../discussions/zone"
import "./global.less";
import moment from 'moment';
import 'katex/dist/katex.min.css'

const { Step } = Steps;
const { TabPane } = Tabs;
// 拷贝方法
import { copy } from '@/functions/copy';
const { TextArea } = Input;
const customDot = (dot, { status, index }) => (
  <Popover
    content={
      <span>
        step {index} status: {status}
      </span>
    }
  >
    {dot}
  </Popover>
);
const { Title } = Typography;

type State = {
  is_focus: boolean
}
class Page extends PureComponent<{}, State> {
  constructor(props: {}) {
    super(props);
    this.state = {
      sections: [],
      is_focus: false
    };
  }
  render() {
    const Competition = this.props.info?.Competition;
    function LinkRenderer(props: any) {
      return (
        <a href={props.href} target="_blank" rel="noreferrer">
          {props.children}
        </a>
      );
    }
    function IframeRenderer(props: any) {
      var strFilter = ".jpeg|.gif|.jpg|.png|.bmp|.pic|.svg|", dom = null;
      if (props.src.indexOf(".") > -1) {
        var p = props.src.lastIndexOf(".");
        var strPostfix = props.src.substring(p, props.src.length) + '|';
        strPostfix = strPostfix.toLowerCase();
        if (strFilter.indexOf(strPostfix) > -1) {
          console.log(props, 'img')
          dom = (<img src={props.src} width="100%">{props.children}</img>)
        } else {
          console.log(props, 'iframe')
          dom = (
            // <iframe src={props.src} scrolling="no" border="0" frameborder="no" framespacing="0" allowfullscreen="true">{props.children}</iframe>
            // <video src={props.src} style={{width: "100%", }}></video>
            <video style={{ width: '90%' }} controls><source src={props.src} /></video>
          );
        }

      }
      return (dom)

    }
    type Props = {
      children: Node,
    };
    const _this = this;
    return (

      <Provider_LOGIN_STATE.Consumer>
        {CONTEXT => {
          return <div className="com-detail-fields">
            <div className="filed-top">
              <Row className="forum-center">
                <Col span={12} >
                  <div className="flex-container">
                    <div className="field-logo">
                      <IconAnt className="uf-icon" component={jiukunLogoSvg} />
                      <span className="text">九坤投资</span>
                    </div>
                    <div className="field-status">
                      <IconAnt className="uf-icon uf-icon-two" component={scheduleSvg} />
                      <span className="text">{new Date(Competition?.StartDate) - new Date() > 0 ? '比赛未开始' : new Date(Competition?.EndDate) - new Date() > 0 ? '比赛进行中' : '比赛已结束'}</span>
                    </div>
                  </div>
                </Col>

                <Col span={12} style={{ textAlign: "right" }}>
                  <div className="detail-right-block">
                    <div className="field-submit">
                      {/* <Link to={{ pathname: "/redirecToUrl", query: { "url": this.props?.info.single_signon_link } }}  style={{display:"block"}} >sadasd</Link> */}
                      {this.props?.info.join ? <>
                        <Link
                          onClick={(event) => {
                            //@ts-ignore
                            const info = this.props.info as any
                            if (info && info.Competition && info.Competition.optionValue && info.Competition.optionValue.temp_apply_link) {
                              event.stopPropagation()
                              event.preventDefault()
                              document.location.href = info.Competition.optionValue.temp_apply_link
                            }
                          }}

                          to={{ pathname: "/competition/item/submit", query: { "org_id": this.props?.info._id, "edite": true } }} style={{ display: "block" }}>
                          <div style={{ marginRight: 14, backgroundColor: "#FFF", border: "1px solid #176EDE", color: "#176EDE", height: 40, width: 120, textAlign: "center", lineHeight: "40px", borderRadius: 3 }}>
                            {"修改报名信息"}
                          </div>
                        </Link>
                        <a
                          href={this.props?.info.single_signon_link}
                          target="_blank"
                          style={{ display: "block" }}>
                          <FlashingButton style={{ marginRight: 14, width: 104 }}>
                            {"前往比赛"}
                          </FlashingButton>
                        </a> </> : <> {this.props.info?.is_able_register ?
                          <> <Link
                            onClick={(event) => {
                              //@ts-ignore
                              const info = this.props.info as any
                              if (info && info.Competition && info.Competition.optionValue && info.Competition.optionValue.temp_apply_link) {
                                event.stopPropagation()
                                event.preventDefault()
                                document.location.href = info.Competition.optionValue.temp_apply_link
                              }
                            }}
                            to={{ pathname: "/competition/item/submit", query: { "org_id": this.props?.info._id } }} style={{ display: "block" }}>
                            <FlashingButton style={{ marginRight: 14, width: 166 }}>
                              {"提交报名信息"}
                            </FlashingButton>
                          </Link></> : <></>}
                      </>}
                      <div className="star-box">
                        {!this.state.is_focus ? <IconAnt className="uf-icon uf-icon-start" onClick={() => { this.setState({ is_focus: true }) }} component={starSvg} /> :
                          <StarFilled style={{ color: "#ffc20e" }} onClick={() => { this.setState({ is_focus: false }) }} className="uf-icon uf-icon-start" />}
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="field-bottom">
              <div className="flex-container">
                <div className="flex-left">
                  <div className="field-statics">
                    <ul>
                      <li>
                        <div className="field-stat-icon">
                          <IconAnt className="uf-icon" style={{ width: '35px' }} component={teamSvg} />
                        </div>
                        <div className="field-stat-text">
                          <div className="stat-number">{Competition?.TeamsNumber}</div>
                          <div className="stat-text">参赛队伍</div>
                        </div>
                      </li>
                      <li>
                        <div className="field-stat-icon">
                          <IconAnt className="uf-icon" component={userSvg} />
                        </div>
                        <div className="field-stat-text">
                          <div className="stat-number">{Competition?.UsersNumber}</div>
                          <div className="stat-text">参赛选手</div>
                        </div>
                      </li>
                      <li>
                        <div className="field-stat-icon">
                          <IconAnt className="uf-icon" component={submitTimesSvg} />
                        </div>
                        <div className="field-stat-text">
                          <div className="stat-number">{Competition?.WorksNumber}</div>
                          <div className="stat-text">提交次数</div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="flex-right forum-align-right">
                  <div className="detail-time-box">
                    <div className="left-clock">
                      <IconAnt className="uf-icon" component={clockSvg} /> <br />
                      {new Date(Competition?.RegisterEndDate) - new Date() > 0 ? '报名截止时间' : '比赛结束时间'}
                    </div>
                    <div className="right-text">
                      {new Date(Competition?.RegisterEndDate) - new Date() > 0 ? <Timer time={(Competition?.RegisterEndDate)} /> : <Timer time={(Competition?.EndDate)} />}
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div className="com-detail-content">
              <Tabs tabPosition="top" className="main-tab">
                <TabPane tab="简要" key="1" className="main-pane">
                  <div className="content-padding">
                    <div className="summary-title">
                      <IconAnt className="uf-icon" component={summarySvg} />
                      简要
                    </div>
                    <div className="summary-tab" style={{ minHeight: 230 }}>
                      <Tabs type="card" style={{ paddingTop: 10 }} tabPosition="left" className="sub-tab">
                        {Array.from(Competition?.Tabs || [], (card, cardKey) => {
                          return <TabPane style={{ overflowY: 'auto', height: '100%' }} tab={card.Title} key={card._id}>
                            <ReactMarkdown components={{ a: LinkRenderer, img: IframeRenderer }} remarkPlugins={[remarkMath, gfm]} rehypePlugins={[rehypeKatex]}>
                              {card.Content}
                            </ReactMarkdown>
                          </TabPane>
                        })}
                      </Tabs>
                    </div>
                    <div className="detail-timeline">
                      {(() => {
                        const now = new Date().valueOf()
                        let current = 0
                        let percent: number | undefined = 0
                        const StartDate = new Date(Competition?.StartDate).valueOf()
                        const RegisterEndDate = new Date(Competition?.RegisterEndDate).valueOf()
                        const EndDate = new Date(Competition?.EndDate).valueOf()
                        if (now > StartDate) {
                          current = 1
                          let passed = (now - StartDate)
                          let total = (RegisterEndDate - StartDate)
                          percent = (passed / total) * 100
                        }
                        if (now > RegisterEndDate) {
                          current = 2
                          let passed = (now - RegisterEndDate)
                          let total = (EndDate - RegisterEndDate)
                          percent = (passed / total) * 100
                        }
                        if (now > EndDate) {
                          current = 3
                          percent = undefined
                        }
                        return <>
                          {/* <div>{"now"}{now}</div>
                          <div>{"StartDate"}{StartDate}</div>
                          <div>{"current"}{current}</div>
                          <div>{"percent"}{percent}</div> */}
                          <Steps current={current} percent={percent}>
                            <Step title="比赛开始" description={moment(Competition?.StartDate).format("YYYY/MM/DD")} />
                            <Step title="报名截止" description={moment(Competition?.RegisterEndDate).format("YYYY/MM/DD")} />
                            <Step title="比赛结束" description={moment(Competition?.EndDate).format("YYYY/MM/DD")} />
                          </Steps>
                        </>
                      })()}


                      {/* {new Date(Competition?.RegisterEndDate) - new Date() > 0 ? <> <Steps current={1} percent={((new Date() - new Date(Competition?.StartDate)) * 100 / (new Date(Competition?.RegisterEndDate) - new Date(Competition?.StartDate)))}>
                        <Step title="比赛开始" description={moment(Competition?.StartDate).format("YYYY/MM/DD")} />
                        <Step title="报名截止" description={moment(Competition?.RegisterEndDate).format("YYYY/MM/DD")} />
                        <Step title="比赛结束" description={moment(Competition?.EndDate).format("YYYY/MM/DD")} />
                      </Steps> </> : <> <Steps current={2} percent={((new Date() - new Date(Competition?.RegisterEndDate)) * 100 / (new Date(Competition?.EndDate) - new Date(Competition?.RegisterEndDate)))}>
                        <Step title="比赛开始" description={moment(Competition?.StartDate).format("YYYY/MM/DD")} />
                        <Step title="报名截止" description={moment(Competition?.RegisterEndDate).format("YYYY/MM/DD")} />
                        <Step title="比赛结束" description={moment(Competition?.EndDate).format("YYYY/MM/DD")} />
                      </Steps> </>} */}

                    </div>
                    <div className="detail-points-tips">
                      <div className="item">
                        <span className="prefix">论坛积分</span>
                        <span className="text">本场比赛将计算论坛积分，积分作为全站排行榜依据。<Link style={{ color: '#176EDE', textDecorationLine: 'underline' }} to="/regular">论坛积分规则</Link>  </span>
                      </div>
                      <div className="item">
                        <span className="prefix">用户评级</span>
                        <span className="text">本场比赛将参与用户等级评级。 <Link style={{ color: '#176EDE', textDecorationLine: 'underline' }} to="/regular">用户等级评级规则</Link> </span>
                      </div>
                    </div>
                  </div>
                </TabPane>
                <TabPane tab="排行榜" key="3">
                  <RankList></RankList>
                </TabPane>
                {(() => {
                  let name = Competition?.Name;
                  let id = this.props?.info.forum_section_id;
                  if (this.props?.info.join) {
                    return <>
                      <TabPane tab="讨论区" key="4">
                        <Zone name={name} id={id}></Zone>
                      </TabPane>
                      <TabPane tab="邀请码" key="5" style={{ marginBottom: "100px" }}>
                        <div style={{ textAlign: "right" }}>
                          <div style={{ width: '99%', marginLeft: '3px', textAlign: 'left', color: '#000', fontSize: 16, lineHeight: '28px', background: '#FFFFFF', boxShadow: '0px 5px 10px rgba(0, 0, 0, 0.13)', padding: '16px 30px', marginBottom: 20 }}>
                            <TextArea
                              value={this.props.info?.share_text}
                              placeholder=""
                              readOnly
                              autoSize={{ minRows: 3, maxRows: 5 }}
                              style={{ border: 'none' }}
                            />
                          </div>
                          <Button type="primary" onClick={() => {
                            const reg = /(https?|http|ftp|file):\/\/[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|]/g;
                            // const strValue = this.props.info?.share_text.match(reg);
                            const strValue = this.props.info?.share_text;
                            if (strValue && strValue.length > 0) {
                              copy(strValue, () => {
                                message.success('复制成功')
                              })
                            } else {
                              message.error('邀请链接有误')
                            }
                          }} style={{ marginRight: '2px' }}>
                            {"复制链接"}
                          </Button>
                        </div>

                      </TabPane>
                    </>
                  }
                  if (CONTEXT?.tiers == '主办方' && !(_this.props?.info.join)) {
                    console.log(CONTEXT, 'CONTEXT', _this.props?.info.join);
                    return <TabPane tab="讨论区" key="4">
                      <Zone name={name || this.props.info.name} id={id}></Zone>
                    </TabPane>
                  }
                })()}
              </Tabs>
            </div>
          </div>

        }}
      </Provider_LOGIN_STATE.Consumer>
    )
  }
}
export default Page
