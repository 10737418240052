import { extend } from 'umi-request';
import { message, notification, Space } from 'antd';
import { ParamsAssign, ParamsToSearch } from '@/functions/ParamsToSearch';

export const codeMessage = {
  200: '服务器成功返回请求的数据。',
  201: '新建或修改数据成功。',
  202: '一个请求已经进入后台排队（异步任务）。',
  204: '删除数据成功。',
  400: '发出的请求有错误，服务器没有进行新建或修改数据的操作。',
  401: '用户没有权限（令牌、用户名、密码错误）。',
  403: '用户得到授权，但是访问是被禁止的。',
  404: '发出的请求针对的是不存在的记录，服务器没有进行操作。',
  406: '请求的格式不可得。',
  410: '请求的资源被永久删除，且不会再得到的。',
  422: '当创建一个对象时，发生一个验证错误。',
  500: '服务器发生错误，请检查服务器。',
  502: '网关错误。',
  503: '服务不可用，服务器暂时过载或维护。',
  504: '网关超时。',
};

export type mapCode = 200 | 201 | 202 | 204
  | 400 | 401 | 403 | 404 | 406 | 410 | 422
  | 500 | 502 | 503 | 504;

export type ErrorCode = 4000;

/**
 * 异常处理程序
 */
const errorHandler = (error: { response: Response }): Response => {
  const { response } = error;
  // console.log(response);
  if (response && response.status) {
    const { status, url } = response;

    response.json().then(body => {
      const errorText = body.message || codeMessage[response.status as mapCode] || response.statusText;
      const errMessage = `操作未能完成`
      notification.warn({
        message: errMessage,
        description: errorText,
        duration: 10,
      });

    }).catch((err) => {
      const errorText = codeMessage[response.status as mapCode] || response.statusText;
      // const { status, url } = response;
      const errMessage = `服务器异常`

      notification.error({
        message: errMessage,
        description: errorText,
        duration: 10,
      });

    });

  } else if (!response) {
    let warn = {
      description: '您的网络发生异常，无法连接服务器',
      // description: url,
      duration: 1.5,
      message: '网络异常',
    };
    console.error(warn);

    notification.warn({
      message: warn.message,
      description: warn.description,
      duration: 10,
    });

  }
  // return response;//什么jb
  throw response;
};

/**
 * 配置request 请求时的默认参数
 */
//  console.log(process.env.UMI_ENV,process.env.API_ROOT);



const request = extend({
  timeout: 20000,
  // prefix: process.env.ENV !== 'dev' ? process.env.API_ROOT : '/api',
  errorHandler, // 默认错误处理
  // credentials: 'include', // 默认请求是否带上cookie
});

// token 拦截器
request.interceptors.request.use((url, options) => {
  let tempURL = new URL(`http://localhost:8000${url}`)

  // //get参数仿POST调用
  // if (options.method === "get") {
  //   const params = JSON.parse(JSON.stringify(options.params)) as { [itemKey: string]: string | number | boolean }
  //   tempURL = ParamsAssign(tempURL, params)
  // }

  url = `${tempURL.pathname}${tempURL.search}`
  ////以下是老代码，兼容
  let fullurl;

  let newOptions: any = { ...options };
  if (url == '/api/sms/send' || url == '/api/user/login') {
    // console.log('login with not token');
  } else {
    const token = localStorage.getItem('access-token');
    if (token) {
      newOptions.headers['Authorization'] = token;
    }
  }
  // newOptions.headers['Cookie'] = 'name=value; name2=value2';

  if (NODE_PRODUCTION && UF_CONFIG.domain_prefix_add) {
    fullurl = UF_CONFIG.api_url + url;
  } else {
    fullurl = url;
  }
  return {
    url: fullurl,
    options: newOptions,
  };
});
export const syntheticURL = (path: string, preset?: string) => {
  if (NODE_PRODUCTION && UF_CONFIG.domain_prefix_add) {
    return UF_CONFIG.api_url + path;
  } else if (preset) {
    return preset + path;
  } else {
    return path;
  }
}

request.interceptors.response.use((response) => {
  const token = response.headers.get('access-token');
  if (token) {
    // localStorage.setItem('access-token', token);
    localStorage.setItem('access-token', 'Bearer ' + token);
  }
  return response;
});
export default request;
