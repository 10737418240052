import React from 'react';
import styles from './styles.less';

const UserAgreement = "/user_agreement.pdf";
const PrivacyPolicy = "/privacy_policy.pdf";
const InformationRelease = '/information_release_management_rules.pdf'
const contestantsNotes = '/contestantsNotes.pdf'
const ceshi = '/work.xlsx';
const x = '/pattern-blue-unit.png';
import { Button } from 'antd';
//react文件预览支持docx，xlsx
import FileViewer from 'react-file-viewer';
//react预览pdf文件插件
import PDF from 'react-pdf-js';

class Files extends React.Component {
  constructor(props) {
      super(props);
      this.state = {
          fileState: props.fileState,//父类传递的fileState
          type: props.fileType,//父类传递的fileType
          page: 1,
          pages: null,
      }
  }
  componentWillReceiveProps(nextProps) {//props发生变化时执行
      this.setState({
          fileState: nextProps.fileState,//父类传递的fileState
          type: nextProps.fileType,//父类传递的fileType
      })
  }
  closeFile() {
      this.setState({
          fileState: 'none',
      })
      this.props.goToFile('none');
  }
 //获取所有页
  onDocumentComplete = (pages) => {
      this.setState({ page: 1, pages })
  }
  //点击上一页
  handlePrevious = () => {
      this.setState({ page: this.state.page - 1 })
  }
  //点击下一页
  handleNext = () => {
      this.setState({ page: this.state.page + 1 })
  }
  render() {
      const { page, pages, type } = this.state;
      return (<React.Fragment>
          <div style={{ display: this.state.fileState }} className={styles.filesBox}>
              {
                  (type == 'UserAgreement' || type == 'InformationRelease' || type == 'contestantsNotes' || type == 'PrivacyPolicy')?
                    //   <div className={styles.flieContent}>
                    //       <FileViewer
                    //           fileType='docx'//文件类型
                    //           filePath={UserAgreement} //文件地址
                    //           onError={console.log('错误信息')}
                    //           errorComponent={console.log('发生错误')}//[可选]：发生错误时呈现的组件，而不是react-file- 
                    //         //    viewer随附的默认错误组件
                    //           unsupportedComponent={console.log('不支持')} //[可选]：在不支持文件格式的情况下呈现的组件
                    //       />
                    //   </div>
                                <div>
                                    <div className={styles.filePdf}>
                                        <PDF
                                            file={type == 'UserAgreement'? UserAgreement:type == 'InformationRelease'?InformationRelease: type == 'contestantsNotes'? contestantsNotes: type === "PrivacyPolicy" ? PrivacyPolicy : ""}
                                            onDocumentComplete={this.onDocumentComplete}
                                            page={page}
                                        />
                                    </div>
                                    <div className={styles.filePdfFooter}>
                                        {page === 1 ?
                                            null
                                            :
                                            <Button type='primary' onClick={this.handlePrevious}>上一页</Button>
                                        }
                                        <div className={styles.filePdfPage}>
                                            <span>第{page}页</span>/<span>共{pages}页</span>
                                        </div>
                                        {page === pages ?
                                            null
                                            :
                                            <Button style={{ marginLeft: '10px' }} type='primary' onClick={this.handleNext}>下一页</Button>
                                        }
                                    </div>
                                </div>
                      : type == 'InformationRelease' ?
                      <div className={styles.flieContent}>
                          <FileViewer
                              fileType='docx'//文件类型
                              filePath={InformationRelease} //文件地址
                          />
                      </div>
                      : type == 'contestantsNotes' ?
                      <div className={styles.flieContent}>
                          <FileViewer
                              fileType='docx'//文件类型
                              filePath={ContestantsNotes} //文件地址
                          />
                      </div>
                      : type == 'xlsx' ?
                          <div className={styles.flieContent}>
                              <FileViewer
                                  fileType='xlsx'//文件类型
                                  filePath={ceshi} //文件地址
                              />
                          </div>
                        : ''
              }
          </div>
      </React.Fragment>)
  }
}
export default Files;