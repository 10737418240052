import { Tabs, Typography } from 'antd';
import React, { PureComponent } from 'react';
import "./index.less";
import moment from "moment"


type Props = {
  time?: Date

}
type State = {
  days: number
  hours: number
  seconds: number
  minutes: number
}

class Timer extends PureComponent<Props, State> {
  Ref_main: React.RefObject<HTMLDivElement>;
  timer?: NodeJS.Timer;
  constructor(props: Props) {
    super(props);
    this.Ref_main = React.createRef();
    this.state = {
      days: 0,
      hours: 0,
      seconds: 0,
      minutes: 0,
    };
  }

  componentDidMount() {
    let self = this
    self.init()
    clearInterval(this.timer);
    this.timer = setInterval(function(){self.init()},1000)
  }
  componentWillUnmount() {
    if (this.timer) {
      clearInterval(this.timer)
    }
  }

  init(){
    let time = moment(this.props.time).diff(moment())
    if(time <= 0){
      clearInterval(this.timer);
      return
    }
    let t = time / 1000;
    let d = Math.floor(t / (24 * 3600));  //剩余天数，如果需要可以自行补上
    let h = Math.floor((t - 24 * 3600 * d) / 3600) + d*24;  //不需要天数，把天数转换成小时
    let _h = Math.floor((t - 24 * 3600 * d) / 3600)  //保留天数后得小时
    let m = Math.floor((t - 24 * 3600 * d - _h * 3600) / 60);
    let s = Math.floor((t - 24 * 3600 * d - _h * 3600 - m * 60));

    this.setState({
      days: d,
      hours: _h,
      minutes: m,
      seconds: s
    })
  }

  Zero(num: number) {
    if (num.toString().length === 1) {
      return `0${num}`
    }
    return num.toString()
  }
  render() {
    const seconds = this.Zero(this.state.seconds)
    const minutes = this.Zero(this.state.minutes)
    const hours = this.Zero(this.state.hours)
    const days = this.Zero(this.state.days)
    return (<>
      <span className="detail-time"> <span>{days}</span>天&nbsp;<span>{hours}</span>时&nbsp;<span>{minutes}</span>分&nbsp;<span>{seconds}</span>秒</span><br/>
      <span className="detail-date">{moment(this.props.time).format('YYYY-MM-DD HH:mm:ss')}</span>
    </>)
    return 
  }
}


export default Timer
