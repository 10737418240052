import React, { PureComponent } from 'react';
import { Link } from 'umi';
import styles from './index.less';
import { Upload, message, Button, Modal } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import {
  API_uploadImage,
} from "../../services/files"


export default class Page extends PureComponent {

  constructor(props) {
    super(props);
    // const { id } = this.props.match.params;
    this.state = {
      // id,
      imgList: [], 
      previewVisible: false,
      previewImage: '',
    };
  }
  handleCancel = () => this.setState({ previewVisible: false });

  // 方法：图片预览
  handlePreview = (file) => {
    console.log(' handlePreview:' + JSON.stringify(file));
    this.setState({
      previewImage: file.url || file.thumbUrl,
      previewVisible: true,
    });
  };

  // 参考链接：https://www.jianshu.com/p/f356f050b3c9
  handleBeforeUpload = (file) => {
    console.log(' handleBeforeUpload file:' + JSON.stringify(file));
    console.log(' handleBeforeUpload file.file:' + JSON.stringify(file.file));
    console.log(' handleBeforeUpload file type:' + JSON.stringify(file.type));

    //限制图片 格式、size、分辨率
    const isJPG = file.type === 'image/jpeg';
    const isJPEG = file.type === 'image/jpeg';
    const isGIF = file.type === 'image/gif';
    const isPNG = file.type === 'image/png';
    const isLt2M = file.size / 1024 / 1024 < 1;
    if (!(isJPG || isJPEG || isPNG)) {
      Modal.error({
        getContainer: '#root',
        title: '只能上传JPG、JPEG、PNG格式的图片~',
      });
    } else if (!isLt2M) {
      Modal.error({
        getContainer: '#root',
        title: '图片超过1M限制，不允许上传~',
      });
    }
    return (isJPG || isJPEG || isPNG) && isLt2M;
  };

  doImgUpload = (options) => {
    const { onSuccess, onError, file, onProgress } = options;
    console.log('calling doImgUpload');
    console.log(file);
    console.log(file.name);
    // window.xie = file;
    // start：进度条相关

    const reader = new FileReader();
    // reader.readAsBinaryString(file); // 读取图片文件
    // reader.readAsDataURL(file); // 读取图片文件
    reader.readAsArrayBuffer(file); // 读取图片文件

    reader.onload = (progress) => {
      console.log("progress", progress);
    window.tian = progress;
      // console.log(file.name);
      // console.log(progress.target.result);

      // 上传图片的binariy编码，调接口后，返回 imageId
      API_uploadImage(progress.target.result, file.name)
      // uploadImage(file, file.name)
        .then((res) => {
          console.log(res);
          console.log('doImgUpload:' + JSON.stringify(res));
          if (res.success && res.data.uri.length < 1) {
	          console.log('图片上传失败:' + JSON.stringify(res));
	          message.error('图片上传失败，请重试');
          }else{
          	let image_uri = res.data['uri'][0]['url'];
          	console.log('图片上传成功：' + image_uri);
          	let image_url = UF_CONFIG.api_url + image_uri;
          	const imgItem = {
                uid: file.uid,
                name: file.name,
          		status: 'done',
          		url: image_url, // url 是展示在页面上的绝对链接
          		imgUrl: image_url, // imgUrl 是存到 db 里的相对链接
          		// response: '{"status": "success"}',
          	};
	        this.setState({
	          imgList: [imgItem],
	        }); // 更新 imgList

          }
        })
        .catch((e) => {
          console.log('smyhvae 图片上传失败:' + JSON.stringify(e || ''));
          message.error('图片上传失败，请重试');
        });
    };
  };

  handleChange = ({ file, fileList }) => {
    console.log('smyhvae handleChange file:' + JSON.stringify(file));
    console.log('smyhvae handleChange fileList:' + JSON.stringify(fileList));

    if (file.status == 'removed') {
      this.setState({
        imgList: [],
      });
    }
  };  

  render() {
    return (
    <div>
      <h1 className={styles.title}>Page </h1>
      <Link to="/login">login</Link>
      <br/>
      <Link to="/competitions/list">competitions</Link>
	  <Upload
		customRequest={this.doImgUpload}
        fileList={this.state.imgList}
        onPreview={this.handlePreview}
        beforeUpload={this.handleBeforeUpload}
        onChange={this.handleChange}
	  >
	    <Button icon={<UploadOutlined />}>Click to Upload</Button>
	  </Upload>,      
    </div>
	)
}

}

// https://www.cnblogs.com/qianguyihao/p/13093592.html