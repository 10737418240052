// @ts-nocheck
import React from 'react';
import { ApplyPluginsType } from '/root/docker/ubiquant_forum_front/node_modules/umi/node_modules/@umijs/runtime';
import * as umiExports from './umiExports';
import { plugin } from './plugin';

export function getRoutes() {
  const routes = [
  {
    "path": "/",
    "component": require('@/layouts/beforeRender').default,
    "routes": [
      {
        "path": "/login",
        "component": require('@/layouts/frontend').default,
        "routes": [
          {
            "path": "/login/",
            "title": "登录-九坤投资论坛",
            "component": require('@/pages/login/index').default,
            "exact": true
          },
          {
            "path": "/login/*",
            "component": require('@/pages/login/index').default,
            "exact": true
          }
        ]
      },
      {
        "path": "/home",
        "component": require('@/layouts/home/index').default,
        "routes": [
          {
            "path": "/home/",
            "title": "九坤投资论坛首页",
            "component": require('@/pages/homepage/index').default,
            "exact": true
          }
        ]
      },
      {
        "path": "/",
        "component": require('@/layouts/general').default,
        "routes": [
          {
            "path": "/competition",
            "routes": [
              {
                "path": "/competition/item",
                "component": require('@/pages/competition/item').default,
                "routes": [
                  {
                    "path": "/competition/item/submit",
                    "title": "提交报名信息 - 九坤投资",
                    "component": require('@/pages/competition/item/submit').default,
                    "exact": true
                  },
                  {
                    "path": "/competition/item/submit2",
                    "title": "提交报名信息 - 九坤投资",
                    "component": require('@/pages/competition/item/submit/index2').default,
                    "exact": true
                  },
                  {
                    "path": "/competition/item/",
                    "title": "比赛详情 - 九坤投资",
                    "component": require('@/pages/competition/item/introduction').default,
                    "exact": true
                  },
                  {
                    "path": "/competition/item/*",
                    "component": require('@/pages/competition/item/introduction').default,
                    "exact": true
                  }
                ]
              },
              {
                "path": "/competition/",
                "title": "比赛列表 - 九坤投资",
                "component": require('@/pages/competition/main').default,
                "exact": true
              }
            ]
          },
          {
            "path": "/search",
            "title": "搜索",
            "component": require('@/pages/search/index').default,
            "exact": true
          },
          {
            "path": "/regular",
            "title": "积分规则 - 九坤投资",
            "component": require('@/layouts/regular/index').default,
            "exact": true
          },
          {
            "path": "/redirecToUrl",
            "title": "九坤投资",
            "component": require('@/layouts/redirect_to/index').default,
            "exact": true
          },
          {
            "path": "/rank",
            "title": "论坛排名 - 九坤投资论坛",
            "component": require('@/pages/rank/index').default,
            "exact": true
          },
          {
            "path": "/user",
            "routes": [
              {
                "path": "/user/",
                "title": "我的资料 - 九坤投资论坛",
                "component": require('@/pages/user_profile/index').default,
                "exact": true
              },
              {
                "path": "/user/:sid",
                "title": "我的资料 - 九坤投资论坛",
                "component": require('@/pages/user_profile/personalInfo').default,
                "exact": true
              }
            ]
          },
          {
            "path": "/personalInfo",
            "routes": [
              {
                "path": "/personalInfo/:sid",
                "title": "我的资料 - 九坤投资论坛",
                "component": require('@/pages/user_profile/personalInfo').default,
                "exact": true
              }
            ]
          },
          {
            "path": "/discussions",
            "component": require('@/layouts/general/children/index').default,
            "routes": [
              {
                "path": "/discussions/new/:sid",
                "component": require('@/pages/discussions/newPost').default,
                "title": "新建讨论 - 九坤投资论坛",
                "exact": true
              },
              {
                "path": "/discussions/new",
                "component": require('@/pages/discussions/newPost').default,
                "title": "新建讨论 - 九坤投资论坛",
                "exact": true
              },
              {
                "path": "/discussions/zone",
                "routes": [
                  {
                    "path": "/discussions/zone/",
                    "exact": true,
                    "component": require('@/pages/discussions/zone').default
                  },
                  {
                    "title": "九坤投资论坛",
                    "path": "/discussions/zone/*",
                    "component": require('@/pages/discussions/zone').default,
                    "exact": true
                  }
                ]
              },
              {
                "path": "/discussions/post",
                "routes": [
                  {
                    "path": "/discussions/post/",
                    "component": require('@/pages/discussions/post').default,
                    "exact": true
                  },
                  {
                    "path": "/discussions/post/:sid/edit",
                    "component": require('@/layouts/general/children/index').default,
                    "routes": [
                      {
                        "path": "/discussions/post/:sid/edit/",
                        "component": require('@/pages/discussions/post/edit').default,
                        "exact": true
                      }
                    ]
                  },
                  {
                    "path": "/discussions/post/:sid",
                    "component": require('@/pages/discussions/post').default,
                    "exact": true
                  },
                  {
                    "path": "/discussions/post/*",
                    "component": require('@/pages/discussions/post').default,
                    "exact": true
                  }
                ]
              },
              {
                "path": "/discussions/",
                "title": "九坤投资论坛",
                "component": require('@/pages/discussions').default,
                "exact": true
              }
            ]
          },
          {
            "path": "/",
            "title": "讨论区- 九坤投资论坛",
            "redirect": "/home",
            "exact": true
          },
          {
            "path": "/demo_test",
            "title": "九坤投资",
            "component": require('@/pages/general/index').default,
            "exact": true
          }
        ]
      }
    ]
  },
  {
    "path": "/",
    "component": require('@/layouts/beforeRender').default,
    "routes": [
      {
        "path": "/login",
        "component": require('@/layouts/frontend').default,
        "routes": [
          {
            "path": "/login/",
            "title": "登录-九坤投资论坛",
            "component": require('@/pages/login/index').default,
            "exact": true
          },
          {
            "path": "/login/*",
            "component": require('@/pages/login/index').default,
            "exact": true
          }
        ]
      },
      {
        "path": "/home",
        "component": require('@/layouts/home/index').default,
        "routes": [
          {
            "path": "/home/",
            "title": "九坤投资论坛首页",
            "component": require('@/pages/homepage/index').default,
            "exact": true
          }
        ]
      },
      {
        "path": "/",
        "component": require('@/layouts/general').default,
        "routes": [
          {
            "path": "/competition",
            "routes": [
              {
                "path": "/competition/item",
                "component": require('@/pages/competition/item').default,
                "routes": [
                  {
                    "path": "/competition/item/submit",
                    "title": "提交报名信息 - 九坤投资",
                    "component": require('@/pages/competition/item/submit').default,
                    "exact": true
                  },
                  {
                    "path": "/competition/item/submit2",
                    "title": "提交报名信息 - 九坤投资",
                    "component": require('@/pages/competition/item/submit/index2').default,
                    "exact": true
                  },
                  {
                    "path": "/competition/item/",
                    "title": "比赛详情 - 九坤投资",
                    "component": require('@/pages/competition/item/introduction').default,
                    "exact": true
                  },
                  {
                    "path": "/competition/item/*",
                    "component": require('@/pages/competition/item/introduction').default,
                    "exact": true
                  }
                ]
              },
              {
                "path": "/competition/",
                "title": "比赛列表 - 九坤投资",
                "component": require('@/pages/competition/main').default,
                "exact": true
              }
            ]
          },
          {
            "path": "/search",
            "title": "搜索",
            "component": require('@/pages/search/index').default,
            "exact": true
          },
          {
            "path": "/regular",
            "title": "积分规则 - 九坤投资",
            "component": require('@/layouts/regular/index').default,
            "exact": true
          },
          {
            "path": "/redirecToUrl",
            "title": "九坤投资",
            "component": require('@/layouts/redirect_to/index').default,
            "exact": true
          },
          {
            "path": "/rank",
            "title": "论坛排名 - 九坤投资论坛",
            "component": require('@/pages/rank/index').default,
            "exact": true
          },
          {
            "path": "/user",
            "routes": [
              {
                "path": "/user/",
                "title": "我的资料 - 九坤投资论坛",
                "component": require('@/pages/user_profile/index').default,
                "exact": true
              },
              {
                "path": "/user/:sid",
                "title": "我的资料 - 九坤投资论坛",
                "component": require('@/pages/user_profile/personalInfo').default,
                "exact": true
              }
            ]
          },
          {
            "path": "/personalInfo",
            "routes": [
              {
                "path": "/personalInfo/:sid",
                "title": "我的资料 - 九坤投资论坛",
                "component": require('@/pages/user_profile/personalInfo').default,
                "exact": true
              }
            ]
          },
          {
            "path": "/discussions",
            "component": require('@/layouts/general/children/index').default,
            "routes": [
              {
                "path": "/discussions/new/:sid",
                "component": require('@/pages/discussions/newPost').default,
                "title": "新建讨论 - 九坤投资论坛",
                "exact": true
              },
              {
                "path": "/discussions/new",
                "component": require('@/pages/discussions/newPost').default,
                "title": "新建讨论 - 九坤投资论坛",
                "exact": true
              },
              {
                "path": "/discussions/zone",
                "routes": [
                  {
                    "path": "/discussions/zone/",
                    "exact": true,
                    "component": require('@/pages/discussions/zone').default
                  },
                  {
                    "title": "九坤投资论坛",
                    "path": "/discussions/zone/*",
                    "component": require('@/pages/discussions/zone').default,
                    "exact": true
                  }
                ]
              },
              {
                "path": "/discussions/post",
                "routes": [
                  {
                    "path": "/discussions/post/",
                    "component": require('@/pages/discussions/post').default,
                    "exact": true
                  },
                  {
                    "path": "/discussions/post/:sid/edit",
                    "component": require('@/layouts/general/children/index').default,
                    "routes": [
                      {
                        "path": "/discussions/post/:sid/edit/",
                        "component": require('@/pages/discussions/post/edit').default,
                        "exact": true
                      }
                    ]
                  },
                  {
                    "path": "/discussions/post/:sid",
                    "component": require('@/pages/discussions/post').default,
                    "exact": true
                  },
                  {
                    "path": "/discussions/post/*",
                    "component": require('@/pages/discussions/post').default,
                    "exact": true
                  }
                ]
              },
              {
                "path": "/discussions/",
                "title": "九坤投资论坛",
                "component": require('@/pages/discussions').default,
                "exact": true
              }
            ]
          },
          {
            "path": "/",
            "title": "讨论区- 九坤投资论坛",
            "redirect": "/home",
            "exact": true
          },
          {
            "path": "/demo_test",
            "title": "九坤投资",
            "component": require('@/pages/general/index').default,
            "exact": true
          }
        ]
      }
    ]
  }
];

  // allow user to extend routes
  plugin.applyPlugins({
    key: 'patchRoutes',
    type: ApplyPluginsType.event,
    args: { routes },
  });

  return routes;
}
