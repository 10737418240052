import React, { PureComponent, useState, useEffect, useRef } from 'react';
import { Button, Select, Typography, Input, DatePicker, Checkbox, Form, InputNumber, Modal, AutoComplete  } from 'antd';

import './index.less';
import { Row, Col, message, ConfigProvider, Spin  } from 'antd';

const { Title, Paragraph, Text, Link } = Typography;
const { RangePicker } = DatePicker;
import moment from 'moment';
import baseConfigObject from './config'

import { InstructionsContestants } from "../../../../layouts/textList/text"
const { confirm } = Modal;
import {Competition_Registration, user_resume} from "@/services/competion"
import {history} from 'umi';
// import { NationPhone } from '@/layouts/nation-phone';
import zhCN from 'antd/lib/locale/zh_CN';
type TypeList = {

}
type State = {

}

const arrayGroupBy = function (list, groupId){
  function groupBy(array, f){
      const groups = {}
      array.forEach(function (o) {
          const group = JSON.stringify(f(o))
          groups[group] = groups[group] || []
          groups[group].push(o)
      })

      return groups;
      // return Object.keys(groups).map(function (group) {
      //     return groups[group]
      // })
  };
  return groupBy(list, function (item) {
      return item[groupId]
  });
};

class Page extends PureComponent<{}, State> {
  constructor(props: {}) {
    super(props);
    this.state = {
      sections: [],
    };
  }
  render() {
    return <div style={{
      marginLeft: 24,
      marginRight: 24,
      display: "flex",
      justifyContent: "center"
    }}>
      <CompSubForm config={[
          {
            "thread": "个人信息",
            "items": [
              {
                "title": "姓名",
                "type": "input",
                "required": true,
                "placeholder": "请输入姓名",
                "name": 'name'
              },
              {
                "title": "手机号",
                "type": "phone",
                "required": true,
                "prefix": true,
                "placeholder": "请输入完整手机号",
                "name": 'phone'
              },
              {
                "title": "邮箱",
                "required": true,
                "type": "input",
                "placeholder": "请输入邮箱",
                "name": 'email'
              },
              {
                "title": "所在地",
                "required": true,
                "type": "input",
                "placeholder": "请输入所在地",
                "name": 'location'
              }
            ]
          },

          {
            "thread": "量化比赛",
            "items": [
              {
                "title": "队伍名称",
                "type": "input",
                "required": true,
                "placeholder": "请输入",
                "name": 'teamName'
              },
              {
                "title": "队伍人数",
                "type": "input",
                "required": true,
                "placeholder": "请输入",
                "name": 'teamNum'
              },
              {
                "title": "队长姓名",
                "required": true,
                "type": "input",
                "placeholder": "请输入",
                "name": 'captianName'
              },
              {
                "title": "往届比赛选手",
                "required": true,
                "type": "select",
                "placeholder": "请选择",
                "name": 'previousCompetitors',
                "options": [
                  {
                  label: '是',
                  value: 'yes'},

                  {
                    label: '否',
                    value: 'no'}
                ]
              }
            ]
          },
        ]}
      />
    </div>
  }
}

type CompSubFormConfigType_Item_Base = {
  title: string
  required?: boolean
  compactSize?: boolean
  smallBig?: boolean
  fullSize?: boolean
  placeholder?: string
  name?: string
}
type CompSubFormConfigType_Item_Select = {
  type: "select"
  options:
  {
    label: string
    value: string
  }[]

} & CompSubFormConfigType_Item_Base

type CompSubFormConfigType_Item_Input = {
  type: "input"
} & CompSubFormConfigType_Item_Base

type CompSubFormConfigType_Item_Phone = {
  type: "phone"
  prefix: boolean
} & CompSubFormConfigType_Item_Base

type CompSubFormConfigType_Item_TimeDuration = {
  type: "timeDuration"
} & CompSubFormConfigType_Item_Base

type CompSubFormConfigType_Item_DatePicker = {
  type: "datePicker"
} & CompSubFormConfigType_Item_Base

type CompSubFormConfigType_Item_MonthPicker = {
  type: "monthPicker"
} & CompSubFormConfigType_Item_Base

type CompSubFormConfigType_Item_Textarea = {
  type: "textarea"
} & CompSubFormConfigType_Item_Base

type CompSubFormConfigType = {
  thread: string
  extendable?: boolean
  items: (CompSubFormConfigType_Item_Select |
    CompSubFormConfigType_Item_Input |
    CompSubFormConfigType_Item_Phone |
    CompSubFormConfigType_Item_TimeDuration |
    CompSubFormConfigType_Item_DatePicker |
    CompSubFormConfigType_Item_MonthPicker |
    CompSubFormConfigType_Item_Textarea)[]
}[]

type CompSubFormProps = {
  config: CompSubFormConfigType
}

export class CompSubForm extends PureComponent<CompSubFormProps, {}> {
  constructor(props: CompSubFormProps) {
    super(props);
    this.state = {
      visible: false,
      modalType: 'educationInfo',
      sections: [],
      educationInfo:[],
      experienceInfo:[],
      projectInfo:[],
      practiceInfo:[],
      jobIntention: {},
      selfDescription: {
        "personal": "zhangsan@mokahr.com",
      }, // 自我介绍
      recommender: {}, // 推荐人
      customFields:[], // 自定义信息
      formData: {},
      ModalVisible: false,
      userAgreement: false,
      basicFormData: {},
      loading: false,
      selectIndex: null,
      phone:''
    };
  }

  formRef = React.createRef();
  form2Ref = React.createRef();
  onFinish = (values) => {
    console.log('Finish:', values);
  };
  hideUserModal() {
    this.setState({
      visible: false
    })
  }

  getModalConfig=()=>{
    return baseConfigObject[this.state.modalType]
  }
  setAddList (values) {
    let modalType = this.state.modalType;
    switch(modalType) {
      case 'educationInfo' :
        var arr = [...this.state.educationInfo];
        if(String(this.state.selectIndex) !== 'null') {
          arr.splice(this.state.selectIndex, 1, values)
        } else {
          arr = [...arr, values]
        }
        this.setState({educationInfo: arr});
        return;
      case 'experienceInfo' :
        var arr = [...this.state.experienceInfo];
        if(String(this.state.selectIndex) !== 'null') {
          arr.splice(this.state.selectIndex, 1, values)
        } else {
          arr = [...arr, values]
        }
        this.setState({experienceInfo: arr});
        return;
      case 'projectInfo' :
        var arr = [...this.state.projectInfo];
        if(String(this.state.selectIndex) !== 'null') {
          arr.splice(this.state.selectIndex, 1, values)
        } else {
          arr = [...arr, values]
        }
        this.setState({projectInfo: arr});
        return;
      case 'practiceInfo' :
        var arr = [...this.state.practiceInfo];
        if(String(this.state.selectIndex) !== 'null') {
          arr.splice(this.state.selectIndex, 1, values)
        } else {
          arr = [...arr, values]
        }
        this.setState({practiceInfo: arr});
        return;
      case 'customFields' :
        var arr = [...this.state.customFields];
        if(String(this.state.selectIndex) !== 'null') {
          arr.splice(this.state.selectIndex, 1, values)
        } else {
          arr = [...arr, values]
        }
        this.setState({customFields: arr});
        return;
    }
  }
  // setAddList (values) {
  //   let modalType = this.state.modalType;
  //   if(this.state.selectIndex) {

  //   } else {
  //     switch(modalType) {
  //       case 'educationInfo' :
  //         this.setState({educationInfo: [...this.state.educationInfo, ...[values]]});
  //         return;
  //       case 'experienceInfo' :
  //         this.setState({experienceInfo: [...this.state.experienceInfo, ...[values]]});
  //         return;
  //       case 'projectInfo' :
  //         this.setState({projectInfo: [...this.state.projectInfo, ...[values]]});
  //         return;
  //       case 'practiceInfo' :
  //         this.setState({practiceInfo: [...this.state.practiceInfo, ...[values]]});
  //         return;
  //       case 'customFields' :
  //         this.setState({customFields: [...this.state.customFields, ...[values]]});
  //         return;
  //     }
  //   }
  // }

  delete(type, index){
    let _this = this;
    confirm({
      content: "是否确认删除？",
      cancelText:'取消',
      okText: "确认",
      onOk() {
        switch(type) {
          case 'educationInfo' :
            _this.setState({educationInfo: _this.state.educationInfo.filter((item, num)=> {return String(num) !== String(index)} )})
            return;
          case 'experienceInfo' :
            _this.setState({experienceInfo: _this.state.experienceInfo.filter((item, num)=> {return String(num) !== String(index)} )});
            return;
          case 'projectInfo' :
            _this.setState({projectInfo: _this.state.projectInfo.filter((item, num)=> {return String(num) !== String(index)} )});
            return;
          case 'practiceInfo' :
            _this.setState({practiceInfo: _this.state.practiceInfo.filter((item, num)=> {return String(num) !== String(index)} )});
            return;
        }
      },
      onCancel() {
        console.log('Cancel');
      }})

  }

  edit (type, index) {
    console.log(index,"edit")
    this.setState({
      selectIndex: index
    })
    switch(type) {
      case 'educationInfo' :
        this.setState({formData: this.state.educationInfo[index], visible: true, modalType: 'educationInfo'});
        return;
      case 'experienceInfo' :
        this.setState({formData: this.state.experienceInfo[index], visible: true, modalType: 'experienceInfo'});
        return;
      case 'projectInfo' :
        this.setState({formData: this.state.projectInfo[index], visible: true, modalType: 'projectInfo'});
        return;
      case 'practiceInfo' :
        this.setState({formData: this.state.practiceInfo[index], visible: true, modalType: 'practiceInfo'});
        return;
      case 'customFields' :
        this.setState({formData: this.state.customFields[index], visible: true, modalType: 'customFields'});
        return;
    }

  }

  renderAuthButton = ()=>{
    return <InstructionsContestants></InstructionsContestants>
  }
  makeConfig (env: string | undefined) {
    switch (env) {
        case "local":
            return CONFIG_LOCAL
        case "prod":
            return CONFIG_PROD
        default:
            return CONFIG_DEV
    }
}
  submitInfo = async (basicInfo, obj)=>{
    const env = process.env.UMI_ENV;
    console.log("state",this.state)
    let data = {
      "basicInfo":{},
      "educationInfo":[],
      "awardInfo":[],
      "customFields": [],
      "action_type": history.location.query?.edite ? "update" : "apply"
    };
    // 参赛说明
    data.customFields.push({
      id: (env !== "prod" ? '410043655':'122122'),
      value: obj.personal,
      index: 0,
      key: "personal"
    });

    // 意向确认
    data.customFields.push({
      id: (env !== "prod" ? '410043684' : "128601"),
      value: obj.ConfirmationOfIntention,
      index: 0,
      key: "ConfirmationOfIntention"
    });

    let team = [{
      id:(env !== "prod" ? '410043644':"93288"),
      value: basicInfo.teamName,
      index: 0,
      key: "teamName"
    },{
      id:(env !== "prod" ? '410043645':"94434"),
      value: basicInfo.teamNum,
      index: 0,
      key: "teamNum"
    },{
      id:(env !== "prod" ? '410043646':"96579"),
      value: basicInfo.captianName,
      index: 0,
      key: "captianName"
    },{
      id:(env !== "prod" ? '410043685':"99749"),
      value: basicInfo.previousCompetitors,
      index: 0,
      key: "previousCompetitors"
    }];
    // 量化比赛
    data.customFields = [...data.customFields, ...team];

    // 项目经历
    this.state.projectInfo.forEach((item, index) => {
      let project = [{
        id:(env !== "prod" ? '410043649': "119661"),
        value: item.startDate,
        index: index,
        key: "startDate"
      },{
        id:(env !== "prod" ? '410043650': "130623"),
        value: item.endDate,
        index: index,
        key: "endDate"
      },{
        id:(env !== "prod" ? '410043651': "121874"),
        value: item.projectName,
        index: index,
        key: "projectName"
      },{
        id:(env !== "prod" ? '410043652': "128602"),
        value: item.projectDescription,
        index: index,
        key: "projectDescription"
      },{
        id:(env !== "prod" ? '410043654': "130626"),
        value: item.responsibilities,
        index: index,
        key: "responsibilities"
      }]
      data.customFields = [...data.customFields, ...project]
    })

    data.basicInfo['name'] = basicInfo.name;
    data.basicInfo['phone'] = String(basicInfo.phone);
    // data.basicInfo['phone'] = String(this.state.phone);
    data.basicInfo['location'] = basicInfo.location;
    data.basicInfo['email'] = String(basicInfo.email);
    data.educationInfo = this.state.educationInfo;

console.log(data, 'kanwei');


    if(this.state.practiceInfo.length) {
      data.awardInfo = this.state.practiceInfo; // 获奖经历
    }

    this.setState({loading:true})

    // 判断当前报名比赛是否为 别人邀请链接
    const invitationInfo = localStorage.getItem(sessionStorage.getItem("org_id"));
    var invite_code = ''
    if(invitationInfo) {
      invite_code = JSON.parse(invitationInfo).code;
      data.customFields.push({
        id:(env !== "prod" ? '410044645': "126818"),
        value: JSON.parse(invitationInfo).name,
        index: 0,
        key: "invite_name"
      })
      data.customFields.push({
        id:(env !== "prod" ? '410044646': "133554"),
        value: JSON.parse(invitationInfo).phone,
        index: 0,
        key: "invite_phone"
      })
    }
    let response;
    try{
      response = await Competition_Registration( sessionStorage.getItem("org_id"), invite_code, data);
    }catch(err){
      this.setState({loading:false});
      return;
    }

    this.setState({loading:false})
    // if(response.code === 200){
      Modal.success({
        content: (
          <div>
            {history.location.query?.edite ? "修改成功" : "报名成功"}
          </div>
        ),
        getContainer: '#root',
        onOk() {
          history.replace({
            pathname: '/competition/item',
            query: {
              org_id: sessionStorage.getItem("org_id")
            }
          })
        },
      });

    // }
    // else{
    //   Modal.success({
    //     getContainer: '#root',
    //     content: (
    //       <div>
    //         {response.data}
    //       </div>
    //     ),
    //     onOk() {
    //       history.replace({
    //         pathname: '/competition/item',
    //         query: {
    //           org_id: sessionStorage.getItem("org_id")
    //         }
    //       })
    //     },
    //   });
    // }
  }

  getinfo =async (params:type) => {
    let info = await user_resume();
    const env = process.env.UMI_ENV;


    let basicInfoObj = {...info.data.basicInfo, ...{}};
    let projectInfo = [];
    var projectInfoObj = null;
    if(env !== "prod" && info.data.customFields) {
      info.data.customFields?.filter(x => (['410043655'].indexOf(x.id) > -1)).map(x => basicInfoObj[x.key] = x.value);
      info.data.customFields?.filter(x => (["410043655","410043684", '410043644',"410043645","410043646","410043685"].indexOf(x.id) > -1)).map(x => basicInfoObj[x.key] = x.value);
      projectInfoObj = arrayGroupBy(info.data.customFields?.filter(x => (['410043649',"410043650","410043651","410043652", "410043654"].indexOf(x.id) > -1)), "index");
    } else if(info.data.customFields){
      info.data.customFields?.filter(x => (['122122'].indexOf(x.id) > -1)).map(x => basicInfoObj[x.key] = x.value);
      info.data.customFields?.filter(x => (['122122',"128601", '93288',"94434","96579","99749"].indexOf(x.id) > -1)).map(x => basicInfoObj[x.key] = x.value);
      projectInfoObj = arrayGroupBy(info.data.customFields?.filter(x => (['119661',"130623","121874","128602", "130626"].indexOf(x.id) > -1)), "index");

    }
    for (const item in projectInfoObj) {
      var obj = {};
      projectInfoObj[item].forEach(info=>{
        obj[info.key] = info.value
      })
      projectInfo.push(obj)
    }

    this.formRef.setFieldsValue(basicInfoObj);

    if(info.data.customFields) {
      if(env !== "prod") {
        this.form2Ref.setFieldsValue({
          personal: info.data.customFields.filter(x => (['410043655'].indexOf(x.id) > -1))[0].value,
          ConfirmationOfIntention: info.data.customFields.filter(x => (['410043684'].indexOf(x.id) > -1))[0].value
        });
      } else {
        this.form2Ref.setFieldsValue({
          personal: info.data.customFields.filter(x => (['122122'].indexOf(x.id) > -1))[0].value,
          ConfirmationOfIntention: info.data.customFields.filter(x => (['128601'].indexOf(x.id) > -1))[0].value,
        });
      }
    }
    this.setState({
      educationInfo: info.data.educationInfo ||  [],
      projectInfo: projectInfo || [],
      practiceInfo: info.data.awardInfo || [],
      basicFormData: basicInfoObj || {},
      // phone: basicInfoObj.phone
    })
    // setTimeout(()=> {
      // document.getElementById('basicForm_phone').value = basicInfoObj.phone || ""
    // },1000)
  }
  updateInfo (values) {
    const params  = JSON.parse(JSON.stringify(values))
    if(typeof values.startDate === 'object'){
      params.startDate = values.startDate.format("YYYY/MM/DD")
      params.endDate = values.endDate.format("YYYY/MM/DD")
    }
    if(typeof values.awardDate  === 'object'){
      params.awardDate = values.awardDate.format("YYYY/MM/DD")
    }

    if(params.startDate){
      params.startDate = moment(params.startDate).format("YYYY/MM/DD")
      params.endDate = moment(params.endDate).format("YYYY/MM/DD")
    }
    if(params.awardDate){
      params.awardDate = moment(params.awardDate).format("YYYY/MM/DD")
    }

    this.setState({visible: false});
    this.setAddList(params);
  }

  // phone_change (phoneNumber) {
  //   console.log("phone_change",phoneNumber)
  //   this.setState({phone: phoneNumber})
  //   //垃圾代码随便处理一下
  //   if(this.formRef){
  //     this.formRef.setFieldsValue({ phone: phoneNumber });
  //   }
   
  // }

  componentDidMount() {
    this.getinfo();
  }
  render() {
    return <div className={"resume-box"} style={{maxWidth: 1024,width: "100%"}}>
      <Spin spinning={this.state.loading}>
      <Form.Provider
        onFormFinish={(name, { values, forms }) => {

        }}
      >
      <Form ref={ref => this.formRef = ref}  name="basicForm"  initialValues={this.state.basicFormData}  onFinish={this.onFinish}>
        {Array.from(this.props.config, (card, cardKey) => {
        return <SubCard key={cardKey} title={card.thread}>
          <Row gutter={32}>
            {Array.from(card.items, (item, itemKey) => {
              let span = 12
              if (item.compactSize) {
                span = 6
              } else if (item.fullSize) {
                span = 24
              } else if (item.smallBig) {
                span = 8
              }
              var rules = [{
                required: true,
                message: item.title+'必输！'
              }];
              switch (item.type) {
                case "input":
                  if(item.name == 'email'){
                    rules.push({
                      pattern: /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/,
                      message: '邮箱格式不正确',
                    })
                  } else if (item.name === 'teamNum') {
                    rules.push({
                      required: false,
                      pattern: new RegExp(/^[1-9]\d*$/, "g"),
                      message: '请输入正确的数字'
                    })
                  }
                  break;
                case "select":

                  break;
                case "phone":
                  // rules.push({
                  //   required: false,
                  //   pattern: new RegExp(/^1(3|4|5|6|7|8|9)\d{9}$/, "g"),
                  //   message: '请输入正确的手机号'
                  // })
                  break;
                case "datePicker":

                  break;
                case "textarea":

                  break;
              }
              return <Col span={span} key={itemKey} style={{ marginTop: 28 }}>
                <Title level={5}>
                  {item.title}{(() => {
                    if (item.required) {
                      return <span style={{ color: "red" }}>{" * "}</span>
                    }
                  })()}
                </Title>
                <Form.Item
                  name={item.name}
                  rules={rules}
                >
                {(() => {
                  switch (item.type) {
                    case "input":
                      return <Input placeholder={item.placeholder}  style={{ borderRadius: 6 }} />
                    case "select":
                      return <Select placeholder={item.placeholder}
                        style={{ width: "100%", }}>
                        {Array.from(item.options, (optionItem, id) => {
                          return <Select.Option key={id} value={optionItem.value}>{optionItem.label}</Select.Option>
                        })}
                      </Select>
                    case "phone":
                      return <Input style={{ borderRadius: 6,width: "100%",} } placeholder={item.placeholder} />
                      // return <Input style={{ borderRadius: 6,width: "100%",} } placeholder={item.placeholder} />
                      // return <NationPhone 
                      // rightStyle={{width:"calc(100% - 89px)"}}
                      // onChange={this.phone_change.bind(this)} 
                      // defaultPhoneString={item.placeholder}
                      // />
                    case "datePicker":
                      return <DatePicker
                        style={{
                          width: "100%",
                          borderRadius: 6
                        }}
                        placeholder={item.placeholder} />
                    case "textarea":
                      return <Input.TextArea  placeholder={item.placeholder} showCount maxLength={10000}

                        autoSize={{ minRows: 5 }}
                      />
                  }
                })()}
              </Form.Item>
            </Col>
            })}
          </Row>
        </SubCard>
      })}

      </Form>

      <ConfigProvider locale={zhCN}>
      <ModalForm  visible={this.state.visible} config={this.getModalConfig()} data={this.state.formData} submitInfo={this.updateInfo.bind(this)} onCancel={this.hideUserModal.bind(this)} />

      </ConfigProvider>

      </Form.Provider>
      <div id={"educationInfo"} className={"resume-item resume-history resume-workExpList"}>
        <div className={"item-primary"}>
          <h3 className={"title"}> 教育背景 <span style={{color:"red",fontSize: 16}}>*</span> <a onClick={()=>{
            this.setState({visible: true, modalType: 'educationInfo', selectIndex: null})
          }} className={"link-add"}><span>添加</span></a>  </h3>
          <ul>
            {Array.from(this.state.educationInfo, (card, cardKey) => {
              return (<li key={cardKey}>
                <div className={"primary-info"}>
                  <div className={"info-text"}>
                    <h4 className={"name"}>{card.school}</h4>
                    <span className={"gray period"}>{card.startDate}-{card.endDate}</span>
                  </div>
                  <div className={"info-text"}>
                    <span className={"text-type"}>学习专业：</span>{card.speciality}
                  </div>
                  <div className={"info-text"}>
                    <span className={"text-type"}>学历：</span>{card.academicDegree}
                  </div>
                </div>
                <div className={"op"}>
                  <a onClick={()=>{this.edit('educationInfo', cardKey)}} className={"link-edit"}><span>编辑</span></a>
                  <a onClick={()=>{this.delete('educationInfo', cardKey)}} className={"link-delete"}><span>删除</span></a>
                </div>
              </li>)

            })}
          </ul>
        </div>
      </div>

      <div id={"projectInfo"} className={"resume-item resume-history resume-workExpList"}>
        <div className={"item-primary"}>
          <h3 className={"title"}> 项目经历 <span style={{color:"red",fontSize: 16}}>*</span>  <a onClick={()=>{
            this.setState({visible: true, modalType: 'projectInfo', selectIndex: null})
          }} className={"link-add"}><span>添加</span></a>  </h3>
          <ul>
            {Array.from(this.state.projectInfo || [], (card, cardsKey) => {
              return (<li key={cardsKey}>
                <div className={"primary-info"}>
                  <div className={"info-text"}>
                    <h4 className={"name"}>{card.projectName}</h4>
                    <span className={"gray period"}>{card.startDate}-{card.endDate}</span>
                  </div>
                  <div className={"info-text"}>
                    <span className={"text-type"}>项目描述：</span>{card.projectDescription}
                  </div>
                  <div className={"info-text"}>
                    <span className={"text-type"}>职责&技能：</span>{card.responsibilities}
                  </div>
                </div>
              <div className={"op"}>
                  <a onClick={()=>{this.edit('projectInfo', cardsKey)}} className={"link-edit"}><span>编辑</span></a>
                  <a onClick={()=>{this.delete('projectInfo', cardsKey)}}  className={"link-delete"}><span>删除</span></a>
                  </div>
                  </li>)

            })}
          </ul>
        </div>
      </div>

      <div id={"practiceInfo"} className={"resume-item resume-history resume-workExpList"}>
        <div className={"item-primary"}>
          <h3 className={"title"}> 获奖经历 <a onClick={()=>{
            this.setState({visible: true, modalType: 'practiceInfo', selectIndex: null})
          }} className={"link-add"}><span>添加</span></a></h3>
          <ul>
            {Array.from(this.state.practiceInfo, (card, cardsKey) => {
              return (<li key={cardsKey}>
                  <div className={"primary-info"}>
                    <div className={"info-text"}>
                      <span className={"text-type"}>获奖时间：</span>{moment(card.awardDate).format("YYYY/MM/DD")}
                    </div>
                    <div className={"info-text"}>
                      <span className={"text-type"}>获奖名称：</span>{card.awardName}
                    </div>
                  </div>
                <div className={"op"}>
                  <a onClick={()=>{this.edit('practiceInfo', cardsKey)}} className={"link-edit"}><span>编辑</span></a>
                  <a onClick={()=>{this.delete('practiceInfo', cardsKey)}} className={"link-delete"}><span>删除</span></a>
                </div>
              </li>)

            })}
          </ul>
        </div>
      </div>

      <Form
        labelCol={{ span: 12 }}
        layout="vertical"
        ref={ref => this.form2Ref = ref}
        >

        <div id={"selfDescription"} className={"resume-item resume-history resume-workExpList"}>
          <div className={"item-primary"}>
            <h3 className={"title"}> 参赛说明 </h3>
            <Form.Item
              name="personal"
              label="参赛说明"
              rules={[{ required: true, message: '请填写参赛说明' }]}
            >
              <Input.TextArea placeholder={"可以自由的介绍自己、参赛动力以及对主办方的期待等等，我们会听到的"} showCount maxLength={500} />
            </Form.Item>
          </div>
        </div>
        {/*
          {
            "thread": "意向确认",
            "items": [
              {
                "title": "是否愿意加入九坤",
                "type": "select",
                "required": true,
                "placeholder": "请选择",
                "name": 'ConfirmationOfIntention',
                "options": [
                  {
                  label: '是',
                  value: 'yes'},

                  {
                    label: '否',
                    value: 'no'}
                ]
              }
            ]
          }, */}

        <div id={"selfDescription"} className={"resume-item resume-history resume-workExpList"}>
          <div className={"item-primary"}>
            <h3 className={"title"}> 意向确认 </h3>
            <Form.Item
              name="ConfirmationOfIntention"
              label="您未来是否有意向接受九坤职位邀请？"
              rules={[{ required: true, message: '请选择意向确认' }]}
            >
              <Select placeholder='请选择' style={{ width: "50%", }}>
                <Select.Option value={'yes'}>是</Select.Option>
                <Select.Option value={'no'}>否</Select.Option>

              </Select>
            </Form.Item>
          </div>
        </div>
      </Form>

      <Row gutter={32} style={{ paddingLeft: 24, alignItems: "center", marginBottom: 65}}>
        <Col span={12} >
          <Checkbox onChange={(e)=>{this.setState({userAgreement: e.target.checked})}} style={{ filter: "brightness(0.95)", userSelect: "none" }}>
            {"本人已阅读及同意"}
            <a style={{color: '#176EDE'}} onClick={()=> {this.setState({ModalVisible: true})}}>
              {"「参赛者协议」"}
            </a>
            {"中的所有条例"}
          </Checkbox>
        </Col>
        <Col span={12} style={{ textAlign:"right", paddingRight: 46}}>
          <Button
            size={"large"}
            type="primary"
            style={{
              background: "#176EDE",
              width: 166,
              // height: 50,
              borderRadius: 6,
              fontSize: 16
            }} onClick={ () => {
              if(!this.state.educationInfo.length){
                message.warn('请至少添加一项教育背景')
                return
              }
              if(!this.state.projectInfo.length){
                message.warn('请至少添加一项项目经历')
                return
              }
              if(!this.state.userAgreement){
                message.warn('请阅读并勾选确认参赛者协议')
                return
              }

              // if(!this.state.phone){
              //   message.warn('请输入手机号')
              //   return
              // }
              const form = this.formRef;
              const form2 = this.form2Ref;
              form.validateFields().then((basicInfo)=>{// 如果全部字段通过校验，会走then方法，里面可以打印出表单所有字段（一个object）
                console.log('成功')
                console.log(basicInfo, 'basicInfo')
                form2.validateFields().then((bigFormData)=>{// 如果全部字段通过校验，会走then方法，里面可以打印出表单所有字段（一个object）
                  console.log('成功2')
                  console.log(bigFormData)
                  this.submitInfo(basicInfo, bigFormData)
                }).catch((errInfo)=>{// 如果有字段没听过校验，会走catch，里面可以打印所有校验失败的信息
                  console.log('失败2')
                  console.log(errInfo)
                })
              }).catch((err)=>{// 如果有字段没听过校验，会走catch，里面可以打印所有校验失败的信息
                console.log('失败')
                console.log(err);

              })
            }}>
            {history.location.query?.edite ? '修改报名信息' : "提交报名信息"}
          </Button>
        </Col>
      </Row>
      <Modal
          title={"参赛者协议"}
          centered
          visible={this.state.ModalVisible}
          width={800}
          className={'modalStyle'}
          destroyOnClose={true}
          onCancel={()=>{
            this.setState({
              ModalVisible: false
            })
          }}
          footer={null}
        >
        {this.renderAuthButton()}
      </Modal>
      </Spin>
    </div>
  }

}

// reset form fields when modal is form, closed
const useResetFormOnCloseModal = ({ form, visible }) => {
  const prevVisibleRef = useRef();
  useEffect(() => {
    prevVisibleRef.current = visible;
  }, [visible]);
  const prevVisible = prevVisibleRef.current;
  useEffect(() => {
    if (!visible && prevVisible) {
      form.resetFields();
    }
  }, [visible]);
};

const ModalForm = ({ visible, submitInfo, onCancel, config, data }) => {
  const [form] = Form.useForm();
  const monthFormat = 'YYYY/MM/DD';
  useResetFormOnCloseModal({
    form,
    visible,
  });
  console.log(data,'dataChildrens');
  const newData = JSON.parse(JSON.stringify(data))
  if(typeof data.startDate  === 'string'){
    newData.startDate = moment(data.startDate);
    newData.endDate = moment(data.endDate);
  }
  if(typeof data.awardDate  === 'string'){
    newData.awardDate = moment(data.awardDate);
  }

  if(Object.keys(data).length > 0 && data.startDate) {
    newData.startDate = moment(data.startDate);
    newData.endDate = moment(data.endDate);
  }
  useEffect(()=>{
    form.setFieldsValue(newData);
  })
  const onOk = () => {
    form.submit()
  };
  const onFinish = (values: any) => {
    const data = JSON.parse(JSON.stringify(values));
    if(data.startDate) {
      if(new Date(data.endDate) - new Date(data.startDate) < 0) {
        message.warn('结束时间不能早于开始时间')
        return
      }
    }
    submitInfo(data)
  };
  return (
    <Modal width={"55%"} cancelText={'取消'} destroyOnClose={true} okText={"确认"} visible={visible} onOk={onOk} onCancel={onCancel} bodyStyle={{maxHeight: 500}} closable={false} maskClosable={false}>
      <Form form={form} name="userForm" onFinish={onFinish} labelCol={ {span:4}}>
      {Array.from(config, (item, itemKey) => {
          var rules = [{
            required: true,
            message: item.label+'必输！'
          }];
          return <Form.Item
                  key={itemKey}
                  name={item.name}
                  label={item.label}
                  rules={rules}
                >
                {(() => {
                  switch (item.type) {
                    case "input":
                      if(item.name == "school" || item.name == "speciality" || item.name == "academicDegree"){
                        const options = baseConfigObject[item.name+'_options']
                        return <AutoComplete filterOption={true} options={options} style={{ borderRadius: 6}} placeholder={item.placeholder} />
                      } else {
                        return <Input placeholder={item.placeholder} style={{ borderRadius: 6 }} />
                      }
                    case "select":
                      return <Select placeholder={item.placeholder} style={{ width: "100%", }}>
                        {Array.from(item.options, (optionItem, id) => {
                          return <Select.Option value={optionItem.value}>{optionItem.label}</Select.Option>
                        })}
                      </Select>
                    case "number":
                      return <Input style={{ borderRadius: 6 } }  placeholder={item.placeholder} />
                    case "datePicker":
                      return <DatePicker
                        style={{
                          width: "100%",
                          borderRadius: 6
                        }} placeholder={"选择日期"} format={monthFormat}/>
                    case "textarea":
                      return <Input.TextArea placeholder={item.placeholder} showCount maxLength={10000}
                        autoSize={{ maxRows: 3}}
                      />
                  }
                })()}
              </Form.Item>
      })}
      </Form>
    </Modal>
  );
};


type SubCardProps = {
  title: string
}
class SubCard extends PureComponent<SubCardProps, {}> {
  constructor(props: SubCardProps) {
    super(props);
    this.state = {
      sections: []
    };
  }
  render() {
    return <div style={{
      background: "#FFF",
      marginBottom: 20,
      marginTop: 20,
      borderBottom:"1px solid #EAEDF2",
      minHeight: 64,
      padding: 30,
    }}>
      <Title level={3}>{this.props.title}</Title>
      {this.props.children}
    </div>
  }
}


export default Page
