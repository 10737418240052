import { PureComponent } from 'react';
import { history } from 'umi';

// import * as 九坤logo from '../../../public/九坤logo-标准版.png';

import svgLogo from '../../../public/九坤logo.svg'



type FlashingLOGOState = {
    play?: boolean
};
const FlashingLOGOStyle: React.CSSProperties = {
    maxHeight: "100%",
    verticalAlign: "top",
}

export class FlashingLOGO extends PureComponent<{}, FlashingLOGOState> {
    timer?: NodeJS.Timer;
    constructor(props: {}) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.timer = setInterval(() => {
            if (this.state.play) {
                this.setState({
                    play: false
                })
            } else {
                this.setState({
                    play: true
                })
            }

        }, 3000);
    }
    componentWillUnmount() {
        if (this.timer) {
            clearInterval(this.timer)
        }
    }
    render() {
        return (
            <div style={{ position: "relative", overflow: "hidden", cursor: 'pointer'}} className={"logoClass"} onClick={() => {history.push('/home')}}>
                <img src={svgLogo} className={"FlashingLOGO"} style={FlashingLOGOStyle} />
                <div style={{
                    transition: this.state.play ? `left 2s` : "",
                    position: "absolute",
                    left: this.state.play ? "150%" : "-50%",
                    // left: "50%",
                    top: "-50%",
                    backgroundColor: "white",
                    height: "200%",
                    width: 8,
                    transform: "rotate(45deg)",
                    opacity: 0.8,
                    boxShadow: "1px 0px 10px white"
                }} />
            </div>
        );
    }
}
