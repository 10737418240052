import { Tabs, Typography } from 'antd';
import React, { PureComponent } from 'react';
import "./index.less";


type Props = {
  value: number
  height: number
  size: number
}
type State = {
  length?: number
  display?: true
  numbers: string[]
}

class NumberRoller extends PureComponent<Props, State> {
  Ref_main: React.RefObject<HTMLDivElement>;
  constructor(props: Props) {
    super(props);
    this.Ref_main = React.createRef();
    this.state = {
      numbers: []
    };
  }
  createObserver(target: HTMLDivElement) {
    let observer;

    let options = {
      root: null,
      rootMargin: "0px",
    };

    observer = new IntersectionObserver(this.handleIntersect.bind(this), options);
    observer.observe(target);
  }
  buildThresholdList() {
    let thresholds = [];
    let numSteps = 20;

    for (let i = 1.0; i <= numSteps; i++) {
      let ratio = i / numSteps;
      thresholds.push(ratio);
    }

    thresholds.push(0);
    return thresholds;
  }

  handleIntersect(entries: IntersectionObserverEntry[]) {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        this.setState({
          display: true
        })
      }
    });
  }



  componentDidMount() {
    const value = this.props.value
    const numbers = value.toString().split("")
    // const length = value.toString().length


    if (this.Ref_main && this.Ref_main.current) {
      this.createObserver(this.Ref_main.current);
    } else {
      this.setState({
        display: true
      })
    }
    this.setState({
      numbers
    })



  }

  render() {
    return <>
      <div className='NumberRoller' ref={this.Ref_main} style={{
        height: this.props.height || 64,
        fontSize: this.props.size || 48
      }}>
        {Array.from(this.state.numbers || [], (item, id) => {
          if (this.state.display) {
            const rest = (this.state.numbers.length - id) % 3 === 0
            const notFirst = (0 !== id)
            if (rest && notFirst) {
              return <>
                <div className={"delimiter"}>{","}</div>
                <OneNumber value={Number(item)} />
              </>
            }
            return <OneNumber value={Number(item)} />
          }
        })}
      </div>
    </>
  }
}
type OneNumberProps = {
  value: number
}

type NumLim = "0" | "1" | "2" | "3" | "4" | "5" | "6" | "7" | "8" | "9"
class OneNumber extends PureComponent<OneNumberProps, {}> {
  Ref: React.RefObject<HTMLDivElement>;
  Ref_0: React.RefObject<HTMLDivElement>;
  Ref_1: React.RefObject<HTMLDivElement>;
  Ref_2: React.RefObject<HTMLDivElement>;
  Ref_3: React.RefObject<HTMLDivElement>;
  Ref_4: React.RefObject<HTMLDivElement>;
  Ref_5: React.RefObject<HTMLDivElement>;
  Ref_6: React.RefObject<HTMLDivElement>;
  Ref_7: React.RefObject<HTMLDivElement>;
  Ref_8: React.RefObject<HTMLDivElement>;
  Ref_9: React.RefObject<HTMLDivElement>;

  constructor(props: Props) {
    super(props);
    this.Ref = React.createRef();
    this.Ref_0 = React.createRef();
    this.Ref_1 = React.createRef();
    this.Ref_2 = React.createRef();
    this.Ref_3 = React.createRef();
    this.Ref_4 = React.createRef();
    this.Ref_5 = React.createRef();
    this.Ref_6 = React.createRef();
    this.Ref_7 = React.createRef();
    this.Ref_8 = React.createRef();
    this.Ref_9 = React.createRef();

  }
  componentDidMount() {
    const inputValue = this.props.value.toString()
    let id: NumLim = "0"
    switch (inputValue) {
      case "0":
      case "1":
      case "2":
      case "3":
      case "4":
      case "5":
      case "6":
      case "7":
      case "8":
      case "9":
        id = inputValue
        break;

      default:
        return
    }
    const current = this[`Ref_${id}`].current
    const main = this[`Ref`].current
    setTimeout(() => {
      if (current && main) {
        const height = current.offsetHeight
        const top = -1 * height * (Number(id) +10)
        main.style.top = `${top}px`

      }
    }, 0);

  }

  render() {
    return <div className={"oneNumber d1"} ref={this.Ref}>
      <div>0</div>
      <div>1</div>
      <div>2</div>
      <div>3</div>
      <div>4</div>
      <div>5</div>
      <div>6</div>
      <div>7</div>
      <div>8</div>
      <div>9</div>

      
      <div ref={this.Ref_0}>0</div>
      <div ref={this.Ref_1}>1</div>
      <div ref={this.Ref_2}>2</div>
      <div ref={this.Ref_3}>3</div>
      <div ref={this.Ref_4}>4</div>
      <div ref={this.Ref_5}>5</div>
      <div ref={this.Ref_6}>6</div>
      <div ref={this.Ref_7}>7</div>
      <div ref={this.Ref_8}>8</div>
      <div ref={this.Ref_9}>9</div>
    </div>
  }
}

export default NumberRoller
