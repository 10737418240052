import request from '@/utils/request';
import { API } from './default';


type GetSmsCodeParams = { phone: string }

type UserSmsCodeParams = { phone: string, check_duplciate: boolean }

/** 
 * @deprecated （旧版）登录/注册时 发送验证码
 * 
 * 这个函数已经被新的函数替代，请使用 getSmsCodeWithPowAnswer
*/
export async function getSmsCode(params: GetSmsCodeParams): Promise<API<{}>> {
  throw "弃用"
  return request('/api/sms/send',
    {
      method: 'post',
      data: params,
    });
}

/** 
 * @deprecated （旧版）发送验证码
 * 这个函数已经被新的函数替代，请使用  
*/
export async function getSmsCodeLoginUser(params: UserSmsCodeParams): Promise<API<{}>> {
  throw "弃用"

  return request('/api/sms/send',
    {
      method: 'post',
      data: params,
    });
}

// 登陆接口
export async function verifySmsCode(params) {
  return request('/api/user/login',
    {
      method: 'post',
      data: params,
    });
}
/**退出登录 */
export async function logout(): boolean {
  const result = await request('/api/user/logout', {
    method: 'GET',
  });
  if (result.success) {
    return true

  }
  return false

}

// 获取Pow问题
export async function getPowQuestion(): Promise<API<{
  "salt": string
  "prefix": string
}>> {
  return request('/api/sms/pow-question');
}



type UserSmsCodeParamsWithPowAnswer = {
  "phone": string //+8612345
  "countryCallingCode": string //+86 
  "nationalNumber": string //12345
  "key": string
}
// 发送验证码
export async function getSmsCodeWithPowAnswer(params: UserSmsCodeParamsWithPowAnswer): Promise<API<{}>> {
  return request('/api/sms/send',
    {
      method: 'post',
      data: params,
    });
}