import { GeneralEditor } from '@/component/Editor';
import { message, Modal } from 'antd';
import React, { PureComponent } from 'react';
import { addForumComment, editComment } from "../../../../services/forum";


export type CommentForEdit = {
    behavior: CommentEditorBehaviorType
    content: string
    replyToUserId?: number
    /**一级评论 */
    commentId?: number
    postId: number
}
export type CommentEditorBehaviorType = "edit" | "new"
type CommentModalReEditorProps = {
    editing?: CommentForEdit
    onDone: () => void
    okText?:string
} & ({
    onCancel: () => void
    notModal?: false | undefined
} | {
    notModal: true
})

/**重新编辑评论或回复评论 
 * 
 * 默认以弹窗出现 */
export class CommentModalReEdit extends PureComponent<CommentModalReEditorProps> {
    constructor(props: CommentModalReEditorProps) {
        super(props);
    }
    render() {
        const okText = this.props.okText || "回复"
        if (this.props.notModal) {
            return <GeneralEditor
                defalutContent={this.props.editing?.content || ""}
                feature={'limit'}
                okText={okText}
                onref={ref => this.GeneralEditorRef = ref}
                onDone={this.sendPost.bind(this)}
            />
        }
        return <Modal
            centered
            visible={!!this.props.editing}
            width={1000}
            className={'CommentModalReEditor'}
            destroyOnClose={true}
            onCancel={this.props.onCancel}
            footer={null}
        >
            <GeneralEditor
                defalutContent={this.props.editing?.content || ""}
                feature={'limit'}
                okText={okText}
                onref={ref => this.GeneralEditorRef = ref}
                onDone={this.sendPost.bind(this)}
            />
        </Modal>
    }

    async sendPost(html: string) {
        if (!this.props.editing) { return }
        const editing = this.props.editing
        switch (editing.behavior) {
            case "edit":
                await editComment({
                    "post_id": editing.postId,
                    "comment_id": editing.commentId,
                    "comment_content": html
                })
                message.info("修改完成")
                break;
            case "new":
                await addForumComment({
                    "post_id": editing.postId,
                    "comment": html,
                    "replyto_uid": editing.replyToUserId,
                    "parent_cid": editing.commentId,
                })
                message.info("创建完成")
                break;

            default:
                break;
        }
        this.GeneralEditorRef && this.GeneralEditorRef.clearContent()
        this.props.onDone()
    }
}
