import { getUFConfig, ufURL } from "@/config/env";
import { GENERAL_UPLOAD_IMAGE_FILE_SIZE_LIMIT } from "@/config/GeneralConfig";
import { API_uploadImage } from "@/services/files";
import { message } from "antd";
import { UploadProps } from "antd/lib/upload";

export type AntdUploadCustomRequestEvent = Parameters<Extract<UploadProps["customRequest"], Object>>[0]
type AntdCustomRequestEvent_Extend = AntdUploadCustomRequestEvent & {
    file: {
        uid: string
        lastModified: number
        lastModifiedDate: Date
        name: string
        size: number
        type: string
    }
}
export const AntdUploadImage = async (event: AntdUploadCustomRequestEvent | AntdCustomRequestEvent_Extend) => {
    if (event.file instanceof Blob && "name" in event.file) {
        const fileName = event.file.name || "file"
        const imageData = await event.file.arrayBuffer()
        return await UploadImage(imageData, fileName)

    } else {
        message.error('文件上传遇到问题');
        console.error("debug", "AntdUploadImage", event)
        throw "event.file 类型错误"
    }
}



type DrupalImage = {
    uid?: number
    fid?: number
    uuid?: string
    name?: string
    status?: boolean
    /** 图片绝对路径 */
    url: URL
    /** drupal 内部资源链接 
     * 
     * 格式 public://XXX
     */
    drupalURI?: URL
}
export const UploadImage = async (imageData: ArrayBuffer, fileName: string): Promise<DrupalImage | undefined> => {
    if (imageData.byteLength > GENERAL_UPLOAD_IMAGE_FILE_SIZE_LIMIT) {
        message.error('超过 2MB 的图片是不被允许的');
        return
    }

    const encodedFileName = encodeURIComponent(fileName)
    const response = await API_uploadImage(imageData, encodedFileName)
    console.log("UploadImage response", response)

    if (!response.success) {
        message.error('图片上传失败');
        return
    }
    const URIPop = response.data.uri.pop()
    if (!URIPop) {
        message.error('服务器出错');
        return
    }
    const imgURL = ufURL(URIPop.url)
    console.log('图片上传成功：', imgURL);
    const responseData: DrupalImage = {
        uid: response.data.uid.pop()?.target_id,
        fid: response.data.fid.pop()?.value,
        uuid: response.data.uid.pop()?.target_uuid,
        name: response.data.filemime.pop()?.value,
        status: response.data.status.pop()?.value,
        url: imgURL,
        drupalURI: new URL(URIPop.value),
    }

    return responseData
    //     if (res.success && res.data.uri.length < 1) {
    //         console.log('图片上传失败:' + JSON.stringify(res));
    //         message.error('图片上传失败，请重试');
    //     } else {
    //         let image_uri = res.data['uri'][0]['url'];
    //         console.log('图片上传成功：' + image_uri);
    //         let image_url = UF_CONFIG.api_url + image_uri;
    //         const imgItem = {
    //             uid: file.uid,
    //             name: file.name,
    //             status: 'done',
    //             url: image_url, // url 是展示在页面上的绝对链接
    //             imgUrl: image_url, // imgUrl 是存到 db 里的相对链接
    //             // response: '{"status": "success"}',
    //         };
    //         this.setState({
    //             imgList: [imgItem],
    //         }); // 更新 imgList
    //         this.setState({
    //             editorState: ContentUtils.insertMedias(this.state.editorState, [{
    //                 type: 'IMAGE',
    //                 url: image_url
    //             }])
    //         })
    //     }
    // })
    // .catch((e) => {
    //     console.log('smyhvae 图片上传失败:' + JSON.stringify(e || ''));
    //     message.error('图片上传失败，请重试');
    // });
}