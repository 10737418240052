import {
  CommentOutlined, LikeOutlined, PushpinOutlined, VerticalAlignTopOutlined
} from '@ant-design/icons';
import { Avatar, Button, List, Pagination, Progress, Skeleton, Tabs, Typography, Popover } from 'antd';
import { PureComponent } from 'react';
import { history, Link } from "umi";
// import { User } from "../../../functions/UserCentral/type";
import {
  getForumSection, getSectionTopics
} from "../../../services/forum";
import { Dislike, ListVotes } from "../ListVotes";
import './index.less';
// import ZTabs from './tabs';
import { SortingSelectionValue, SortingSelector } from '@/component/Select/SortingSelector';
import { Icon } from "@/functions/IconLab";
import { TimePassed } from '@/functions/timeDiff';
import actionCreator from '@/redux/store/actionCreator';
import store from '@/redux/store/store';
const { Title, Text } = Typography;
const { TabPane } = Tabs;

type TypeList = {
  id: number
  section_id: number
  section_name: string
  title: string
  is_like: boolean
  view_count: number
  comment_count: number
  like_count: number
  sticky: boolean
  good: boolean
  status: boolean
  is_locked: boolean
  create_date: Date
  latest_comment_timestamp: Date
  latest_action?: {
    type: string
    author: string
    time: Date
  }
  author?: {
    name: string
    id: number
    picture: string
  }
}[]

type State = {
  posts?: TypeList
  list_top?: TypeList
  page?: number
  total?: number
  section_id: number
  section_name: string
  section_desc: string
  // posts?: TypeBlockItemList4RenderPost[]
  zoneIdString: string
  sortBy: string
}

class Page extends PureComponent<{}, State> {
  constructor(props: {}) {
    super(props);
    this.state = {
      zoneIdString: props?.id,
      sortBy: 'created'
    };
  }
  async componentDidMount() {
    const zoneIdString = this.state.zoneIdString || history.location.pathname.split("/")[3];
    const zoneId = Number(zoneIdString) || 0
    const res = (await getSectionTopics(zoneId, 1, 'created')).data
    const items = res.data
    console.log("getSectionTopics", zoneId, items)

    let total = res.count
    let page = res.page

    const list1: TypeBlockItemList4RenderPost[] = [], list_top = [];

    for (let item of items) {
      if (!item.good) {
        list1.push(item);
      } else {
        list_top.push(item)
      }
    }

    let { sections } = store.getState()
    if (sections.length == 0) {
      const result = await getForumSection()
      const data = result.data
      sections = []
      for (let item of data) {
        sections.push({
          id: item.id,
          key: item.id,
          icon: (item.icon ? new URL(item.icon) : ''),
          name: item.name,
          description: item.description || "",
          topics: item.last_posts || [],
        })
      }
      if (this.props.name) {
        sections.push({
          id: zoneId,
          key: zoneId,
          icon: (''),
          name: this.props.name,
          description: "",
          topics: [],
        })
      }
      actionCreator.setSections(sections);
    }
    let section_name = ''
    section_name = `未能识别的版块(${zoneId})`
    let section_desc = ''
    console.log('sections: ', sections)
    for (let section of sections) {
      if (section.key == zoneId) {
        section_name = section.name;
        section_desc = section.description;
      }
    }
    this.setState({
      total: total,
      page: page,
      posts: list1,
      list_top: list_top,
      section_id: zoneId,
      section_name: this.props.name || section_name,
      section_desc: section_desc,
    })

  }

  reloadTopics = async (pageCurrent, sortBy) => {
    console.log(this.state.section_id)

    this.setState({
      posts: [],
      list_top: []
    });

    const result2 = await getSectionTopics(this.state.section_id, pageCurrent, sortBy || this.state.sortBy)
    const data2 = result2.data
    console.log("reloadTopics", data2)
    let total = data2.count
    let page = data2.page
    const list_top: TypeList[] = [], list_other = [];

    for (let item of data2.data) {
      item.key = item.id;
      if (item.good) {
        list_top.push(item)
      } else {
        list_other.push(item);
      }
    }


    this.setState({
      posts: list_other,
      total,
      page,
      list_top: list_top
    })
  }

  onChangePage = (page, pageSize) => {
    console.log('page, ', page);
    this.reloadTopics(page);
    console.log('pageSize, ', pageSize);
  }

  onSortChange = (sortBy: SortingSelectionValue) => {
    this.reloadTopics(this.state.page, sortBy);
    this.setState({ sortBy: sortBy })
  }

  render() {
    console.log("this.zone", this.state)
    return (
      <div className="forum-zone-container src\pages\discussions\zone\index.tsx">
        <div className="common-header2">
          <div className="left">
            <h1>{this.state.section_name}</h1>
            <div className="desc">{this.state.section_desc}</div>
          </div>
          <div className="right" style={{ display: 'flex' }}>
            <SortingSelector onChange={this.onSortChange.bind(this)} />
            <Link to={`/discussions/new/${this.state.section_id}${this.state.zoneIdString ? '?from=competition' : ''}`}>
              <Button style={{ color: '#333' }} shape="round" icon={<Icon id="PlusOutlined" />} >新建话题</Button>
            </Link>
          </div>
        </div>
        {/* <ZTabs> */}
        {/* <TabPane tab={"所有"} key="1"> */}
        {this.state.list_top?.length > 0 ? <>
          <div style={{ fontSize: 14, color: '#176EDE', lineHeight: '21px', marginBottom: 30 }}>置顶 <VerticalAlignTopOutlined style={{ color: '#176EDE', fontSize: 14 }} /></div>
          <RenderPost top={true} list={this.state.list_top || []} zoneIdString={this.state.zoneIdString} />
        </> : ''}
        {this.state.posts?.length ? <><div style={{ fontSize: 14, color: '#7a7a7a', lineHeight: '21px', marginBottom: 30 }}>其他帖子</div>
          <RenderPost top={true} list={this.state.posts} zoneIdString={this.state.zoneIdString} /></> : ''}
        <Pagination
          className={"discussions_main_Pagination"}
          // defaultCurrent={this.state.page + 1}
          current={this.state.page + 1}
          total={this.state.total}
          showSizeChanger={false}
          // pageSizeOptions={10}
          pageSize={10}
          onChange={this.onChangePage}
        />
        {/* </TabPane> */}
        {/* <TabPane tab={"我的"} key="2"> */}
        {/* <RenderPost /> */}
        {/* <RenderPost /> */}
        {/* </TabPane> */}
        {/* </ZTabs> */}
      </div>
    );
  }
}


type RenderPostState = {
  // list?: TypeBlockItemList[]
  page?: number
  total?: number
  search?: string
}

type RenderPostProps = {
  top?: boolean
  list?: TypeList
}
class RenderPost extends PureComponent<RenderPostProps, RenderPostState> {
  constructor(props: RenderPostProps) {
    super(props);
    this.state = {
    };
  }

  PinIcon = <div style={{
    position: "absolute",
    bottom: 0,
    right: 0,
    backgroundColor: "white",
    borderRadius: "100%",
    height: 25,
    width: 26,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    boxShadow: "1px 1px 0px #e1e1e1",
  }}>
    <PushpinOutlined />
  </div>


  render() {
    return <div>
      <List<TypeList >
        className="forum-search-list"
        loading={!(this.props.list || this.state.list)}
        itemLayout="horizontal"
        dataSource={(this.props.list || this.state.list)}
        renderItem={item => (
          <List.Item key={item.id}
          >
            <Skeleton avatar title={false}
              loading={!(this.props.list || this.state.list)}
              active>

              <List.Item.Meta
                style={{ display: "flex", alignItems: "center" }}
                avatar={
                  <Progress
                    type="circle"
                    width={44}
                    // percent={Math.ceil(Math.random() * 100)}
                    percent={0}
                    // strokeColor={item.themeColor}
                    format={() => <Link to={{ pathname: `/user/${item?.author.id}`, query: { "uid": item?.author.id } }}><Avatar
                      src={item?.author?.picture}
                      style={{ backgroundColor: item.themeColor, }}
                      size={38}
                    /></Link>} />
                }
                title={<Link style={{ width: '100%' }} to={{ pathname: `/discussions/post/${item.id}${this.props.zoneIdString ? '?from=competition' : ''}` }}>{item.title}</Link>}
                description={<><Link style={{ width: '100%', color: '#333' }} to={{ pathname: `/discussions/post/${item.id}${this.props.zoneIdString ? '?from=competition' : ''}` }}>
                  <Text >
                    {item?.author?.name}
                  </Text>
                  发表于
                  <Text >
                    {item.section_name}
                  </Text>
                  , 最后由
                  <Text >
                    {item?.latest_action?.author}
                  </Text>
                  于
                  <Text >
                    {TimePassed(new Date(item?.latest_action?.time || 0), {
                      sec2JustNow: true,
                      afterfix: "前"
                    }) || "刚刚"}

                  </Text>
                  发表
                  {item?.latest_action?.type == 'post_create' ? '主题' : '评论'}
                </Link></>}
              />
              <div className="like-icons">
                <Dislike style={{ display: "block" }} targetId={item.id} targetType={"post"} defaultState={item.is_like} votes={item.like_count} />
                <span>{`${item.comment_count} 条评论`}</span>
              </div>
            </Skeleton>
          </List.Item>
        )}
      />

    </div>
  }
}



type ListMakerState = {
}

type ListMakerProps = {
  type: "post" | "comment"
  tid: number
  title: string
  loading: boolean
  author?: {
    avatar: {
      picture: URL
    }
    name: string
  }
  section?: {
    name: string,
  }
  comments?: string
  latest_action?: {
    author: {
      name: string
    }
    time: Date
    type: string//不知道啥类型
  }
  comment_count: string
  new_message: string
  like_count: number

}


export class ListMaker extends PureComponent<ListMakerProps, {}> {
  constructor(props: ListMakerProps) {
    super(props);
    this.state = {
    };
  }


  render() {
    return <List.Item
      className='ListMaker' style={{ cursor: 'pointer' }} id={this.props.pageId || ''}>
      <Skeleton avatar title={false}
        loading={this.props.loading}
        active>
        <List.Item.Meta
          style={{ display: "flex", alignItems: "center" }}
          avatar={this.props.author ? <Progress
            type="circle"
            width={44}
            percent={0}
            format={() => <Link to={{ pathname: `/user`, query: { "uid": this.props.author?.id } }}>
              <Avatar src={this.props.author.avatar.picture.href} size={38} /></Link>} /> : ""
          }
          title={<div style={{ position: 'relative' }}>
            <Link
              style={{ width: '100%', cursor: 'pointer', fontSize: 18, lineHeight: '27px', color: '#000' }}
              // onClick={(event) => {
              //   event.stopPropagation()
              //   event.preventDefault()
              //   const pathname = `/discussions/post/${this.props.tid}`
              //   document.location.pathname = pathname
              // }}
              to={{
                pathname: `/discussions/post/${this.props.tid}`,
                query: {
                  commentId: (this.props.type === "post" ? "" : (this.props.pageId || ""))
                }
              }} >
              <div className='overHidden ListMaker-title' style={{ color: "#333333", paddingRight: 130 }} dangerouslySetInnerHTML={{
                __html: this.props.title || (() => {
                  console.error("没有评论内容", item)
                  return `<span class="no-content"></span>`
                })()
              }} /></Link> {this.props.new_message == '1' ? <span className="dotRed"></span> : ''}</div>}
          description={<>
            <Link style={{ display: 'block', cursor: 'pointer', position: 'relative', paddingRight: 130 }}
              to={{
                pathname: `/discussions/post/${this.props.tid}`,
                query: {
                  commentId: (this.props.type === "post" ? "" : (this.props.pageId || ""))
                }
              }} >
              <Text>
                {this.props.author ? this.props.author.name : ""}
              </Text>
              <Text style={{ fontSize: 13 }}>
                {this.props.content}
                {this.props.section ? this.props.section.name : "论坛"}
              </Text>
              {(() => {
                if (this.props.latest_action) {
                  return <>
                    , 最后由
                    <Text >
                      {this.props.latest_action ? this.props.latest_action.author.name : "论坛"}
                    </Text>
                    于
                    <Text >
                      {TimePassed(new Date(this.props.latest_action.time || 0), {
                        sec2JustNow: true,
                        afterfix: "前"
                      }) || "刚刚"}

                    </Text>
                    发表</>
                  // } else if (this.props.like_count || this.props.comment_count) {
                  /**改成无论是否有点赞和评论都要显示 */
                } else {
                  return <span style={{ position: 'absolute', right: '10px', color: '#333' }}>
                    {this.props.type === "post" ? <>
                      <CommentOutlined />
                      <Text style={{ width: 30, display: 'inline-block', textAlign: 'right', marginRight: 10 }}>
                        {this.props.comment_count || '0'}
                      </Text>
                    </> : ""}
                    <LikeOutlined />
                    <Text style={{ width: 30, display: 'inline-block', textAlign: 'right' }}>
                      {this.props.like_count || '0'}
                    </Text>
                  </span>
                }

              })()}


              {this.props.latest_action ? (this.props.latest_action.type == 'post_create' ? '主题' : '评论') : ""}
              <div className='ListMaker-content' style={{ marginTop: "20px", color: "#333333", "display": "-webkit-box", "-webkit-box-orient": "vertical", "-webkit-line-clamp": "3", "overflow": "hidden", "max-height": /<img[^>]*>/.test(this.props.description) || /<video[^>]*>/.test(this.props.description) ? 'auto' : '70px' }} dangerouslySetInnerHTML={{
                __html: ((this.props.description || "").replace(/<img[^>]*>/g, "<span>[图片]</span>").replace(/<video[^>]*>/g, "<span>[视频]</span>"))
              }} />
              {/* <div style={{marginTop: "20px", color: "#333333", "display": "-webkit-box", "-webkit-box-orient": "vertical", "-webkit-line-clamp": "3", "overflow": "hidden","height": /<img[^>]*>/.test(this.props.description) || /<video[^>]*>/.test(this.props.description) ? 'auto': '70px' }} dangerouslySetInnerHTML={{
              __html: (this.props.description)
            }} /> */}
            </Link>
            {/* <Popover content={()=> {return (<div dangerouslySetInnerHTML={{
                        __html: this.props.description || (() => {
                          return `<span class="no-content"></span>`
                        })()
                      }} />)}} trigger="hover">
                  
            </Popover>   */}
          </>}
        />
      </Skeleton>
    </List.Item>
  }
}
export default Page
