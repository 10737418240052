import { Layout, Menu } from 'antd';
import { PureComponent } from 'react';
import ForumFooter from '../../layouts/footer_black';
import '../global.less';
import './index.less';

const { Header, Footer, Sider, Content } = Layout;

const { SubMenu } = Menu;

type HomeState = {
};
export class Home extends PureComponent<{}, HomeState> {
  constructor(props: {}) {
    super(props);
    this.state = {
    };
  }
  render() {
    return (
      <div className="homepag-container">
        <Layout className="forum-container">
          <Content className="forum-content" style={{padding:0}}>
            {this.props.children}
          </Content>
          <Footer className="home-forum-footer">
            <ForumFooter mode="inline" />
          </Footer>
        </Layout>
      </div>
    );
  }
}

export default Home;
