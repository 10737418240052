import {
  SearchOutlined
} from '@ant-design/icons';
import { Button, Input, Menu } from 'antd';
import { PureComponent } from 'react';
import ForumFooter from '../../layouts/footerSimple';
import { Menu as SelfMenu } from '../../layouts/menu_home/';
import '../global.less';
import './index.less';
import topLeftLogo from '../../../public/九坤logo.svg'
import backgroundImage from '../../../public/pattern-blue-unit.jpg'
import { history } from 'umi';

const { SubMenu } = Menu;

const logo = (
  <div style={{ display: 'inline-block' }}>
    {/* <DesktopOutlined /> */}
    {'LOGO'}
  </div>
);

type GeneralLayoutState = {
  menuHide: boolean;
};
export class GeneralLayout extends PureComponent<{}, GeneralLayoutState> {
  constructor(props: {}) {
    super(props);
    this.state = {
      menuHide: false,
    };
  }

  render() {
    const inlineCollapsed = this.state.menuHide;
    const leftLogoWidth = 180;
    return (
      <div className="layouts_frontend_left_header body-login" style={{ backgroundImage: `url(${backgroundImage})` }}>
        <aside style={{
          paddingLeft: 10,
          backgroundColor: '#fff',
          position: "fixed",
          left:0,
          top: 0,
          width: '100vw',
          zIndex: "999"
        }}>
          <div
            className={'layouts_frontend_left_header_logo '}
            style={{
              width: leftLogoWidth,
              display: 'inline-block',
              background: 'white',
              borderBottomColor: '#F0F0F0',
              borderBottomStyle: 'solid',
              borderBottomWidth: 1,
            }}
          >
            <div
              style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontWeight: 600,
                fontSize: 24,
                // backgroundImage:
                backgroundImage: `url(${topLeftLogo})`,
                backgroundSize: "100%",
                // backgroundSize: 'contain',
                backgroundPosition: '50% 50%',
                backgroundRepeat: 'no-repeat',
              }}
            >
              {/* {'九坤投资'} */}

            </div>
          </div>
          <SelfMenu mode="horizontal" onWidthChange={() => { }} />
          <div
            className={'layouts/frontend/left/heder/search'}
            style={{
              width: leftLogoWidth,
              display: 'inline-block',
              background: 'white',
              borderBottomColor: '#F0F0F0',
              borderBottomStyle: 'solid',
              borderBottomWidth: 1,
            }}
          >
            <div
              style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                // fontWeight: 600,
                // fontSize: 24,
              }}
            >
              <Input
                style={{ borderRadius: 4 }}
                // size="large"
                placeholder={'请输入内容'}
                prefix={<SearchOutlined />}
                onPressEnter={(event) => {
                  const target = event.target as HTMLInputElement
                  const input = target.value
                  console.log("input", input)
                  history.push(`/search?wd=${input}`)
                }}
              />
            </div>
          </div>
          <div
            className={'layouts/frontend/left/heder/login'}
            style={{
              width: leftLogoWidth,
              display: 'inline-block',
              background: 'white',
              borderBottomColor: '#F0F0F0',
              borderBottomStyle: 'solid',
              borderBottomWidth: 1,
            }}
          >
            <div
              style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                // fontWeight: 600,
                // fontSize: 24,
              }}
            >
              <Button type="link">{'登录/注册'}</Button>
            </div>
          </div>
        </aside>
        <article style={{marginTop: 46}}>
          <div>{this.props.children}</div>
        </article>
        <footer className="forum-simple-footer-outer">
          <ForumFooter mode="inline" />
        </footer>
      </div>
    );
  }
}


export default GeneralLayout;
