import moment from 'moment';
type TimePassedConfig = {
    sec2JustNow?: boolean,
    afterfix?: string
}
export function TimePassed(time: Date, config?: TimePassedConfig) {
    let start_date = moment(time);
    let end_date = moment();

    let day = end_date.diff(start_date, 'day');
    let hour = end_date.diff(start_date, "hour");
    let minute = end_date.diff(start_date, "minute");

    if (day) { return `${day}天${config?.afterfix}` }
    if (hour) { return `${hour}小时${config?.afterfix}` }
    if (minute) {
        if (config?.sec2JustNow) {
            return false
        }
        return `${minute}秒${config?.afterfix}`
    }
    return false
}