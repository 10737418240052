import { PureComponent } from 'react';
import { Row, Col,Divider,Space } from 'antd';
import { Typography, List, Skeleton, Avatar, Dropdown } from 'antd';

import { SmileOutlined, MailOutlined,LinkedinOutlined } from '@ant-design/icons';
import UserProfileSvg from '@/pages/icons/user-profile.svg';
import Icon from '@ant-design/icons';
import { Link } from 'umi';

import "./index.less"

const { Title, Text } = Typography;

type State = {
  voted?: boolean
}

class HomeHeader extends PureComponent<{}, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
    };
  }
  onVote() {
    this.setState({
      voted: !this.state.voted
    })
  }
  render() {
    return <div className="forum-header">
    <div className="header-flex-container">
      <div className="header-flex-left">
        <img src="/assets/images/homepage/jiukun-logo.png" alt="" />
      </div>
      <div className="header-flex-middle">
        <div className="header-menu">
          <ul>
            <li><Link to="/home">主页</Link></li>
            <li><Link to="/competition">比赛</Link></li>
            <li><Link to="/discussions">论坛</Link></li>
            <li><Link to="/rank">论坛排名</Link></li>
          </ul>
        </div>
      </div>
      <div className="header-flex-right">
        <div className="header_user">
            <Link to="/user">
                <Icon className="menu-item-single" style={{filter: 'brightness(1000%)'}} component={UserProfileSvg}/>
            </Link>
        </div>
      </div>
    </div>
</div>
  }
}

export default HomeHeader
