import { Button } from 'antd';
import { PureComponent } from 'react';


import * as 九坤logo from '../../../public/九坤logo-标准版.png';



type FlashingButtonState = {
    play?: boolean
};
type FlashingButtonProps = {
    style?: React.CSSProperties
};
const FlashingButtonStyle: React.CSSProperties = {
    maxHeight: "100%",
    verticalAlign: "top",
}

export class FlashingButton extends PureComponent<FlashingButtonProps, FlashingButtonState> {
    timer?: NodeJS.Timer;
    constructor(props: {}) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.timer = setInterval(() => {
            // if (this.state.play) {
            // this.setState({
            //     play: false
            // })
            // } else {
            //     this.setState({
            //         play: true
            //     })
            // }

        }, 3000);
    }
    componentWillUnmount() {
        if (this.timer) {
            clearInterval(this.timer)
        }
    }
    render() {
        return (
            <div style={{
                position: "relative", overflow: "hidden", width: 166,
                ...this.props.style,
                height: 40,
            }} className={"logoClass"}>
                <Button
                    onMouseOver={() => {
                        if (!this.state.play) {
                            this.setState({
                                play: true
                            })
                            setTimeout(() => {
                                this.setState({
                                    play: false
                                })
                            }, 1500);
                        }
                    }}
                    // onMouseLeave={() => {
                    //     this.setState({
                    //         play: false
                    //     })
                    // }}
                    className="submit-button"
                    style={{
                        // display: "inline-block",
                        // background: "#176EDE",
                        // borderRadius: "4px",
                        // width: "166px",
                        // height: "40px",
                        // fontWeight: "normal",
                        // fontSize: "14px",
                        // lineHeight: "21px",
                        // padding: "9px",
                        // color: "#FFFFFF",
                        // textAlign: "center",
                        // marginRight: "14px",
                        // boxShadow: "2px 3px 4px #003c8b2c",
                        // transition: "all 0.3s",
                    }}>
                    {this.props.children}
                </Button>
                <div style={{
                    transition: this.state.play ? `left 2s` : "",
                    position: "absolute",
                    left: this.state.play ? "150%" : "-75%",
                    // left: "50%",
                    top: "-50%",
                    backgroundColor: "white",
                    height: "200%",
                    width: 8,
                    transform: "rotate(45deg)",
                    opacity: 0.8,
                    boxShadow: "white 18px -8px 12px 20px"
                }} />
            </div >
        );
    }
}
