import { Col, Row, Skeleton } from 'antd';
import { PureComponent } from 'react';

import { getCompetictionList, getPostList, UserActivityDataAtDiscussion, UserCompetition, UserPublicInfo } from '@/services/user';
import { List } from 'antd';
import moment from 'moment';
import { history as umiHistory } from 'umi';




type UserBillboard_CompetitionProps = {
    uid: number
    userInfo?: UserPublicInfo
}
type UserBillboard_CompetitionState = {
    competition?: UserCompetition[]
}

export class UserBillboard_Competition extends PureComponent<UserBillboard_CompetitionProps, UserBillboard_CompetitionState> {
    constructor(props: UserBillboard_CompetitionProps) {
        super(props);
        this.state = {

        };
    }
    render() {
        const userInfo = this.props.userInfo
        const competition = this.state.competition
        return <div className="UserBillboard_Competition base-info" style={{ marginRight: 34 }}>
            <div className="baseInfo-header">
                赛事成绩
            </div>
            <div className='awardInfo_self' style={{ textAlign: "center" }}>
                <div className="text">排名</div>
                {!userInfo ? <Skeleton.Button active /> : <>
                    <div className="text rankNumber">{userInfo.competition_range_array?.position || '-'}</div>
                    <div className="text number1">{userInfo.competition_range_array?.total || '-'}</div>
                </>}
            </div>
            <Row className="row_top3">
                <Col flex={2} style={{ display: 'flex', alignItems: "center", justifyContent: 'center' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: "center" }}>
                        <img src="/assets/images/goldl@2x.png" style={{ marginBottom: 5 }} height={"20px"} />
                        {!userInfo ? <Skeleton.Button active size='small' /> : userInfo.com_awards?.gold || '-'}
                    </div>
                </Col>
                <Col flex={2} style={{ display: 'flex', alignItems: "center", justifyContent: 'center' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: "center" }}>
                        <img src="/assets/images/silverl@2x.png" style={{ marginBottom: 5 }} height={"20px"} />
                        {!userInfo ? <Skeleton.Button active size='small' /> : userInfo.com_awards?.silver || '-'}
                    </div>
                </Col>
                <Col flex={2} style={{ display: 'flex', alignItems: "center", justifyContent: 'center' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: "center" }}>
                        <img src="/assets/images/bronzel@2x.png" style={{ marginBottom: 5 }} height={"20px"} />
                        {!userInfo ? <Skeleton.Button active size='small' /> : userInfo.com_awards?.bronze || '-'}
                    </div>
                </Col>
            </Row>
            <List
                className="list_"
                itemLayout="vertical"
                size="large"
                pagination={{
                    pageSize: 8,
                    hideOnSinglePage: true,
                    showSizeChanger: false
                }}
                dataSource={competition}
                footer={null}
                renderItem={item => {
                    const award = typeof item.award === "object" ? item.award : undefined

                    return <Row style={{ padding: '15px 30px', flexWrap: 'nowrap', cursor: 'pointer' }} onClick={() => this.itemClicked(item)}>
                        <Col span={20} style={{ display: 'flex', alignItems: "center" }}>
                            <div style={{ display: 'flex', flexDirection: 'column', wordBreak: 'break-all' }}>
                                <div className="title_">{item.competition_Name || ""}</div>
                                <div>
                                    {award?.metal_type == 1 ? <img src="/assets/images/goldl@2x.png" style={{ marginBottom: 5, marginRight: 5 }} height={"18px"} /> : ""}
                                    {award?.metal_type == 2 ? <img src="/assets/images/silverl@2x.png" style={{ marginBottom: 5, marginRight: 5 }} height={"18px"} /> : ""}
                                    {award?.metal_type == 3 ? <img src="/assets/images/bronzel@2x.png" style={{ marginBottom: 5, marginRight: 5 }} height={"18px"} /> : ""}
                                    <span className="timeCa">
                                        {moment(item.created).format('YYYY-MM-DD')}
                                    </span>
                                </div>
                            </div>
                        </Col>
                        <Col span={4} style={{ display: 'flex', alignItems: "center", justifyContent: 'flex-end' }}>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <span className="topNumber">{item.position}</span> <span className="right_text">{item.competition_users_count}</span>
                            </div>
                        </Col>
                    </Row>
                }}
            />
        </div>
    }
    async componentDidMount() {
        const response = await getCompetictionList({ uid: this.props.uid })
        console.log("getPostList", response)
        this.setState({ competition: response.data.data })
    }
    itemClicked(item: UserCompetition) {
            umiHistory.push(`/competition/item/?org_id=${item._id}`)
    }

}
