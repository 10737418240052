import request from '@/utils/request';
import { API } from './default';

type GetSmsCodeParams = {}

// 发送验证码
export async function getList(params): Promise<API<{}>> {
  return request(`/api/competiction/list?status=${String(params.status)}&size=${String(params.size)}&page=${String(params.page)}`,
    {
      method: 'GET',
    });
}

interface Tab {
  type: number;
  _id: string;
  title: string;
  content: string;
}

interface Task {
  _id: string;
  name: string;
  showLeaderboard: boolean;
}

interface Stage {
  tasks: Task[];
  showLeaderboard: boolean;
  _id: string;
  name: string;
  startDate: string;
  endDate: string;
}

interface Organization {
  _id: string;
  shortname: string;
  name: string;
}

interface Competition {
  _id: string;
  datasetBindings: any[];
  displayLabel: string;
  TaskdisplayLabel: string;
  type: number;
  name: string;
  image: string;
  startDate: string;
  endDate: string;
  shortDescription: string;
  tabs: Tab[];
  registerNotice: string;
  RegisterendDate: string;
  needregisterForm: boolean;
  registerForm: any[];
  registerAuthorization: boolean;
  registerAuthorizationFields: any[];
  stages: Stage[];
  usersNumber: number;
  teamsNumber: number;
  worksNumber: number;
  forum: string;
  maxMembersPerTeam: number;
  needVerify: boolean;
  seriesCompetition?: any;
  useKLab: boolean;
  organization: Organization;
  finalRanking: boolean;
  relatedColumns: any[];
  IsTeacherEmailRequiredInregisterForm: boolean;
  IsTeacherRequiredInregisterForm: boolean;
  teamNotification: string;
  status: number;
  imageHeading: string;
  award: string;
  optionValue: any[];
}

export type CompetictionDetail = {
  labVersions: any[];
  tabsToPublish: string[];
  hide: boolean;
  shareToPortal: boolean;
  enableDownload: boolean;
  enableFork: boolean;
  _id: string;
  type: number;
  organization: string;
  user: string;
  competition: Competition;
  imageHeading: string;
  publishAll: boolean;
  publishLeaderboard: boolean;
  publishForum: boolean;
  order: number;
  createDate: string;
  updateDate: string;
  __v: number;
  dataset?: any;
  lab?: any;
  data_from: string;
  forum_section_id: number;
  join: boolean;
  single_signon_link: string;
  title_hide: boolean;
  is_able_register: boolean;
  share_text: string;
}
// 详情接口
export async function detail(orgId, invite_code): Promise<CompetictionDetail> {
  return request(`/api/competiction/detail?org_id=${orgId}${invite_code ? '&invite_code=' + invite_code : ''}`,
    {
      method: 'GET',
    });
}

// 报名接口
export async function Competition_Registration(orgId, invite_code, params) {
  return request(`/api/v1/jobs/apply?org_id=${orgId}${invite_code ? ('&invite_code=' + invite_code) : ''}`,
    {
      method: 'POST',
      data: params,
    });
}

// 详情 比赛排行
export async function Competition_leadboard(orgId) {
  return request(`/api/competiction/leadboard?org_id=${orgId}`,
    {
      method: 'GET',
    });
}

// 详情 比赛排行
export async function user_resume() {
  return request(`/api/v1/user/last_resume`,
    {
      method: 'GET',
    });
}

