import { DiscussionComment } from '@/types/comment';
import { PostDetail } from '@/types/post';
import request, { syntheticURL } from '@/utils/request';
import { API } from './default';

/** 论坛板块id */
type SectionID = number
/** 论坛板块 */
export type ForumSection = {
  "id": SectionID
  "name": string
  "description": string
  "icon": string
  "last_comment": {
    "ago": string
    "user": {
      "id": string
      "name": string
      "fullname": string
    },
    "content": {
      "type": string
      "id": string
      "title": string
    }
  }
}
/** 论坛板块接口 */
export async function getForumSection(): Promise<API<ForumSection[]>> {
  return request('/api/forum/section');
}

/**论坛帖子接口 */
export async function getSectionTopics(topicId: number, page?: number, sort_by?: string, sort_direction?: string,): Promise<API<{
  count: number,
  page: number,
  data: {
    "id": string
    "title": string
    "is_like": boolean,
    "view_count": number,
    "author": {
      "name": string
      "id": string
      "picture": string
    },
    /**点赞总数 */
    "like_count": number,
    /**评论总数 */
    "comment_count": number,
    /**是否置顶 */
    "sticky": boolean,
    /** 是否加精 */
    "good": boolean,
    /**帖子是否已发布 */
    "status": boolean,
    /**ISO 时间 */
    "create_date": string
  }[]
}>> {
  let params = {
    "section": topicId,
    sort_by: sort_by || 'hot',
    sort_direction: sort_direction || 'DESC',
    'size': '20'
  };
  if (page > 0) {
    params['page'] = page - 1;
  }
  return request('/api/forum/post/list', {
    method: 'get',
    params: params,
  });
}

type searchConfig = {
  sort_by: "created" | "hot"
  sortDirection: "ASC" | "DESC"
  page: number
}


export type TopicSearchResultItem = {
  id: number
  section_id: number
  section_name: string
  title: string
  is_like: boolean
  view_count: number
  comment_count: number
  sticky: boolean
  good: boolean
  is_locked: boolean
  create_date: Date
  latest_comment_timestamp: Date
  status: boolean
  // themeColor: string
  // description: string
  latest_action: {
    type: string
    author: string
    time: Date
  }
  author: {
    name: string
    id: number
    picture: string
  }
}
/**论坛帖子搜索 */
export async function searchTopics(keyword: string, page?: number, tag?: string, sortBy?: string): Promise<API<{
  count: number,
  page: number,
  data: TopicSearchResultItem[]
}>> {
  let searchParm = {
    keyword: keyword,
    tag: tag,
    sort_by: sortBy || 'hot',
    sort_direction: 'DESC'
  };
  if (page > 0) {
    searchParm['page'] = page - 1;
  }
  // /api/forum/post/search?tag=20
  return request('/api/forum/post/search', {
    method: 'get',
    params: searchParm,
  });
}

/**帖子详情接口 */
export async function getPostDetail(tid: number): Promise<API<PostDetail>> {
  return request(`/api/forum/post/${tid}/detail`);
}


/** 帖子POST主体 */
type PostPostBody = {
  title: string
  content: string
  section: number
}
/** 创建帖子接口 */
export async function createForumPost(params: PostPostBody): Promise<API<{ id: number }>> {
  return request('/api/forum/post/create',
    {
      method: 'post',
      data: params,
    });
}

// // 帖子加精接口
// /*
// {
//   "id": 5,
//   "val": true
// }
// */
// export async function getForumPostAddGood(params) {
//   return request('/api/forum/post/good_toggle',
//     {
//       method: 'post',
//       data: params,
//     });
// }

// 创建评论接口
/**
 * https://github.com/4positives/ubiquant_forum_front/blob/main/docs/api_design.md#%E5%88%9B%E5%BB%BA%E8%AF%84%E8%AE%BA%E6%8E%A5%E5%8F%A3
 */
type CommentPostBody = {
  post_id: number
  /** 一级评论的comment id */
  parent_cid?: number
  comment: string
  subject?: "subject"
  /**回复对象贴子uid(), 只有对二级评论 , 才带上这个 */
  replyto_uid?: number
}
export async function addForumComment(params: CommentPostBody): Promise<API<{
  post_id: number
  comment_id: string
}>> {
  return request('/api/forum/post/comment',
    {
      method: 'post',
      data: {
        ...params,
        /**
         *  https://github.com/4positives/ubiquant_forum/issues/38#issuecomment-1046194715
         * */
        subject: "subject",
      },
    });
}

// 获取评论接口
export async function getTopicComments(postId: number, params?: { page?: number, size?: number }): Promise<API<DiscussionComment[]>> {
  params = params || {}
  // return request(`/api/forum/post/comment?post_id=${postId}&page=${params.page || 0}&size=${params.size || 10000}`);
  return request(`/api/forum/post/comment?post_id=${postId}`);
}

// //size page keyword
// // 获取评论接口
// export async function getSearch(size: number,page:): Promise<API<{
//   is_like: false;
//   like_count: number
//   id: number
//   parent_id: number
//   parent_data: string
//   author: {
//     name: string
//     id: number
//     picture: string
//   }; content: string
//   created: string
// }[]>> {
//   return request(`/api/forum/post/comment?post_id=${postId}`);
// }

// 点赞接口主题
/*
{
  "type": "post",
  "id": 5,
  "state": 1
}
*/
export async function setLike(params: {
  "type": "comment" | "post"
  "id": number
  "state": 1 | 0
}) {
  return request('/api/like',
    {
      method: 'post',
      data: params,
    });
}



/* 获取用户所有帖子接口

*  @deprecated
*/
export async function getUserPost(params: {
  "type": "comment" | "post"
  "id": number
  "state": 1 | 0
}) {
  return request('/api/user/posts',
    {
      method: 'post',
      data: params,
    });
}

/* 获取用户所有帖子（评论） 接口 */
export async function getUserGeneration(params: {
  with_comments?: boolean
  sort_direction?: "ASC" | "DESC"
  page?: number
  size?: number
  information?: string
}): Promise<API<{
  id: number;
  type: string;
  comment_body: string;
  uid: number;
  create_date: string;
  post_id: number;
  post_title: string;
  new_message: number;
}[]>> {
  return request(`/api/user/posts?with_comments=${params.with_comments ? 1 : 0}&information=${params.information}`,
    {
      method: 'get',
      data: {
        ...params,
        with_comments: params.with_comments ? 1 : 0
      },
    });
}
 


export type LikeState = "like" | "unset" | "dislike"
export type IsLike = -1 | 1 | 0
/* 获取用户所有帖子接口 */
export async function pointLike(params: {
  id: number
  setTo: LikeState
  type: "comment" | "post"
}): Promise<API<[]>> {
  const state: {
    [str in LikeState]: string
  } = {
    "like": "1",
    "unset": "0",
    "dislike": "-1"
  }
  return request('/api/like',
    {
      method: 'post',
      data: {
        ...params,
        state: state[params.setTo]
      },
    });
}

/* 删除评论接口 */
export async function deleteComment(postId: number, comment_id: number) {
  return request(`/api/forum/post/${postId}/comment/${comment_id}/delete`,
    {
      method: 'post',
      data: {},
    });
}

/* 编辑评论接口 */
export async function editComment(params) {
  return request(`/api/forum/post/comment_edit`,
    {
      method: 'post',
      data: params,
    });
}

/* 删除帖子接口 */
export async function deletePost(postId: number): Promise<API<[]>> {
  return request(`/api/forum/post/${postId}/delete`,
    {
      method: 'post',
      data: {}
    });
}

/* 编辑帖子接口 */
export async function editPost(params: {
  postId: number
  title: string
  content: string
  section: number
}): Promise<{ id: number }> {
  return request(`/api/forum/post/${params.postId}/edit`,
    {
      method: 'post',
      data: params,
    });
}

/**
 * 帖子标签
 */
export async function getTags() {
  return request(`/api/forum/post/tags`, { method: 'get', data: {} })
}

