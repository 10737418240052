import { PureComponent } from 'react';
import { Row, Col } from 'antd';

import { Typography, List, Skeleton, Avatar, Dropdown } from 'antd';

// import GIF from "../../../public/2022-1-23_AE_市场预测赛.gif"
const GIF = "/sites/default/files/default_images/20220422205005.gif"
// import GIF from "../../../public/2022-1-26-AE-市场预测赛v1.gif"
import "./index.less"
import ForumHeader from '../../layouts/header_home';
import { Layout } from 'antd';
import { Link } from 'umi';
import NumberRoller from '@/component/NumberRoller';
import { getHomeInfo } from '@/services/home'

const { Header } = Layout;
const { Title, Text } = Typography;
// type Props = {
//   votes: number
// }
type State = {
  voted?: boolean,
  primary?: object
}

class Page extends PureComponent<{}, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      primary: {}
    };
  }
  onVote() {
    // alert(String(this.state.voted))
    this.setState({
      voted: !this.state.voted
    })
  }

  componentDidMount() {
    getHomeInfo().then((res) => {
      this.setState((res.data || {}))
    })
  }

  render() {
    var info = this.state;
    const primaryLink: string = info.primary?.link || ""
    return (
      <div className="home-content-container">
        <div className="header-image-container" style={{
          backgroundImage: "url(" + UF_CONFIG.api_url + GIF + ")",
          backgroundPosition: 'top center',
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat'
        }}>
          {/* <img src={GIF} alt="" /> */}
          <Header className="forum-header">
            <ForumHeader />
          </Header>
        </div>
        <div className="com-detail-wraper">
          <Row>
            <Col span={8}>
              <div className="home-detail-item">
                <img src="/assets/images/homepage/home-location.svg" alt="" /><br />
                <span className="home-detail-number">
                  {info.primary.region_number ? <NumberRoller
                    value={(info.primary.region_number)}
                    height={72}
                    size={68} /> : 0}
                </span><br />
                <span className="home-detail-text">地区</span>
              </div>
            </Col>
            <Col span={8}>
              <div className="home-detail-item">
                <img src="/assets/images/homepage/home-cake.svg" alt="" /><br />
                <span className="home-detail-number">
                  {info.primary.total_reward ? <NumberRoller
                    value={(info.primary.total_reward)}
                    height={72}
                    size={68} /> : 0}
                </span><br />
                <span className="home-detail-text">总奖金</span>
              </div>
            </Col>
            <Col span={8}>
              <div className="home-detail-item">
                <img src="/assets/images/homepage/home-com-user.svg" alt="" /><br />
                <span className="home-detail-number">
                  {info.primary.total_competitor ? <NumberRoller
                    value={(info.primary.total_competitor)}
                    height={72}
                    size={68} /> : 0}
                </span><br />
                <span className="home-detail-text">选手</span>
              </div>
            </Col>
          </Row>
        </div>
        <div className="com-detail-title-container">
          <h5 className="title1">{info.primary?.highlight_title1}</h5>
          <h5 className="title2">{info.primary?.highlight_title2}</h5>
          <div className="title-signup" style={{ display: primaryLink ? undefined : "none" }}>
            <a href={primaryLink} target="_blank">
              <div className="signup-button"
                key="点击报名"
                style={{
                  backgroundImage: "url(" + "/assets/images/homepage/home-join-button.png" + ")",
                  backgroundPosition: 'center',
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat'
                }}>

              </div>
            </a>
          </div>
        </div>
        <div className="home-artile-list">
          <div className="list-title">探索星际奥秘</div>
          <div className="list-items">
            <Row gutter={32}>
              {Array.from(info.promote_line1 || [], (item, itemKey) => {
                let url = item.bg_img_url;
                return (
                  <Col span={8}>
                    <a href={item.link} target="_blank">
                      <div className="list-item" style={{
                        backgroundImage: "url(" + url + ")",
                        backgroundPosition: 'center',
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat'
                      }}>
                        <div className="item-title">
                          {item.title}
                        </div>
                      </div>
                    </a>
                  </Col>
                )
              })}
            </Row>
          </div>
        </div>
        <div className="home-artile-list">
          <div className="list-title">对话星际巅峰</div>
          <div className="list-items">
            <Row gutter={32}>

              {Array.from(info.promote_line2 || [], (item, itemKey) => {
                let url = item.bg_img_url;
                return (
                  <Col span={8}>
                    <a href={item.link} target="_blank">
                      <div className="list-item" style={{
                        backgroundImage: "url(" + url + ")",
                        backgroundPosition: 'center',
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat'
                      }}>
                        <div className="item-title">
                          {item.title}
                        </div>
                      </div>
                    </a>
                  </Col>
                )
              })}
              {/* <Col span={8}>
                <Link href="#" target="_blank">

                  <div className="list-item" style={{
                    backgroundImage: "url(" + "/assets/images/homepage/home-article-5.png" + ")",
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat'
                  }}>
                    <div className="item-title">
                      【代码分享】模拟撮合程序设计思路
                    </div>
                  </div>
                </Link>

              </Col>
              <Col span={8}>
                <Link href="#" target="_blank">
                  <div className="list-item" style={{
                    backgroundImage: "url(" + "/assets/images/homepage/home-article-6.png" + ")",
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat'
                  }}>
                    <div className="item-title">
                      【论坛精华】组队与分工的合理搭配
                    </div>
                  </div>
                </Link>

              </Col> */}
            </Row>
          </div>
        </div>
      </div>)
  }
}

export default Page
