import request, { ErrorCode } from '@/utils/request';
import { API } from './default';



type config = {
  sort_by: "created" | "hot"
  sortDirection: "ASC" | "DESC"
  page: number
}
/**搜索API */
export async function getUserInfo(keywords: string, config?: config): Promise<{}> {
  return request(`/api/forum/post/list?keyword=${keywords}`,
    {
      method: 'get',
      data: {
        sort_by: "",
        sort_direction: "",
        page: "",
      }
    });
}
// 请求示例JSON
// sort_by 
// - created 按时间排序
// - hot 按浏览量排序
// sort_direction = ASC/DESC ASC按小到大, DESC按大到小
// page=1  page默认是0开始，1查的是第二页的数据
// keyword=关键字


// ## 搜索比赛-全文搜索

// 请求地址  [GET] /api/search/competiction?keyword=关键字&page=1&size=10
// 请求头 Content-Type: application/json

// 请求参数示例
// size=10 // 每页条数
// page=1  page默认是0开始，1查的是第二页的数据
// keyword=关键字

export type CompSearchResultItem = {
  cid: number,
  uuid: string,
  uf_portal_id: string,
  uf_organization_id: string,
  uf_competition_id: string,
  uf_competition_Image: string,
  uf_competition_ImageHeading: string,
  uf_competition_DisplayLabel: string,
  uf_competition_TaskDisplayLabel: string,
  uf_competition_Name: string,
  uf_competition_Award: string,
  uf_competition_ShortDescription: string,
  uf_competition_StartDate: string,
  uf_competition_EndDate: string,
  uf_competition_CreateDate: string,
  uf_competition_UsersNumber: number,
  uf_competition_WorksNumber: number,
  uf_competition_TeamsNumber: number,
  uf_competition_Status: number,
  uf_competition_Order: number,
  created: string
}
export async function searchCompetiction(keyword: string, page?: number, size?: number): Promise<API<{
  page: number
  count: number
  data: CompSearchResultItem[]
}>> {
  return request(`/api/search/competiction?keyword=${keyword}&page=${page || 0}&size=${size || 10}`,);
}