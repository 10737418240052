import { FlashingLOGO } from '@/component/FlashingLOGO';
import { hasUnreadNotification } from '@/functions/Notification';
import { addNotifyEventListener } from '@/services/notifyEvent';
import Icon2, { SearchOutlined, UserOutlined } from '@ant-design/icons';
import { Badge, Button, Input, Menu as AntdMenu } from 'antd';
import React, { PureComponent } from 'react';
import { history, Link } from 'umi';
import { MENU_CONFIG } from '../../config/menu';
import { Icon } from '../../functions/IconLab';
import { Provider_LOGIN_STATE } from '../beforeRender/loginState';
import '../global.less';
import "./index.less";
import styles from './index.less';


const { SubMenu } = AntdMenu;
// const ThemeContext = React.createContext('light');

const onSearch = value => console.log(value);

type MenuProps = {
  mode: "horizontal" | "inline"
  onWidthChange: (newWidth: number) => void
  expectWidth?: number
};
type MenuState = {
  menuHide: boolean;
  hasUnreadMessage?: boolean;
};
export class Menu extends PureComponent<MenuProps, MenuState> {
  constructor(props: MenuProps) {
    super(props);
    this.state = {
      menuHide: true,
    };

    this.listen()
  }
  static contextType = Provider_LOGIN_STATE;
  leftLogoWidth = 180;

  logo = <div style={{ display: 'inline-block' }}>
    <FlashingLOGO />
  </div>
  width1 = 70
  width2 = 266

  render() {
    const style1: React.CSSProperties = {
      width: `calc(100% - ${this.leftLogoWidth}px)`,
    }
    const style2: React.CSSProperties = {
      width: this.state.menuHide ? this.width1 : this.width2,
      display: 'flex', flexDirection: 'column',
    }
    const InlineLOGOArea = <div className="inlineLOGOArea">
      <div
        style={{ display: 'inline-block', minWidth: 70, textAlign: 'center', cursor: 'pointer' }}
        onClick={() => {
          const nextState = !this.state.menuHide
          if (typeof (this.props.onWidthChange) === "function") {
            this.props.onWidthChange(nextState ? this.width1 : this.width2)
          } else {
            console.error("onWidthChange failed")
          }

          this.setState({ menuHide: nextState });
        }}
      >
        {this.state.menuHide ? <Icon id={"Icon_nav_inside"} /> : <Icon id={"MenuFoldOutlined"} />}
      </div>
      <span
        className={styles.opacityTransition}
        style={{ display: this.state.menuHide ? 'none' : 'unset' }}
      >
        {this.logo}
      </span>
    </div>


    const SearchInput = <div
      className="searchWrap"
      onClick={() => {
        if (this.state.menuHide) {
          history.push("/search")
        }
      }}>
      <Input
        placeholder="搜索:量化联赛、kaggle、交易"
        prefix={<SearchOutlined />}
        onPressEnter={(event) => {
          const target = event.target as HTMLInputElement
          const input = target.value
          console.log("input", input)
          history.push(`/search?wd=${input}`)
        }}
      />
    </div>

    const InlineSearchArea = <div className={`InlineSearchArea`}>
      {SearchInput}
    </div>

    let currentActiveKey = ""

    const location = history.location.pathname

    Array.from(MENU_CONFIG, (item, id) => {
      const itemPath = item.path?.toLocaleLowerCase()
      if (itemPath) {
        if (location.toLocaleLowerCase().startsWith(itemPath)) {
          currentActiveKey = String(id)
        }
      }
    })
    return <Provider_LOGIN_STATE.Consumer>
      {CONTEXT => {
        if (typeof this.state.hasUnreadMessage === "undefined" && CONTEXT.login) { this.updateMessageUnreadState() }
        return <div className={`forum-menu ${this.state.menuHide ? "forum-menu-fold" : ""}`}>
          <div
            onClick={() => {
              this.setState({ menuHide: true });
              if (typeof (this.props.onWidthChange) === "function") {
                this.props.onWidthChange(this.width1)
              }
            }}
            style={{
              position: "fixed",
              left: 0,
              top: 0,
              zIndex: 9,
              width: this.props.expectWidth || 200,
              height: "100%",
              backgroundColor: "white",
              borderRight: "1px solid #f0f0f0",
              overflow: "hidden",
              transitionDuration: "0.3s",
              transitionProperty: "background, width, filter",
              transitionTimingFunction: "ease, cubic-bezier(0.2, 0, 0, 1)",
              filter: this.state.menuHide ? "" : "drop-shadow(-7px 0px 13px #333)",
            }}>

            <div onClick={(event) => {
              event.stopPropagation()
            }}>
              {this.props.mode === "inline" ? InlineLOGOArea : ""}
              {this.props.mode === "inline" ? InlineSearchArea : ""}
            </div>
            <AntdMenu
              className={'layouts/frontend/left/heder/menu'}
              mode={this.props.mode}
              style={this.props.mode === "inline" ? style2 : style1}
              selectedKeys={[currentActiveKey]}
              inlineCollapsed={this.state.menuHide}
            >

              {Array.from(MENU_CONFIG, (item, id) => {
                const push = (href?: string) => {
                  console.debug("pushing", href)
                  href ? history.push(href) : ""
                  this.forceUpdate()
                }
                if (item.routes) {
                  return <SubMenu key={id} icon={<Icon id={item.icon} />} title={'更多'}>
                    {Array.from(item.routes, (childItem, childId) => {
                      return <AntdMenu.Item onClick={() => { push(item.href) }}
                        key={`${id}-_-${childId}`}>
                        <Link to={item.href || "/404"}>
                          {childItem.title}
                        </Link>
                      </AntdMenu.Item>
                    })}
                  </SubMenu>
                }
                // 项目管理 自定义按钮
                if (id === 5) {
                  return <>
                    <li key={id} style={{ display: 'flex', alignItems: "center" }}
                    // onClick={() => { window.open("https://comtask.ubiquant.com/mw-org/ubiop/workspace/project") }}
                    >
                      <Icon style={{ width: 46, height: 46, marginLeft: 12, marginRight: 12, marginTop: 5 }} id={item.icon[0]} />
                      <a className='24trg7uh8'
                        //  href='https://comtask.ubiquant.com/mw-org/ubiop/workspace/project' 
                        style={{ color: 'rgba(0,0,0,0.85)' }} target={'_blank'}>
                        {item.title}
                      </a>
                    </li>
                  </>
                }
                return <><AntdMenu.Item
                  key={id}
                  onClick={() => { push(item.href) }}
                >
                  <Badge dot={item.tag === "user" && this.state.hasUnreadMessage}>
                    {/* <span style={{display:"none"}}>{typeof this.state.hasUnreadMessage}</span> */}
                    {/* <span style={{display:"none"}}>{String(this.state.hasUnreadMessage)}</span> */}
                    <Icon id={item.icon[0]} />
                    <Icon id={item.icon[1]} />
                  </Badge>
                  <span className="menu-item-text">
                    <Link to={item.href || "/404"}>
                      {item.title}
                    </Link>
                  </span>
                </AntdMenu.Item>
                  {id === 4 ? <> <div onClick={(event) => {
                    const nextState = !this.state.menuHide;
                    if (typeof (this.props.onWidthChange) === "function") {
                      this.props.onWidthChange(nextState ? this.width1 : this.width2)
                    } else {
                      console.error("onWidthChange failed")
                    }

                    this.setState({ menuHide: nextState });
                    event.stopPropagation();
                  }} style={{ flex: '1', cursor: 'pointer' }}>
                  </div></> : ''}
                </>

              })}
            </AntdMenu>
          </div>
          <div style={{
            width: "100%",
            height: " 100%",
            position: "fixed",
            zIndex: "1",
            backgroundColor: " rgba(0,0,0,.5)",
            display: this.state.menuHide ? 'none' : 'block',
          }}
            onClick={() => {
              this.setState({ menuHide: true });
              if (typeof (this.props.onWidthChange) === "function") {
                this.props.onWidthChange(this.width1)
              }
            }}></div>
        </div>
      }}
    </Provider_LOGIN_STATE.Consumer>
  }
  async componentDidMount() {
    let syncState = () => {
      const state = this.state.menuHide
      if (typeof (this.props.onWidthChange) === "function") {
        this.props.onWidthChange(state ? this.width1 : this.width2)
      } else {
        console.error("syncState failed")
      }
    }

    syncState()
    this.forceUpdate()
  }
  // async componentWillUnmount() {
  //   (await this.notifyConnectionWorker).port.close()
  // }
  async updateMessageUnreadState() {
    return
    const hasUnreadMessage = await hasUnreadNotification()

    this.setState({
      hasUnreadMessage
    })
    // this.forceUpdate()
  }
  async listen() {
    addNotifyEventListener((event) => {
      switch (event.type) {
        case "unreadChange":
          {
            console.error("setting hasUnreadMessage",event.data)
            this.setState({ hasUnreadMessage: event.data > 0 })
          }
          break;

        default:
          break;
      }
    })
  }
}

export default AntdMenu;
