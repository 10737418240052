import { Icon } from "@/functions/IconLab";
import { TimePassed } from "@/functions/timeDiff";
import { Provider_LOGIN_STATE } from '@/layouts/beforeRender/loginState';
import { addNotifyEventListener, NotifyEvents, removeNotifyEventListener } from "@/services/notifyEvent";
import { DiscussionComment } from '@/types/comment';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Avatar, Button, List, message, Modal, Tag } from 'antd';
import { PureComponent } from 'react';
import { Link } from "umi";
import { deleteComment, getTopicComments } from "../../../../services/forum";
import { Dislike } from "../../ListVotes";
import { CommentForEdit, CommentModalReEdit } from '../reedit';
import { SubCommentRender } from './sub';


type RenderCommentState = {
  list?: DiscussionComment[]
  ModalComment?: Boolean
  editing?: CommentForEdit
}

type RenderCommentProps = {
  tid: number
}
export class RenderComment extends PureComponent<RenderCommentProps, RenderCommentState> {
  listenerId: string;
  constructor(props: RenderCommentProps) {
    super(props);
    this.listenerId = "0"
    this.state = {};
  }
  render() {
    return <div>
      <List
        className="discussions_post_index_renderComment"
        loading={!(this.state.list)}
        itemLayout="vertical"
        dataSource={(this.state.list)}
        renderItem={(item, itemId) => (
          <Provider_LOGIN_STATE.Consumer>
            {CONTEXT => {
              const content = `${item.content}`

              const TimeDisplays = <>{TimePassed(new Date(item.created || 0), { sec2JustNow: true, afterfix: "前" }) || "刚刚"}</>
              const Reply = <Button type="text" icon={<Icon id="MessageFilled" />} onClick={() => this.action(item, "reply")}>{"回复"}</Button>
              const Delete = <Button type="text" icon={<Icon id="DeleteFilled" />} onClick={() => this.action(item, "delete")}>{"删除"}</Button>
              const Edit = <Button type="text" icon={<Icon id="EditFilled" />} onClick={() => this.action(item, "edit")}>{"编辑"}</Button>

              return <List.Item id={item.id}>
                <List.Item.Meta
                  style={{ display: "flex", alignItems: "flex-start", }}
                  avatar={
                    <div style={{ position: "relative", textAlign: 'center' }}>
                      {CONTEXT.uid != item.author.id ? <Link to={{ pathname: `/user/${item?.author?.id}`, query: { "uid": item?.author?.id } }}><Avatar
                        src={item?.author?.picture}
                        size={50}
                      /></Link> : <Link to={{ pathname: `/user` }} ><Avatar src={item?.author?.picture} size={50} /></Link>
                      }
                      <br></br>
                      <Tag style={{ margin: 5 }}><span>{item.author.name}</span></Tag>
                      <br></br>
                      <Tag style={{ margin: 5 }}><span>{item.author.tiers}</span></Tag>
                    </div>
                  }
                  title={<div style={{ marginRight: 10, position: "relative", width: "100%" }}>
                    {TimeDisplays}
                    {CONTEXT.login ? Reply : undefined}
                    {CONTEXT.login && CONTEXT.uid === item.author.id ? Delete : undefined}
                    {CONTEXT.login && CONTEXT.uid === item.author.id ? Edit : undefined}
                    <Dislike style={{ position: "absolute", right: 10 }} targetId={item.id} targetType={"comment"} defaultState={item.is_like} votes={item.like_count} />
                  </div>}
                  description={<>
                    <div className="CommentRender_item_content"
                      style={{
                        display: 'flex',
                        flexDirection: "column",
                        color: '#666',
                        wordBreak: "break-all",
                        fontSize: 16
                      }}
                      dangerouslySetInnerHTML={{ __html: content }} />
                    <SubCommentRender
                      parentCommentId={item.id}
                      tid={this.props.tid}
                      data={item.sub_comments}
                      onChange={this.updateList.bind(this)} />
                  </>}
                />
              </List.Item>

            }
            }
          </Provider_LOGIN_STATE.Consumer>
        )}
      />
      <CommentModalReEdit
        editing={this.state.editing}
        onCancel={this.clearEditing.bind(this)}
        onDone={this.onEditDone.bind(this)}
      />
    </div>
  }
  async componentDidMount() {
    await this.fetchData()
    this.listenNotify()



    this.props.onref && this.props.onref(this)
  }

  componentWillUnmount(): void {
    removeNotifyEventListener(this.listenerId)
  }

  async fetchData() {
    const result = await getTopicComments(this.props.tid)
    this.setState({
      list: result.data || []
    })

  }

  private action(comment: DiscussionComment, action: "reply" | "delete" | "edit") {
    console.log("comment", comment)
    switch (action) {
      case "delete":
        Modal.confirm({
          getContainer: '#root',
          icon: <ExclamationCircleOutlined />,
          content: '是否确认删除当前评论？',
          okText: '确认',
          cancelText: '取消',
          onOk: async () => {
            await deleteComment(this.props.tid, comment.id)
            this.updateList()
          }
        });
        break;
      case "edit":
        this.setState({
          editing: {
            behavior: "edit",
            content: comment.content,
            commentId: comment.id,
            postId: this.props.tid,
          }
        })
        break;
      case "reply":
        this.setState({
          editing: {
            behavior: "new",
            content: "",
            commentId: comment.id,
            postId: this.props.tid,
          }
        })
        break;
      default:
        break;
    }
  }
  async updateList() {
    const data = await getTopicComments(this.props.tid)
    this.setState({
      list: data.data || [],
      ModalComment: false
    })
  }
  onLoadMore() {
    if ((this.state.list % 10) > 0) {
      message.warning('暂无更多数据！');
      return
    }
    const params = {
      page: Math.ceil(this.state.list?.length / 10),
      size: 10
    }
    getTopicComments(this.props.tid, params).then(data => {
      const list = this.state.list.concat(data.data)
      this.setState({
        list: list || [],
        ModalComment: false
      })
    })
  }
  clearEditing() {
    this.setState({ editing: undefined })
  }
  onEditDone() {
    this.setState({ editing: undefined })
    this.updateList()
  }
  async listenNotify() {
    this.listenerId = await addNotifyEventListener(this.notifyEventHandle.bind(this))
  }

  notifyEventHandle(event: NotifyEvents) {
    switch (event.type) {
      case "unreadChange":
        this.fetchData()
        break;
        1
      default:
        break;
    }
  }
}
