import { PureComponent } from 'react';
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { Provider_LOGIN_STATE } from '../../../layouts/beforeRender/loginState';
import "./index.less";

import { IsLike, LikeState, pointLike } from "../../../services/forum";

type Props = {
  style?: React.CSSProperties
  targetId: number
  targetType: "comment" | "post"
  defaultState: LikeState | IsLike
  votes: number
}
type State = {
  state?: LikeState
  flatVotes: number
  voteOffset: number
}

export class Dislike extends PureComponent<Props, State> {
  delay = 200
  constructor(props: Props) {
    super(props);
    let state: LikeState
    let flatVotes: number
    let voteOffset: number
    switch (props.defaultState) {
      case -1:
      case "dislike":
        state = "dislike"
        flatVotes = props.votes + 1
        voteOffset = -1
        break;
      case 0:
      case "unset":
        state = "unset"
        flatVotes = props.votes
        voteOffset = 0
        break;
      case 1:
      case "like":
        state = "like"
        flatVotes = props.votes - 1
        voteOffset = 1
        break;
    }

    this.state = {
      state,
      flatVotes,
      voteOffset
    };
  }

  render() {
    return <Provider_LOGIN_STATE.Consumer>
      {CONTEXT => {

        if(this.props.targetId ===171){
          console.log("Dislike",this.props)
        }
        return CONTEXT.login ? <div className={"Dislike"} style={{ ...this.props.style }}>
          <Button
            loading={typeof this.state.state === "undefined"}
            onClick={this.onLikeClick.bind(this)}
            type={this.state.state === "like" ? "primary" : undefined}
            shape="round"
            className={"likesBtn"}
            icon={<CaretUpOutlined />} />
          <div className={"contentBtn"}>
            {this.state.flatVotes + this.state.voteOffset}
          </div>
          <Button
            loading={typeof this.state.state === "undefined"}
            onClick={this.onDisLikeClick.bind(this)}
            type={this.state.state === "dislike" ? "primary" : undefined}
            shape="round"
            className={"debunkBtn"}
            icon={<CaretDownOutlined />} />
        </div> : ""
      }}
    </Provider_LOGIN_STATE.Consumer >
  }
  onLikeClick(event: React.MouseEvent<HTMLElement, MouseEvent>) {
    event.stopPropagation()
    if (this.state.state === "like") {
      this.sendAction("unset")
    } else {
      this.sendAction("like")
    }
  }
  onDisLikeClick(event: React.MouseEvent<HTMLElement, MouseEvent>) {
    event.stopPropagation()
    if (this.state.state === "dislike") {
      this.sendAction("unset")
    } else {
      this.sendAction("dislike")
    }
  }

  async sendAction(setTo: LikeState) {
    this.setState({ state: undefined })
    console.log("sendAction", "this.state", this.state, this.props, setTo)

    await pointLike({
      type: this.props.targetType,
      id: this.props.targetId,
      setTo
    })

    switch (setTo) {
      case "dislike":
        this.setState({ voteOffset: -1 })
        break;
      case "like":
        this.setState({ voteOffset: 1 })
        break;
      case "unset":
        this.setState({ voteOffset: 0 })
        break;
    }
    setTimeout(() => {
      this.setState({
        state: setTo
      })
    }, this.delay);
  }
}




//////////////////////////////////////////
type ListVotesProps = {
  votes: number
}
type ListVotesState = {
  votes?: number
  upvoted?: boolean
  downvoted?: boolean
}
/**
 * 
 * @deprecated 
 */
class ListVotes extends PureComponent<ListVotesProps, ListVotesState> {
  constructor(props: Props) {
    super(props);
    console.log(props?.info.is_like, props?.info.is_like == -1, props?.info.id)
    this.state = {
      downvoted: props?.info.is_like == -1,
      upvoted: props?.info.is_like == 1,
      votes: props.votes
    };
  }
  toggleDownvote(event) {
    var info = this.props?.info;
    var votes = this.state?.votes, state = "0";
    if (this.state.upvoted) {
      // return
      this.setState({
        upvoted: !this.state.upvoted
      })
      votes = Number(votes) - 1;
    }
    if (!this.state.downvoted) {
      state = "-1"
      votes = Number(votes) - 1;
    } else {
      votes = Number(votes) + 1
    }
    this.setState({
      downvoted: !this.state.downvoted,
      votes: String(votes)
    })
    pointLike({
      "type": this.props?.type,
      "id": info.id,
      "state": state
    }).then((res) => {
      console.log(res, "res")
    })
  }
  toggleUpvote(event) {
    console.log(event)
    var info = this.props?.info;
    var votes = this.state?.votes, state = "0";
    if (this.state.downvoted) {
      this.setState({
        downvoted: !this.state.downvoted
      })
      votes = Number(votes) + 1;
    }
    if (!this.state.upvoted) {
      votes = Number(votes) + 1;
      state = "1"
    } else {
      votes = Number(votes) - 1
    }
    this.setState({
      upvoted: !this.state.upvoted,
      votes: String(votes)
    })
    pointLike({
      // "type": info.latest_action.type.replace("_create", ""),
      "type": this.props?.type,
      "id": info.id,
      "state": state
    }).then((res) => {
      console.log(res, "res")
    })
  }
  componentDidMount() {
    // console.log(this.props.info)
    // console.log(this.state)
  }
  render() {
    return <Provider_LOGIN_STATE.Consumer>
      {CONTEXT => {
        return CONTEXT.login ? <div className={"discussions_ListVotes_main"} style={{ padding: 5 }}>
          <Button onClick={() => this.toggleUpvote(event)} type={this.state.upvoted ? "primary" : ''} shape="round" className={"likesBtn"} icon={<CaretUpOutlined />} ></Button>
          <div className={"contentBtn"}>{this.state.votes}</div>
          <Button onClick={() => this.toggleDownvote(event)} type={this.state.downvoted ? "primary" : ''} shape="round" className={"debunkBtn"} icon={<CaretDownOutlined />} ></Button>
        </div> : ""
      }}
    </Provider_LOGIN_STATE.Consumer >
  }
}


export { ListVotes };

