import { PureComponent } from 'react';
import { Selector } from '.';

type SortingSelectorProps<T extends SortingSelectionValue = SortingSelectionValue> = {
    onChange: (selected: T) => void
}

export class SortingSelector extends PureComponent<SortingSelectorProps>  {
    constructor(props: SortingSelectorProps) {
        super(props);
    }
    render() {
        const defaultValue: SortingSelectionValue = "created"
        return <Selector<SortingSelectionValue>
            defaultValue={defaultValue}
            selection={sortingSelections}
            onChange={this.props.onChange}
        />
    }
}

export type SortingSelectionValue = "created" | "last_comment" | "comment_count" | "like_count"
const sortingSelections: {
    displays: string,
    value: SortingSelectionValue
}[] = [{
    displays: "最新创建",
    value: "created"
}, {
    displays: "最后评论",
    value: "last_comment"
}, {
    displays: "最多评论",
    value: "comment_count"
}, {
    displays: "最多点赞",
    value: "like_count"
}]


