import { PureComponent } from 'react';
import comListBanner from "../../public/assets/images/dottedLine.gif"
import './lineDecoration.less';

export class LineDecoration extends PureComponent<{}, {}> {
    render() {
        return <div className="com-header-innner">
            {/* <div className="diagram-title">
                If you torture the data long enough, it will confess to anything.
            </div> */}
            <div className="diagram-img" style={{
                backgroundImage: `url(${comListBanner})`,
                backgroundPosition: 'center',
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat'
            }}>
            </div>
        </div>
    }
}
