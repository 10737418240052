import { CloseOutlined, ExclamationCircleOutlined, SearchOutlined } from '@ant-design/icons';
import { Avatar, Button, Drawer, Menu } from 'antd';
import { PureComponent } from 'react';
// import * as 九坤logo from '../../../public/九坤logo-标准版.png';
import { Logout } from '../../functions/logout';
import { Menu as SelfMenu } from '../../layouts/menu';
import ForumFooter from '../../layouts/footer';
import '../global.less';
import styles from './index.less';
import './index.less';
import { Provider_LOGIN_STATE } from '../beforeRender/loginState';
import { Affix } from 'antd';
import { Layout } from 'antd';
import * as 九坤logo from '../../../public/九坤logo-标准版.png';
import { FlashingLOGO } from '../../component/FlashingLOGO';
import { getOverflowStyles } from '@/functions/CompatibilityFix';

const { Header, Footer, Sider, Content } = Layout;


type GeneralLayoutState = {
  asideWidth?: number;
  affixed?: boolean
  headerLogoShow?: boolean
};
export class GeneralLayout extends PureComponent<{}, GeneralLayoutState> {
  constructor(props: {}) {
    super(props);
    this.state = {
    };
  }
  logo = <div style={{ display: 'inline-block' }}>
    <FlashingLOGO />
  </div>
  MenuMinWidth = 70

  onTopBarStateChange(affixed?: boolean) {
    console.log("affixed", affixed)
    this.setState({
      affixed: !!affixed
    })
  }
  render() {
    return (
      <div className={styles.body}>
        <aside style={{ width: this.MenuMinWidth }}>
          <SelfMenu
            expectWidth={this.state.asideWidth}
            mode="inline"
            onWidthChange={(newWidth) => {
              this.setState({
                asideWidth: newWidth,
                headerLogoShow: newWidth < 150
              })
            }} />
        </aside>
        <Layout

          className="forum-container"
          style={{ width: `calc(100% - ${this.MenuMinWidth}px)` }}>
          <Header
            style={{
              width: "100%",
              padding: 0,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              paddingRight: 24,
              backgroundColor: "white",
              height: 64
            }}
          >

            <div style={{ height: "100%" }}>
              <div style={{
                display: this.state.headerLogoShow ? "block" : "none",
                paddingLeft: 24
              }}>
                {this.logo}
              </div>
            </div>
            <div>
              <span style={{ display: "none" }}>
                <UserAvatarAndSideBar />
              </span>
            </div>


          </Header>
          <Content className="forum-content" style={{ overflow: getOverflowStyles("overlay") }}>
            <article style={{ backgroundColor: "white", paddingRight: "8vw"}}>
              {this.props.children}
            </article>
            <Footer className="forum-footer-outer">
              <ForumFooter />
            </Footer>
          </Content>

        </Layout>
      </div>
    );
  }
}

type UserSideBarState = {
  sideBarDisplay: boolean;
};
type UserSideBarProps = {};
export class UserAvatarAndSideBar extends PureComponent<UserSideBarProps, UserSideBarState> {
  constructor(props: UserSideBarProps) {
    super(props);
    this.state = {
      sideBarDisplay: false,
    };
  }

  render() {

    return <Provider_LOGIN_STATE.Consumer>
      {CONTEXT => {
        const setOpen = () => {
          this.setState({ sideBarDisplay: true });
        };
        const setClose = () => {
          this.setState({ sideBarDisplay: false });
        };

        const avatar = (
          <Avatar
            style={{ margin: 10, cursor: 'pointer' }}
            src={CONTEXT.avatar.href}
          />
        );

        const DrawerTitle = (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <div>
              {avatar}
              {CONTEXT.userName || CONTEXT.phone || "未登陆"}
            </div>
            <div>
              <Button type="text" onClick={setClose}>
                <CloseOutlined />
              </Button>
            </div>
          </div>
        );
        return (
          <>
            {/* {value.avatar.href} */}
            <div onClick={setOpen} style={{
              display: "inline-block"
            }}>{avatar}</div>
            <Drawer
              title={DrawerTitle}
              placement={'right'}
              closable={false}
              onClose={setClose}
              visible={this.state.sideBarDisplay}
              key={'placement'}
            >
              <Button type="text" style={{ width: '100%', textAlign: 'left' }}>
                <ExclamationCircleOutlined />
                {'编辑资料！'}
              </Button>

              <Button type="text" style={{ width: '100%', textAlign: 'left' }} onClick={Logout}>
                <ExclamationCircleOutlined />
                {'退出登录'}
              </Button>
            </Drawer>
          </>
        );

      }}


    </Provider_LOGIN_STATE.Consumer>

  }
}

export default GeneralLayout;
