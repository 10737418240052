import { Provider_LOGIN_STATE } from '@/layouts/beforeRender/loginState';
import { PureComponent } from 'react';
import "./index.less";
import { UserProfileRender } from './Render';


class Page extends PureComponent<{}, {}> {
  uid: number;
  constructor(props: {}) {
    super(props);
    this.uid = Number(document.location.pathname.split("/")[2])
  }
  render() {
    return <Provider_LOGIN_STATE.Consumer>
      {CONTEXT => {
        if (this.uid === CONTEXT.uid) {
          return <UserProfileRender type={"usercenter"} />
        }
        return <UserProfileRender type={"display"} uid={this.uid} />
      }}
    </Provider_LOGIN_STATE.Consumer>
  }
}

export default Page
