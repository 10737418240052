import { PureComponent } from 'react';
import { Link } from 'umi';

import { Typography, List, Skeleton, Avatar } from 'antd';
import {
  BorderlessTableOutlined,
  SoundOutlined,
} from '@ant-design/icons';
import { getRandomData } from "../../functions/randomData"
import { User } from "../../functions/UserCentral/type"
import { TimePassed } from '@/functions/timeDiff';


const { Title } = Typography;

export type TypeBlockItemList = {
  key: string
  title: string
  icon: URL
  description: string
  lastPost?: {
    time: Date
    user: User
  }
  topics?: {
    user: User
    ago: string
  }[]
}
type Props = {
  list?: TypeBlockItemList[]
}
/**论坛版块列表渲染模块 */
class BlockItem extends PureComponent<Props, {}> {
  constructor(props: Props) {
    super(props);
    this.state = {
    };
  }

  render() {
    return <List
      className="dis-section-container"
      loading={!(this.props.list)}
      itemLayout="horizontal"
      dataSource={(this.props.list)}
      renderItem={item => (
        <List.Item>
          <Skeleton avatar title={false}
            loading={!(this.props.list)}
            active>
              <Link style={{ }} to={{pathname:`/discussions/zone/${item.key}`}} >
                <List.Item.Meta
                  style={{ display: "flex", alignItems: "center" }}
                  avatar={<Avatar src={item.icon?.href} size={42} shape="square" />}
                  title={item.name}
                  description={<>
                    {item.description}
                  </>}
                />
              </Link>
            {item.topics ? (
              item.topics.length > 0 && (<div>
                <div key='topics-userIcon' style={{ textAlign: "right", marginBottom: "5px" }}>
                  {Array.from(item.topics || [], (topic, topicArrId) => {
                    return <Avatar size={36} src={topic.user.avatar} key={topicArrId}
                      style={{
                        backgroundColor: topic.user.themeColor,
                        border: "2px white solid",
                        position: "relative",
                        left: 12 * ((item.topics?.length || 0) - (topicArrId + 1))
                      }} />
                  })}
                </div>
                {(() => {
                  const topic = item.topics.shift()
                  return <div key='topics-user'
                   style={{textAlign: "right"}}
                   >
                    {"新主题由 "}<span className="topic-username">{topic?.user?.name}</span>
                    {"发布于 "}<span className="topic-timesago">
                      {TimePassed(new Date(topic?.ago || 0), {
                        sec2JustNow: true,
                        afterfix:"前"
                      }) || "刚刚"}
                    </span>

                  </div>
                })()}
              </div>)
            ) : ""}
          </Skeleton>
        </List.Item>
      )}
    />
  }
}

export { BlockItem }
