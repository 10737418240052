import { GENERAL_ALLOWED_IMAGE_TYPES } from '@/config/GeneralConfig';
import { AntdUploadCustomRequestEvent, AntdUploadImage } from '@/functions/upload/image';
import { API_uploadImage } from "@/services/files";
import { updateUserInfo } from '@/services/user';
import { LoadingOutlined } from '@ant-design/icons';
import { ConfigProvider, message, Modal, Popover, Upload } from 'antd';
import ImgCrop from 'antd-img-crop';
import zhCN from 'antd/lib/locale/zh_CN';
import { PureComponent } from 'react';

type UserAvatarProps = {
    defaultImage?: string
    editable?: boolean
}
type UserAvatarState = {
    loading?: boolean
    imageUrl?: string
}
export class UserAvatar extends PureComponent<UserAvatarProps, UserAvatarState> {

    constructor(props: UserAvatarProps) {
        super(props);
        this.state = {

        };

    }
    render() {
        return <div className='UserAvatar' style={{ height: 200, width: 200, overflow: "hidden", background: "#F2F2F2", position: "relative" }}>
            <div className='UserAvatar-picture' style={{
                position: "absolute", height: "100%", width: "100%",
                backgroundImage: `url(${this.state.imageUrl || this.props.defaultImage})`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                backgroundSize: "cover",
                opacity: this.state.loading ? 0 : 1
            }} />
            <div className='UserAvatar-cover' style={{
                position: "absolute", height: "100%", width: "100%",
                display: this.props.editable ? undefined : "none"
            }} >
                <ConfigProvider locale={zhCN}>
                    <ImgCrop rotate onModalOk={(event) => {
                        console.log("event", event)
                    }}>
                        <Upload
                            accept={GENERAL_ALLOWED_IMAGE_TYPES.join(",")}
                            showUploadList={false}
                            customRequest={this.onAvatarUpdate.bind(this)}
                        >
                            <Popover content={"点击可更换头像"} trigger="hover">
                                <div className='UserAvatar-cover' style={{
                                    position: "absolute", height: "100%", width: "100%", top: 0, cursor: "pointer"
                                }} />
                            </Popover>
                        </Upload>
                    </ImgCrop>
                </ConfigProvider>


            </div>
            <div className='UserAvatar-loading' style={{
                position: "absolute", height: "100%", width: "100%",
                display: this.state.loading ? "flex" : "none", alignItems: "center", justifyContent: "center"
            }} >
                <LoadingOutlined style={{ fontSize: 64 }} />
            </div>
        </div>
    }

    async onAvatarUpdate(event: AntdUploadCustomRequestEvent) {
        const result = await AntdUploadImage(event)
        this.setState({ loading: true })
        const fileId = result?.fid

        const imageUrl = result?.url.href
        if (!fileId) {
            message.error("图片上传失败")
            return
        }
        this.setState({ imageUrl })
        await updateUserInfo({
            user_picture: fileId
        })
        setTimeout(() => {
            this.setState({ loading: false })
        }, 1000);
    }
}

