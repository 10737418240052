import { ReactWarn2Warn } from "fuck-react-warning"
ReactWarn2Warn(true)




/**上传图片时的文件格式限制
 * 
 * 类型名参见：https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/file#accept
 * 
 * 但是格式使用数组
 */
export const GENERAL_ALLOWED_IMAGE_TYPES = ["image/jpeg", "image/png", "image/gif"]

/** 上传图片的大小限制
 * 
 * 单位：字节
 */
export const GENERAL_UPLOAD_IMAGE_FILE_SIZE_LIMIT = 2_000_000
