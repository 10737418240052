import React, { PureComponent, useState } from 'react';
import { Tooltip , Row, Col, Modal, Button, Tabs, Progress } from 'antd';

import { Typography, List, Skeleton, Avatar, Dropdown, message } from 'antd';
import { LineDecoration } from "../../component/lineDecoration"
import { Provider_LOGIN_STATE } from '@/layouts/beforeRender/loginState';
import { DoubleRightOutlined} from '@ant-design/icons';
import "./index.less"
import { Link } from 'umi';

import GoldIcon from "../../../public/assets/Icons/goldl@1x.png"
import SilverIcon from "../../../public/assets/Icons/silverl@1x.png"
import BronzeIcon from "../../../public/assets/Icons/bronzel@1x.png"
import { Icon } from '@/functions/IconLab';
import moment from "moment";
import { getCompetictionList, getForumpPointsList, getListCount } from '@/services/rank'

const { TabPane } = Tabs;
const { Title, Text } = Typography;
type Props = {
}
type State = {
  // bgcstyle?: string
  voted?: boolean
  rankingArray?: {
    backerGroundColor?: number
    id: number,
    teamName: string,
    members: {
      id: string
      name: string
      url: string
    },
    score: number,
    enties: number,
    last: number
  }[],
  countList?: {
    id: string,
    name: string,
    count: string
  }[],
  forumpPoints?: [],

}


const operations =<Link style={{marginRight: 38, color: '#176EDE'}} to="/regular">排名规则</Link>;

class Page extends PureComponent<{}, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      rankingArray: [],
      forumpPoints: [],
      countList: [
        {id: "1", name: '星际漫游者', count: "0"},
        {id: "2", name: '星际宇航员', count: "0"},
        {id: "3", name: '星际任务专家', count: "0"},
        {id: "4", name: '星际飞行员', count: "0"},
        {id: "5", name: '星际指挥官', count: "0"},
      ],
      initLoading: true,
      loading_f: true,
      loading_c: true,
      competionTotal: '',
      competion_currentPage: '',
      forumpTotal: '',
      forump_currentPage: '',

    };
  }
  headerGap3 = <Col span={1} style={{ display: "flex", alignItems: "center" }}><DoubleRightOutlined style={{ color: "#CCCCCC" }} /></Col>
  headerLevel(name: string, number: string, color: string, icon: React.ReactElement) {
    return <Col span={4} style={{ display: "flex", alignItems: "center" }}>
      <div>
        {React.cloneElement(icon, { style: { fontSize: 28, margin: 5, color } })}
      </div>
      <div>
        <div style={{ fontWeight: 700, fontSize: 15, color }}>{number}</div>
        <div style={{ fontWeight: 400, fontSize: 13, color }}>{name}</div>
      </div>
    </Col>
  }
  headerLevel2(color: string, icon: React.ReactElement) {
    return <Col span={4} style={{ display: "flex", alignItems: "center" }}>
      <div>
        {React.cloneElement(icon, { style: { fontSize: 28, margin: 5, color ,height:20 } })}
      </div>
    </Col>
  }
  MedalMarker(props: { type: "g" | "s" | "b", value: number }) {
    return <div style={{ width: "30%" }}>
      <img src={(() => {
        switch (props.type) {
          case "g":
            return GoldIcon
          case "s":
            return SilverIcon
          case "b":
            return BronzeIcon
        }
      })()} style={{ width: 18, marginRight: 5 }} />
      <span style={{
        color: (() => {
          switch (props.type) {
            case "g":
              return "#b88121"
            case "s":
              return "#838280"
            case "b":
              return "#8e5b3d"
          }
        })()
      }}>
        {props.value}
      </span>
    </div>
  }
  dateDiff (hisTime: string, nowTime: string){
    if(!arguments.length) return '';
    return moment(hisTime, 'YYYY-MM-DD', true).fromNow(true)
  }
  componentDidMount() {
    getCompetictionList({page: '0', 'size': 10}).then((response)=> {
      try {
        this.setState({
          rankingArray: response.data.data,
          loading_c: false,
          competionTotal: response.data.totalNum,
          competion_currentPage: response.data.page,
        })
        // message.info("获取数据成功")
      } catch (error) {
        message.error("获取数据失败")
        console.log(error);
      }
    })
    getForumpPointsList({page: '0', 'size': 10}).then((response)=> {
      try {
        this.setState({
          forumpPoints: response.data.data,
          forumpTotal: response.data.totalNum,
          forump_currentPage: response.data.page,
          loading_f: false
        })
        // message.info("获取数据成功")
      } catch (error) {
        message.error("获取数据失败")
        console.log(error);
      }
    })

    getListCount().then((response)=> {
      try {
        this.setState({
          countList: response.data
        })
        // message.info("获取数据成功")
      } catch (error) {
        message.error("获取数据失败")
        console.log(error);
      }
    })
  }

  onLoadMore_Competiction = () => {
    if((this.state.competionTotal / 10) - 1 <= this.state.competion_currentPage){
      message.warning('暂无更多数据');
      return
    }
    this.setState({
      loading_c: true
    });
    console.log((this.state.competion_currentPage - (-1)), 'this.state.competion_currentPage');

    new Promise((resolve, reject) => {
      getCompetictionList({size: '10', page: String(this.state.competion_currentPage - (-1)) })
        .then(res => {
          try {
            const data = this.state.rankingArray.concat(res.data.data);
            this.setState({
              rankingArray: data ,
              competionTotal: res.data.totalNum,
              competion_currentPage: res.data.page,
              loading_c: false,
            })
            // message.info("获取数据成功")
          } catch (error) {
            message.error("获取数据失败")
            console.log(error);
          }
      })
      setTimeout(resolve, 3000);
    }).catch(() => console.log('Oops errors!'));
  }

  onLoadMore_Forump = () =>{
    if((this.state.forumpTotal / 10) - 1 <= this.state.forump_currentPage){
      message.warning('暂无更多数据！');
      return
    }
    this.setState({
      loading_f: true
    });

    getForumpPointsList({size: '10', page: String(this.state.forump_currentPage - (-1)) })
      .then(res => {
        try {
          const data = this.state.forumpPoints.concat(res.data.data);
          this.setState({
            forumpPoints: data,
            forumpTotal: res.data.totalNum,
            forump_currentPage: res.data.page,
            loading_f: false,
          })
          // message.info("获取数据成功")
        } catch (error) {
          message.error("获取数据失败")
          console.log(error);
        }
    })
  }

  render() {
    const { initLoading, loading_c, loading_f, rankingArray, competionTotal, competion_currentPage, forumpPoints, forumpTotal, forump_currentPage} = this.state;
    const onLoadMore_Forump = Math.floor(forumpTotal/10) >  forump_currentPage ? (
      <div
        style={{
          textAlign: 'center',
          marginTop: 60,
          height: 32,
          lineHeight: '32px',
          cursor: 'pointer',
        }}
      >
        <span onClick={this.onLoadMore_Forump}>加载更多</span>
      </div>
    ) : null;
    const onLoadMore_Competiction =  Math.floor(competionTotal/10) >  competion_currentPage ? (
      <div
        style={{
          textAlign: 'center',
          marginTop: 60,
          height: 32,
          lineHeight: '32px',
          cursor: 'pointer',
        }}
      >
        <span onClick={this.onLoadMore_Competiction}>加载更多</span>
      </div>
    ) : null;

    return (<Provider_LOGIN_STATE.Consumer>
      {CONTEXT => {
        return <div className="rank-content-container" >
        <div className="com-list-container">
          <div className="com-header">
            <LineDecoration />

            <Tabs
              defaultActiveKey="3"
              style={{ backgroundColor: "#F8F8F8" }}
              tabBarExtraContent={operations}
            >
              <TabPane tab="赛事排行" key="3" style={{ background: "white" }}>
                <div className='rank-content-3-header-out'>
                  <Row className="rank-content-3-header">
                    {Array.from(this.state.countList || [], (item, num) => {
                      let colorArr = ["#79A4B6","#1D96D4","#4B90B3","#003C82","#003C82"];
                      return (<>{this.headerLevel(item.name, item.count, colorArr[num], <Icon id={`Icon_V${item.id}`} />)}
                            {num < this.state.countList?.length -1 ? this.headerGap3 : ''}</> 
                      )
                    })}
                  </Row>

                </div>
                <div style={{padding: 38}}>
                  <Row className="rank-content-3-table-header">
                    <Col span={2} >{"排名"}</Col>
                    <Col span={2}>{"评级"}</Col>
                    <Col span={5}>{"用户"}</Col>
                    <Col span={9}>  </Col>
                    <Col span={3}>{"奖牌统计"}</Col>
                    <Col span={3} style={{ justifyContent: "flex-end" }}>{"积分统计"}</Col>
                  </Row>
                  <List
                            className="com-list-content"
                            loading={loading_c}
                            itemLayout="horizontal"
                            loadMore={onLoadMore_Competiction}
                            dataSource={rankingArray}
                            renderItem={(item, id) => {
                                  let color = ["rgba(245, 254, 250)", "rgba(17,213,131)"]
                                  if (item.backerGroundColor === 2) {
                                    color = ["rgba(249, 247, 243 )", "rgba(190,144,63)"]
                                  } else if (item.backerGroundColor === 3) {
                                    color = ["rgba(250, 250, 250 )", "rgba(145,145,143)"]
                                  }
                                  // return <Row className="rankingTableList" style={{ backgroundColor: color[0], borderLeft: `5px solid ${color[1]}` }}>
                                  return <Row className="rankingTableList" >
                                    <Col span={2} style={{ paddingLeft:10 }}>
                                    {item.rank}
                                    </Col>
                                    <Col span={2}>
                                      {/* <DribbbleOutlined style={{ fontSize: 28 }} /> */}
                                      <Row className='star-Command-Icon' >
                                        {this.headerLevel2("#003C82", <Icon style={{ fontSize: 10 }} id={`Icon_V${item.tiers_image_key}`} />)}
                                      </Row>
                                    </Col>
                                    <Col span={5} style={{ textAlign: "center" }}>
                                      {CONTEXT.uid != item?.uid ? <Link style={{width: '100%'}} to={{pathname: `/user/${item?.uid }`,  query: { "uid": item?.uid}}}>
                                      <Avatar src={`${item.picture}`} shape={"square"} />
                                      <span style={{ paddingLeft: 10,textAlign: 'left', display: 'inline-block', width: 'calc(100% - 40px)',color:"#333", whiteSpace:'nowrap'}}>{item.name}</span>
                                      </Link>: <Link to={{pathname:`/user`}}>
                                      <Avatar src={`${item.picture}`} shape={"square"} />
                                      <span style={{ paddingLeft: 10,textAlign: 'left', display: 'inline-block', width: 'calc(100% - 40px)',color:"#333", whiteSpace:'nowrap'}}>{item.name}</span>
                                      </Link>}
                                      
                                    </Col>
                                    <Col span={9} style={{ justifyContent: "flex-end", paddingRight: 18, }}>
                                      <span style={{ color: "#808695" }}>
                                        {`joined ${(this.dateDiff(new Date(item.join_time)))}`}
                                      </span>
                                    </Col>
                                    <Col span={5}>
                                      <this.MedalMarker type="g" value={item.awards?.gold || 0} />
                                      <this.MedalMarker type="s" value={item.awards?.silver || 0} />
                                      <this.MedalMarker type="b" value={item.awards?.bronze || 0} />
              
                                    </Col>
                                    <Col span={1} style={{ justifyContent: "flex-end" }}>
                                      <span style={{ color: "#808695" }}>
                                        {item.points}
                                      </span>
                                    </Col>
                                  </Row>
                            }}
                          />
                </div>
              </TabPane>
              <TabPane tab="论坛活跃排行" key="4"  style={{ background: "white" }}>
              <div className='rank-content-3-header-out'>
                  <Row className="rank-content-3-header">
                    {Array.from(this.state.countList || [], (item, num) => {
                      let colorArr = ["#79A4B6","#1D96D4","#4B90B3","#003C82","#003C82"];
                      return (<>{this.headerLevel(item.name, item.count, colorArr[num], <Icon id={`Icon_V${item.id}`} />)}
                            {num < this.state.countList?.length -1 ? this.headerGap3 : ''}</> 
                      )
                    })}
                  </Row>

                </div>
                <div style={{padding: 38}}>
                  <Row className="rank-content-3-table-header">
                    <Col span={2} >{"排名"}</Col>
                    <Col span={2}>{"评级"}</Col>
                    <Col span={5}>{"用户"}</Col>
                    <Col span={9}>  </Col>
                    <Col span={3}>{"奖牌统计"}</Col>
                    <Col span={3} style={{ justifyContent: "flex-end" }}>{"积分统计"}</Col>
                  </Row>


                  <List
                            className="com-list-content"
                            loading={loading_f}
                            itemLayout="horizontal"
                            loadMore={onLoadMore_Forump}
                            dataSource={forumpPoints}
                            renderItem={(item, id) => {
                              let color = ["rgba(245, 254, 250)", "rgba(17,213,131)"]
                              if (item.backerGroundColor === 2) {
                                color = ["rgba(249, 247, 243 )", "rgba(190,144,63)"]
                              } else if (item.backerGroundColor === 3) {
                                color = ["rgba(250, 250, 250 )", "rgba(145,145,143)"]
                              }
                              // return <Row className="rankingTableList" style={{ backgroundColor: color[0], borderLeft: `5px solid ${color[1]}` }}>
                              return <Row className="rankingTableList" >
                                <Col span={2} style={{ paddingLeft:10 }}>
                                  {item.rank}
                                </Col>
                                <Col span={2}>
                                  {/* <DribbbleOutlined style={{ fontSize: 28 }} /> */}
                                  <Row className='star-Command-Icon' >
                                    {this.headerLevel2("#003C82", <Icon style={{ fontSize: 10 }} id={`Icon_V${item.tiers_image_key}`} />)}
                                  </Row>
                                </Col>
                                <Col span={5} style={{ textAlign: "center" }}>
                                  {CONTEXT.uid != item?.uid ? <Link style={{width: '100%'}} to={{pathname: `/user/${item?.uid }`,  query: { "uid": item?.uid}}}>
                                      <Avatar src={`${item.picture}`} shape={"square"} />
                                      <span style={{ paddingLeft: 10,textAlign: 'left', display: 'inline-block', width: 'calc(100% - 40px)',color:"#333", whiteSpace:'nowrap'}}>{item.name}</span>
                                      </Link>: <Link to={{pathname:`/user`}}>
                                      <Avatar src={`${item.picture}`} shape={"square"} />
                                      <span style={{ paddingLeft: 10,textAlign: 'left', display: 'inline-block', width: 'calc(100% - 40px)',color:"#333", whiteSpace:'nowrap'}}>{item.name}</span>
                                      </Link>}
                                </Col>
                                <Col span={9} style={{ justifyContent: "flex-end", paddingRight: 18, }}>
                                  <span style={{ color: "#808695" }}>
                                    {`joined ${(this.dateDiff(new Date(item.join_time)))}`}
                                  </span>
                                </Col>
                                <Col span={5}>
                                  <this.MedalMarker type="g" value={item.awards?.gold || 0} />
                                  <this.MedalMarker type="s" value={item.awards?.silver ||0} />
                                  <this.MedalMarker type="b" value={item.awards?.bronze ||0} />

                                </Col>
                                <Col span={1} style={{ justifyContent: "flex-end" }}>
                                  <span style={{ color: "#808695" }}>
                                    {item.points}
                                  </span>
                                </Col>
                              </Row>
                            }}
                          />
                </div>
              </TabPane>
            </Tabs>

          </div>
        </div>
      </div>
      }}
      </Provider_LOGIN_STATE.Consumer>)
  }
}

export default Page
