
import { message } from 'antd';
import { logout } from '../../services/login';
const Logout = async function () {
    const accessToken = localStorage.getItem("access-token") || ""
    localStorage.setItem("access-token_bak",accessToken)
    localStorage.removeItem("access-token")
    message.info("注销成功");

    setTimeout(() => {
        document.location.reload()
    }, 2000);
}
export { Logout };

