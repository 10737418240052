import { PureComponent } from 'react';
import { Row, Col,Divider,Space } from 'antd';
// import * as 九坤logo from '../../../public/九坤logo-标准版.png';

import { Typography, List, Skeleton, Avatar, Dropdown } from 'antd';

import { SmileOutlined, MailOutlined,LinkedinOutlined } from '@ant-design/icons';
import BottomFooter from '../footer/bottomContent';
import "./index.less"

const { Title, Text, Link } = Typography;

type State = {
  voted?: boolean
}

class ForumFooter extends PureComponent<{}, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
    };
  }
  onVote() {
    this.setState({
      voted: !this.state.voted
    })
  }
  render() {
    return <>

<BottomFooter name='forum-simple-footer-box'></BottomFooter>
  {/* <div className="forum-simple-footer-box box-right-align-bottom">
    <div className="box-left">
      <span>
      Copyright 2015-2022 九坤投资 (北京) 有限公司版权所有 京ICP 备案 15026595号
      </span>
    </div>
    <div className="box-right box-right-align">
        <Space size="large">
          <Link href="#" target="_blank">
            参赛选手须知
          </Link>
          <Link href="#" target="_blank">
            隐私政策与用户协议
          </Link>
          <Link href="#" target="_blank">
            信息发布与管理规则
          </Link>
        </Space>
    </div>
  </div> */}
</>
  }
}

export default ForumFooter
