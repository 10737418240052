export const getOverflowStyles = (value: "hidden" | "overlay") => {
    const isFirefox = navigator.userAgent.includes("Firefox")
    switch (value) {
        case "overlay":
            if (isFirefox) {
                return "auto"
            }

        default:
            return value
    }
  
}