import { PureComponent } from 'react';
import { List, Typography, Menu, message } from 'antd';
import { LineDecoration } from '../../../component/lineDecoration';
import { Link, history} from 'umi';

import './index.less';
import {
  ClockCircleOutlined} from '@ant-design/icons';
import { Icon } from '@/functions/IconLab';
import NumberRoller from '@/component/NumberRoller';
const { Title } = Typography;
import { getList } from '../../../services/competion';
import moment from "moment"
import ArrowSlide from '@/layouts/arrow_slide/index'
import { clearConfigCache } from 'prettier';
type TypeList = {

}
const count = 3;
type State = {
}
class Page extends PureComponent<{}, State> {
  constructor(props: {}) {
    super(props);
    this.state = {
      list_old: [],
      list_old_page: '0',
      list_new: [],
      initLoading: true,
      loading: false,
      data: [],
    };
  }
  async componentDidMount() {
    this.getNewList();
    getList({ status: 'old', size: '10', page: '0' }).then(res => {

      this.setState({
        initLoading: false,
        data: res.data,
        list_old: res.data,
        list_old_page: res.page,
        list_old_totalNum: res.totalNum,
      });
    });
  }
  onLoadMore = () => {
    if((this.state.list_old_totalNum / 10) - 1 <= this.state.list_old_page){
      message.warning('暂无更多数据！');
      return
    }
    this.setState({
      loading: true
    });

    getList({ status: 'old', size: '10', page: String(this.state.list_old_page - (-1)) })
      .then(res => {
        const data = this.state.data.concat(res.data);
        this.setState(
          {
            data,
            list_old: data,
            loading: false,
          },
          () => {
            window.dispatchEvent(new Event('resize'));
          },
        );
      });
  };
  async getNewList() {
    try {
      let response_new = await getList({ status: 'new', size: '99999', page: '0' });
      if (UF_CONFIG.auth_mode == 'jwt' && response_new.data && response_new.data.token) {
        localStorage.setItem('access-token', 'Bearer ' + response_new.data.token);
      }

      this.setState({
        list_new: response_new?.data || [],
      })
      // message.info("获取数据成功")
    } catch (error) {
      message.error("登录失败")
      console.log(error);
    }

  }
  onError(e) {
    e.target.src = '/assets/images/com-active-1.png';
  }

  render() {
    const { initLoading, loading, list_old, list_new, list_old_page, list_old_totalNum } = this.state;
    const loadMore = Math.floor(list_old_totalNum/10) >  list_old_page ? (
      <div
        style={{
          textAlign: 'center',
          marginTop: 60,
          height: 32,
          lineHeight: '32px',
          cursor: 'pointer',
        }}
      >
        <span onClick={this.onLoadMore}>查看更多比赛</span>
      </div>
    ) : null;
    const menu = (
      <Menu>
        <Menu.Item>
          <a href="#">
            热度
          </a>
        </Menu.Item>
        <Menu.Item>
          <a href="#">
            最新发布
          </a>
        </Menu.Item>
      </Menu>
    );
    let _this = this;
    const goDetail = (isGo, url)=> {
      if(isGo) {
        return
      }
      history.push(url)
    }
    return (
      <div className="com-list-container">
        <div className="com-header">
          <LineDecoration />
        </div>
        <div className="com-active-list">
          <div className="flex-left">
            <Title level={3}>
              <ClockCircleOutlined style={{ marginRight: 10 }} />
              {"进行中的比赛"}
            </Title>
          </div>
          {/* <div className="flex-right">
            <Dropdown placement="bottomRight" overlay={menu}>
              <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                热度 <DownOutlined />
              </a>
            </Dropdown>
          </div> */}
        </div>
        <List
          className="com-list-content"
          loading={initLoading}
          itemLayout="horizontal"
          // loadMore={loadMore}
          dataSource={list_new}
          renderItem={info => {
            var award = 0;
            if(info.sub_competitions) {
              award = info.sub_competitions.reduce((c, R) => (c - 0) + (R.Competition.Award - 0), 0)
            }
            return (
            <div  className={`list-item  ${info?.sub_competitions?.length? "parents": ""}`}  onClick={()=>{goDetail(info?.sub_competitions?.length > 0, `/competition/item/?org_id=${info._id}`)}}  key={info._id} style={{paddingBottom: info?.sub_competitions?.length > 0 ? 0 : 30}}>
              <div className="flex-left">
                <a style={{cursor: 'inherit'}}>
                  <img onError={_this.onError.bind(_this)} src={info.Competition.Image} alt="" />
                </a> 
              </div>
              <div className="flex-middle">
                <Title level={5}>{info.Competition.Name}</Title>
                <div className="desc">{info.Competition.ShortDescription}</div>
                {info?.sub_competitions?.length > 0 ? '' : <div className="item-bottom">
                  <div className="time-close">比赛结束时间 <span>{ moment(new Date(info.Competition?.EndDate)).format("YYYY/MM/DD")}</span></div>
                  <div className="team-join">参赛团队<span>{info.Competition.TeamsNumber}</span></div>
                </div> }

              </div>
              {
                /^\d+$/.test(info.Competition.Award) ? <> <div className="flex-right">
                  <div className="award-box">
                    <div className="award-text">
                      总奖池<br />
                      <span className="money-synbol">¥</span>
                      <span className="money-price"><NumberRoller value={Number(info.Competition.Award) || award} size={25} height={32} /></span>
                    </div>
                  </div>
                </div> </> : info.Competition.Award ?  <> <div className="flex-right">
                  <div className="award-box-old">
                    <div className="award-text">
                      <span className="money-price">{info.Competition.Award}</span>
                    </div>
                  </div>
                </div> </> : info?.sub_competitions?.length > 0 ? <> <div className="flex-right">
                  <div className="award-box">
                    <div className="award-text">
                      总奖池<br />
                      <span className="money-synbol">¥</span>
                      <span className="money-price"><NumberRoller value={award} size={25} height={32} /></span>
                    </div>
                  </div>
                </div> </> : <></>
              }
              {info?.sub_competitions?.length > 0 ?
              <ArrowSlide
                key={info._id}
              >
                <div className="sub-list-content">
                  {info.sub_competitions.map(function (d, idx) {
                    return (
                      <div key={idx} className="sub-list-item" onClick={()=>{goDetail(false ,`/competition/item/?org_id=${d._id}`)}}>
                        {/* <Link to={'competition/item/?org_id=' + d._id}> */}
                        <div className="left">
                          <img className="one" src='/assets/images/blue-circle.svg' alt="" />
                          <div style={{height: "1px", width: '42.5%', position: 'relative'}}>
                          {idx == info.sub_competitions.length - 1 ? <p className="line" style={{height: (idx * 154 + 42) + "px" , top: -(idx * 154 + 42)+'px', left: '-5px'}}></p> : ''}
                          </div>
                          <img className="two" src='/assets/images/light.svg' alt="" />
                        </div>
                        <div className="center">
                          {/* <Link to={'competition/item/?org_id=' + d._id}> */}
                            <Title level={5} style={{fontWeight:'400'}}>{d.Competition.Name}</Title>
                          {/* </Link> */}
                          <div className="desc">{d.Competition.ShortDescription}</div>
                          <div className="item-bottom">
                            <div className="time-close">比赛结束时间 <span>{ moment(new Date(d.Competition?.EndDate)).format("YYYY/MM/DD")}</span></div>
                            <div className="team-join">参赛团队<span>{d.Competition.TeamsNumber}</span></div>
                          </div>
                        </div>
                        <div className="right">
                          <div className="status-box-open">
                            {d.Competition.StatusRender}
                          </div>
                          {
                            /^\d+$/.test(d.Competition.Award) ? <>
                              <div className="award-box">
                                <div className="award-text">
                                  <span className="money-synbol">¥</span>
                                  <span className="money-price"><NumberRoller value={d.Competition.Award} size={20} height={25} /></span>
                                </div>
                              </div>
                            </> : d.Competition.Award ?  <>
                              <div className="award-box-old">
                                <div className="award-text">
                                  <span className="money-price">{d.Competition.Award}</span>
                                </div>
                              </div>
                            </>: <></>
                          }
                        </div>
                        {/* </Link> */}
                      </div>
                    )
                  })}
                </div>
              </ArrowSlide> : <></>}
              
            </div>
          )

          } }
        />

        <div className="com-old-list">
          <div className="flex-left">
            <Title level={3}>
              <Icon id="iconDotCircle" style={{ marginRight: 10 }}/>
              {"过往的比赛"}
            </Title>
          </div>
        </div>
        <List
          className="com-list-content"
          loading={initLoading}
          itemLayout="horizontal"
          loadMore={loadMore}
          dataSource={list_old}
          renderItem={info => (
            <div className={`list-item  ${info?.sub_competitions?.length? "parents": ""}`} key={info._id}  onClick={()=>{goDetail(info?.sub_competitions?.length > 0, `/competition/item/?org_id=${info._id}`)}} >
              <div className="flex-left">
                <a  style={{cursor: 'inherit'}}>
                  <img onError={_this.onError.bind(_this)} src={info.Competition.Image} alt="" />
                </a>
              </div>
              <div className="flex-middle">
                <Title level={5}>{info.Competition.Name}</Title>
                <div className="desc">{info.Competition.ShortDescription}</div>
                <div className="item-bottom">
                  <div className="time-close">比赛结束时间 <span>{ moment(new Date(info.Competition?.EndDate)).format("YYYY/MM/DD")}</span></div>
                  <div className="team-join">参赛团队<span>{info.Competition.TeamsNumber}</span></div>
                </div>
              </div>
              {
                /^\d+$/.test(info.Competition.Award) ? <> <div className="flex-right">
                  <div className="award-box-old">
                    <div className="award-text">
                      总奖池<br />
                      <span className="money-synbol">¥</span>
                      <span className="money-price"><NumberRoller value={info.Competition.Award} size={25} height={32} /></span>

                    </div>
                  </div>
                </div> </> : info.Competition.Award ?  <> <div className="flex-right">
                  <div className="award-box-old">
                    <div className="award-text">
                      <span className="money-price">{info.Competition.Award}</span>
                    </div>
                  </div>
                </div> </>: <></>
              }
              
              {info?.sub_competitions?.length > 0 ?
              <ArrowSlide
                key={info._id}
              >
                <div className="sub-list-content">
                  {info.sub_competitions.map(function (d, idx) {
                    return (
                      <div key={idx} className="sub-list-item" onClick={()=>{goDetail(false ,`/competition/item/?org_id=${d._id}`)}}>
                        <div className="left">
                          <img className="one" src='/assets/images/blue-circle.svg' alt="" />
                          <div style={{height: "1px", width: '42.5%', position: 'relative'}}>
                          {idx == info.sub_competitions.length - 1 ? <p className="line" style={{height: (idx * 154 + 42) + "px" , top: -(idx * 154 + 42)+'px', left: '-5px'}}></p> : ''}
                          </div>
                          <img className="two" src='/assets/images/light.svg' alt="" />
                        </div>
                        <div className="center">
                            <Title level={5} style={{fontWeight:'400'}}>{d.Competition.Name}</Title>
                          <div className="desc">{d.Competition.ShortDescription}</div>
                          <div className="item-bottom">
                            <div className="time-close">比赛结束时间 <span>{  moment(new Date(d.Competition?.EndDate)).format("YYYY/MM/DD")}</span></div>
                            <div className="team-join">参赛团队<span>{d.Competition.TeamsNumber}</span></div>
                          </div>
                        </div>
                        <div className="right">
                          <div className="status-box-open">
                            {d.Competition.StatusRender}
                          </div>
                          {
                            /^\d+$/.test(d.Competition.Award) ? <>
                              <div className="award-box">
                                <div className="award-text">
                                  <span className="money-synbol">¥</span>
                                  <span className="money-price"><NumberRoller value={d.Competition.Award} size={20} height={25} /></span>
                                </div>
                              </div>
                            </> : d.Competition.Award ?  <>
                              <div className="award-box-old">
                                <div className="award-text">
                                  <span className="money-price">{d.Competition.Award}</span>
                                </div>
                              </div>
                            </>: <></>
                          }
                        </div>
                        {/* </Link> */}
                      </div>
                    )
                  })}
                </div>
              </ArrowSlide> : <></>}
            </div>
          )}
        />
      </div>
    );
  }
}


const CompetitionCard = () => {

}
export default Page
