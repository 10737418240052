import { PureComponent } from 'react';

import { getPersonalInfo, UserPublicInfo } from '@/services/user';
import { UserBillboard_Activity } from './activity';
import { UserBillboard_Competition } from './competition';




type UserBillboardProps = {
    uid: number
    userInfo?: UserPublicInfo
}
type UserBillboardState = {
    info?: UserPublicInfo
}
export class UserBillboard extends PureComponent<UserBillboardProps, UserBillboardState> {
    constructor(props: UserBillboardProps) {
        super(props);
        this.state = {
            info: props.userInfo
        };
    }
    render() {
        return <div className="UserBillboard" style={{ display: 'flex' }}>
            <UserBillboard_Competition userInfo={this.state.info} uid={this.props.uid} />
            <UserBillboard_Activity userInfo={this.state.info} uid={this.props.uid} />
        </div>
    }
    async componentDidMount() {
        if (!this.props.userInfo) {
            const response = await getPersonalInfo(this.props.uid)
           setTimeout(() => {
            this.setState({ info: response.data })
           }, 400);
        }
    }

}
