import { message } from 'antd';
import { detail } from '../../../services/competion'
import React , {useEffect, PureComponent}from 'react';
import 比赛详情bg图 from '../../../../public/比赛详情bg图.png';
import './index.less';

type TypeList = {
}
type State = {
  // sections: TypeBlockItemList[]
  // list?: TypeList[]
  org_id: string
  imgUrl: string
  infoData: Object
  sections: Array
}
class Page extends PureComponent<{}, State> {
  constructor(props: {}) {
    super(props);
    this.state = {
      sections: [],
      org_id: this.props.location.query.org_id,
      imgUrl: "$$$$baidu$$$$",
      infoData: {}
    };
    sessionStorage.setItem("org_id", this.props.location.query.org_id)
  }

  componentDidMount() {
    this.getDetail(this.state.org_id);
  }
  async getDetail(org_id: string) {
    try {
      let response = await detail(org_id, (this.props.location.query.invitation_code || ""));

      this.setState({
        infoData: response,
        imgUrl: (response.Competition?.ImageHeading)
      })
      if(this.props.location.query.invitation_code) {
        let invitationInfo = {
          "code": this.props.location.query.invitation_code,
          "name": response['invitor'].name,
          "phone": response['invitor'].phone,
        } 
        localStorage.setItem(this.props.location.query.org_id, JSON.stringify(invitationInfo))
      }
      // message.info("获取数据成功")
    } catch (error) {
      message.error("获取数据失败")
      console.log(error);
    }
  }

  onError(e: Object) {
    if(e.target.src.indexOf("$$$$baidu$$$$") < 0) {
      this.setState({
        imgUrl: 比赛详情bg图
      })
    }
    // e.target.src = '/assets/images/com-active-1.png';
  }

  getCurrentChildren() {
    const _this = this;
    let { children } = _this.props;
    return React.Children.map(children, child => {
      return React.cloneElement(child, {
        info: _this.state.infoData
      })
    })
  }

  render() {
    const info = this.state.infoData.Competition;
    return <div className="com-detail-container">
      <div className="com-detail-header" style={{marginRight: '-8vw'}}>
        <div style={{
          position: "relative"
        }}>
        <img onError={this.onError.bind(this)} style={{width: "100%", }} src={this.state.imgUrl} alt="" />
          {this.state.infoData?.title_hide? "" : <div style={{display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          color: "white",width: "100%", height: '100%', position: "absolute" ,left: '0', top: '0'}}><div style={{ fontSize: 38, lineHeight: "57px", marginBottom: 5 }}>{info?.Name}</div>
          <div style={{ fontSize: 18, lineHeight: "27px", marginBottom: 12, marginLeft: 60, marginRight: 60 }}>{info?.ShortDescription}</div>
          {<总奖池Card data={info} />}</div>}
        </div>
      </div>
      <div className="com-detail-main">
        {this.getCurrentChildren()}
      </div>

    </div>
  }
}

const 总奖池Card = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return <div style={{
    fontSize: 20,
    color: "#176EDE",
    background: "linear-gradient( -180deg, #FDF6EB 0%,  #F9C98C 100% )",
    borderRadius: 10,
    padding: 15,
    paddingTop: 0,
    paddingBottom: 0,
    fontWeight: 600,
  }}>
    <div style={{
      height: "100%",
      filter: "drop-shadow(0px -1px 1px rgba(236, 173, 104, 0.9095))",
    }}>
      <span style={{
        fontWeight: 700,
        filter: "drop-shadow(0px 1px 1px rgba(255, 255, 255,0.5))",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}>
        {/^\d+$/.test(props?.data?.Award) ? <><span>{"总奖池"}</span><span style={{ fontSize: 13, margin: 4, }}>{"￥"}</span><span style={{ background: "linear-gradient( 160deg, #176EDE 0%,  #BA4300 100% )", fontSize: 25, backgroundClip: "text", WebkitBackgroundClip: "text", WebkitTextFillColor: "transparent", }}>{props.data?.Award}</span></> : props.data?.Award ? <span>{"奖项:" + (props.data?.Award || "")}</span>: ""}
      </span>
    </div>

  </div>
}
const CompetitionCard = () => {

}
export default Page
