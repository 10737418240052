import { Pagination } from 'antd';
import { PureComponent } from 'react';
import { LineDecoration } from '../../component/lineDecoration';

import './index.less';

import { CloseOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Typography } from 'antd';

import { BlockItem, TypeBlockItemList } from "./blockListItem";
import { PostListItem } from "./postListItem";
// import ForumSvg from '@/pages/icons/forum_black_18dp.svg';
import ForumSvg from '@/pages/icons/forum2.svg';
import TeamSvg from '@/pages/icons/team2.svg';

import Icon from '@ant-design/icons';

// import store from '@/redux/store/store'
import actionCreator from '@/redux/store/actionCreator';

import { SortingSelectionValue, SortingSelector } from '@/component/Select/SortingSelector';
import {
  getForumSection, getTags, searchTopics
} from "../../services/forum";
const { Title } = Typography;


type TypeList = {
  id: number
  section_id: number
  section_name: string
  title: string
  is_like: boolean
  view_count: number
  comment_count: number
  like_count: number
  sticky: boolean
  good: boolean
  status: boolean
  is_locked: boolean
  status: boolean
  create_date: Date
  latest_comment_timestamp: Date
  status: boolean
  // themeColor: string
  // description: string
  latest_action?: {
    type: string
    author: string
    time: Date
  }
  author?: {
    name: string
    id: number
    picture: string
  }
}[]
type State = {
  sections?: TypeBlockItemList[]
  list?: TypeList[]
  total?: number
  current?: number
  search: string,
  loading: boolean,
  tag_list: [],
  sortBy: string
}
class Page extends PureComponent<{}, State> {
  constructor(props: {}) {
    super(props);
    this.state = {
      // sections: [],
      // list: [],
      search: '',
      loading: false,
      tag_list: [],
      selectId: '',
      sortBy: 'created'
    };
  }

  async componentDidMount() {
    const result = await getForumSection()
    const data = result.data
    const sections: TypeBlockItemList[] = []
    for (let item of data) {
      sections.push({
        id: item.id,
        key: item.id,
        icon: (item.icon ? new URL(item.icon) : ''),
        name: item.name,
        description: item.description || "",
        topics: item.last_posts || [],
      })
    }

    actionCreator.setSections(sections)

    this.setState({
      sections
    })

    const result2 = await searchTopics('', 1, '', this.state.sortBy)
    const data2 = result2.data;
    let total = data2.count
    let page = data2.page
    const list: TypeList[] = []

    for (let item of data2.data) {
      list.push(item);
    }

    this.setState({
      list,
      total,
      page,
      loading: false,
    })

    getTags().then((res) => {
      let arr = [];
      for (let item in res.data) {
        arr.push({
          id: item,
          name: res.data[item]
        })
      }
      this.setState({ tag_list: arr })
    })
  }


  searchTopic = async (pageCurrent, sortBy) => {
    console.log(this.state.search)
    console.log('---')

    this.setState({
      list: []
    });

    const result2 = await searchTopics(this.state.search || '', pageCurrent, '', sortBy || this.state.sortBy)
    const data2 = result2.data
    console.log("searchTopics", data2)
    let total = data2.count
    let page = data2.page
    const list: TypeList[] = []

    for (let item of data2.data) {
      item.key = item.id;
      list.push(item);
    }

    this.setState({
      list,
      total,
      page,
      loading: false,
    })
    // this.forceUpdate();
    // console.log('forceUpdate', this.state.total)
  }

  _handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      console.log('do validate');
      // console.log(e.target.value);
      this.searchTopic(1, this.state.sortBy);
    }
  }

  handleChange = (e) => {
    this.setState({ search: e.target.value });
  }

  onChangePage = (page, pageSize) => {
    console.log('page, ', page);
    this.searchTopic(page, this.state.sortBy);
    console.log('pageSize, ', pageSize);
  }

  onSortChange = (sortBy: SortingSelectionValue) => {
    this.setState({ sortBy })
    this.searchTopic(this.state.page, sortBy);
  }


  async filter_Func(id) {
    var tagId = '';
    if (id === this.state.selectId) {
      tagId = ''
    } else {
      tagId = id;
    }

    this.setState({
      list: [],
      loading: true
    });
    const result2 = await searchTopics(this.state.search, 1, tagId, this.state.sortBy)
    const data2 = result2.data
    console.log("searchTopics", data2)
    let total = data2.count
    let page = data2.page
    const list: TypeList[] = []

    for (let item of data2.data) {
      list.push(item);
    }

    this.setState({
      list,
      total,
      page,
      loading: false,
      selectId: tagId
    })
  }

  render() {
    return (
      <div className="dis-section-list">
        <div className="com-header">
          <LineDecoration />
        </div>
        <Title level={3}>
          <Icon className="uf-icon" component={ForumSvg} />
          {"讨论"}
        </Title>
        <BlockItem list={this.state.sections} />
        <Title level={3} style={{ marginTop: 7 }}>
          <Icon className="uf-icon" component={TeamSvg} style={{ marginTop: 32 }} />
          {"站内所有的主题"}
        </Title>
        <div className="searchWrap" >
          <input type="text" placeholder="搜索主题" onChange={this.handleChange} onKeyDown={this._handleKeyDown} ref={input => this.search = input} />
          <span className="forum-icon-search" onClick={this.searchTopic}><SearchOutlined /></span>
        </div>
        <div style={{ display: "flex" }}>

          <div className="tag_list" style={{width:"calc(100% - 256px)",display:"inline-block"}}>
            {this.state.tag_list.map((tag, index) => {
              if (this.state.selectId) {
                if (this.state.selectId === tag.id) {
                  return <Button onClick={() => { this.filter_Func(tag.id) }} key={index} data-id={tag.id} className={`tag-item  ${this.state.selectId ? 'selected' : ''}`}>
                    <span className='text'>{tag.name}</span>
                    <CloseOutlined style={{ fontSize: 12, marginTop: 2 }} />
                  </Button>
                }
              } else {
                return <Button onClick={() => { this.filter_Func(tag.id) }} key={index} data-id={tag.id} className={`tag-item`}>
                  <span className='text'>{tag.name}</span>
                </Button>
              }
            })
            }
          </div>
          <div style={{ textAlign: "right" }}>
            <SortingSelector onChange={this.onSortChange.bind(this)} />
          </div>
        </div>
        <PostListItem list={this.state.list} />
        <Pagination
          className={"discussions_main_Pagination"}
          // defaultCurrent={this.state.page + 1}
          current={this.state.page + 1}
          total={this.state.total}
          showSizeChanger={false}
          pageSizeOptions={10}
          pageSize={10}
          onChange={this.onChangePage}
        />
      </div>
    );
  }
}

export default Page
