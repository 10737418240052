import React, { PureComponent } from "react";
import {history} from 'umi';

type State = {
}

 
export class Redirect extends PureComponent<{}, State>  {
  constructor( props: {} ){
    super();
    this.state = { ...props };
  }
  componentWillMount(){
    window.open(decodeURIComponent(this.state.location.query.url));
  }
  render(){
    return (<>
    <section>已跳转页面...</section>
    <button onClick={()=> {
      history.push({
        pathname: '/competition/item',
        query: {
          org_id: sessionStorage.getItem("org_id")
        }})
    }}>返回</button>
    </>
    
    );
  }
}
 
export default Redirect;