import { LinkedinFilled, LinkedinOutlined, MailOutlined } from '@ant-design/icons';
import { Col, Divider, Row, Space, Typography } from 'antd';
import { PureComponent } from 'react';
import 九坤logo from '../../../public/九坤logo-标准版.png';
import IMG_wechatSubscribe from '../../../public/assets/images/wechat-subscribe.png';
import IMG_hireAssisant from '../../../public/assets/images/hire-assisant.png';
import "./index.less";
import { Icon } from '@/functions/IconLab';
import BottomFooter from './bottomContent'



const { Title, Text, Link } = Typography;

type Props = {
  black: boolean
}
type State = {
  voted?: boolean
}

class ForumFooter extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
    };
  }
  onVote() {
    this.setState({
      voted: !this.state.voted
    })
  }
  LeftImgStyleBase: React.CSSProperties = {
    width: 110,
    marginBottom: 24,
  }
  RightBoxStyleBase: React.CSSProperties = {
    textAlign: "left",
    display: "inline-block",
    // width: 220,
    // maxWidth: "33%",
    wordBreak: "break-all",
    color: "#333333",
    verticalAlign: "top",
  }
  RightTopContentStyleBase: React.CSSProperties = {
    fontSize: 16,
    display: "block",
    width: "fit-content",
    lineHeight: "24px",
    color: "#333333",
    borderBottom: "1px #333333 solid"
  }
  RightBottomIconBoxStyleBase: React.CSSProperties = {
    marginTop: 51,
    marginBottom: 33,
    width: 44,
    height: 44,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: '#666666',
    padding: 10,
    border: "1px #979797 solid",
    borderRadius: "100%",
    fontSize: 24,
  }

  render() {
    return <>
      <div className="forum-footer">
        <Row gutter={16} className={"forum-foot-container"}>
          <Col span={10} style={{ fontSize: 14 }}>
            <div style={{ display: "inline-block", marginRight: 50, textAlign: "center" }}>
              <img src={IMG_hireAssisant} style={this.LeftImgStyleBase} />
              <p>{"九坤招聘公众号"}</p>
            </div>
            <div style={{ display: "inline-block", textAlign: "center" }}>
              <img src={IMG_wechatSubscribe} style={this.LeftImgStyleBase} />
              <p>{"坤坤招聘助手"}</p>
            </div>
          </Col>
          <Col span={14} style={{
            textAlign: "right", display: 'flex', justifyContent: 'flex-end',...(() => {
              if (this.props.black) {
                return {
                  filter: "invert(100%) brightness(1.4)"
                }
              }
            })()
          }}>
            <div style={this.RightBoxStyleBase}>
              <div>
                <Link href="https://www.ubiquant.com" target="_blank">
                  <Text style={this.RightTopContentStyleBase}>{"九坤官网"}</Text>
                </Link>
              </div>
              <Link href="mailto:hackathon@ubiquant.com" target="_blank">
                <Icon id="MailOutlined" style={this.RightBottomIconBoxStyleBase} />
                <div>
                  <Text>{"赛事答疑"}</Text>
                  <br />
                  <Text>{"hackathon@ubiquant.com"}</Text>
                </div>
              </Link>
            </div>
            <div style={{...this.RightBoxStyleBase, marginLeft:20, marginRight: 20}}>
              <div>
                <Link href="https://app.mokahr.com/apply/ubiquantrecruit/37030#/" target="_blank">
                  <Text style={this.RightTopContentStyleBase}>{"社会招聘"}</Text>
                </Link>
              </div>
              <Link href="mailto:recruiter@ubiquant.com" target="_blank">
                <Icon id="MailOutlined" style={this.RightBottomIconBoxStyleBase} />
                <div>
                  <Text>{"招聘答疑"}</Text>
                  <br />
                  <Text>{"recruiter@ubiquant.com"}</Text>
                </div>
              </Link>
            </div>
            <div style={this.RightBoxStyleBase}>
              <div>
                <Link href="https://app.mokahr.com/campus_apply/ubiquantrecruit/37031" target="_blank">
                  <Text style={this.RightTopContentStyleBase}>{"校园招聘"}</Text>
                </Link>
              </div>
              <div onClick={() => {
                window.open("https://www.linkedin.com/company/%E4%B9%9D%E5%9D%A4%E6%8A%95%E8%B5%84-%E5%8C%97%E4%BA%AC-%E6%9C%89%E9%99%90%E5%85%AC%E5%8F%B8/")
              }} style={{ cursor: "pointer" }}>
                <Icon id="LinkedinFilled" style={this.RightBottomIconBoxStyleBase} />
                <div>
                  <Text>{"领英"}</Text>
                  <br />
                  <Text>{"linkedin/九坤投资"}</Text>
                </div>
              </div>
            </div>

          </Col>
        </Row>
      </div>
      <BottomFooter name='home-forum-footer'></BottomFooter>
      {/* <div className="forum-footer-box box-right-align-bottom">
        <div className="box-left">
          <span>
            Copyright 2015-2022 九坤投资 (北京) 有限公司版权所有 京ICP 备案 15026595号
          </span>
        </div>
        <div className="box-right box-right-align">
          <Space size="large">
            <Link href="#" target="_blank">
              参赛选手须知
            </Link>
            <Link href="#" target="_blank">
              隐私政策与用户协议
            </Link>
            <Link href="#" target="_blank">
              信息发布与管理规则
            </Link>
          </Space>
        </div>
      </div> */}
    </>
  }
}

export default ForumFooter
