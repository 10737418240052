import { Button, ConfigProvider, Input, Menu, Modal, Popover, Tabs, Upload } from 'antd';
import ImgCrop from 'antd-img-crop';
import React, { PureComponent } from 'react';
import { history } from 'umi';
import { NationPhone } from '@/layouts/nation-phone';
import { } from '@/services/login';
import { getCompetictionList, getInvitationLists, getPersonalInfo, getPostList, getUserCompetictions, getUserInfo, getUserPosts, Posts, updateUserInfo } from '@/services/user';
import { FrownOutlined, LogoutOutlined } from '@ant-design/icons';
import { Avatar, Badge, List, message, Typography } from 'antd';
import zhCN from 'antd/lib/locale/zh_CN';
import { parsePhoneNumber } from 'react-phone-number-input';
import { Link } from 'umi';
import { Logout } from '../../functions/logout';
import { LoginState, Provider_LOGIN_STATE } from '../../layouts/beforeRender/loginState';
import { API_uploadImage } from "../../services/files";
import { ListMaker } from '../discussions/zone';
import "./index.less";

type InfoKey = "name" | "email" | "phoneNumber" | "phoneState"

import moment from 'moment';
import { UserBillboard } from './Billboard';
import { UserProfileRender } from './Render';

const infoKeyTranslate: {
  [str in InfoKey]: string
} = {
  name: "昵称",
  email: "邮箱",
  phoneNumber: "手机号",
  phoneState: "手机状态",
}
const { TabPane } = Tabs;
const { Title, Text } = Typography;


type State = {
  voted?: boolean
  com_list: []
  InvitationList: []
  posts: Posts[]
  image_url: string
  page: Number
  total: string
  name: string
  phone: string
  email: string
  new_message: boolean,
  userInfo: Object
}

class Page extends PureComponent<{}, State> {
  static contextType = Provider_LOGIN_STATE;
  constructor(props: {}) {
    super(props);
    this.state = {
      com_list: [],
      InvitationList: [],
      image_url: '',
      page: 0,
      total: '3',
      showComponent: '',
      name: '',
      phone: '',
      email: '',
      visible: false,
      compitionsList: [],
      new_message: false,
      userInfo: {},
      postList: []
    };
  }
  render() {
    const InlineComItem = (
      <div className="list-item">
        <div className="flex-left">
          <Link to="/login">
            <img src="/assets/images/com-active-1.png" alt="" />
          </Link>
        </div>
        <div className="flex-middle">
          <Link to="/login">
            <Title level={5}>量化投资联赛 - 第四届 模式市场交易赛</Title>
          </Link>
          <div className="desc">在模拟的市场上，尽情追求高额的、稳定的收益，请尽量优化</div>
          <div className="item-bottom">
            <div className="time-close">比赛结束时间 <span>2022/06/15</span></div>
            {/*<div className="team-join">参赛团队<span>142</span></div>*/}
          </div>
        </div>
        <div className="flex-right">
          <div className="status-box-open">
            <div className="status-text">进行中</div>
          </div>
        </div>
      </div>
    )

    const goDetail = (isGo, url) => {
      if (isGo) {
        return
      }
      history.push(url)
    }
    // const [isModalVisible, setIsModalVisible] = useState(false);
    return <Provider_LOGIN_STATE.Consumer>
      {CONTEXT => {
        return <UserProfileRender type={"usercenter"} />
      }
      }
    </Provider_LOGIN_STATE.Consumer >

  }
  async componentDidMount() {
    const CONTEXT = this.context as LoginState
    const currentUserID = CONTEXT.uid
    const posts2 = await getPersonalInfo(currentUserID)

    const posts = await getUserPosts({ 'with_comments': '1' })
    let is_new_message = posts.data.filter(x => x.new_message > 0).length > 0;
    const compitionsList = await getCompetictionList({ size: '10', page: '0' });
    const userInfo = await getUserInfo();
    const postList = await getPostList({ size: '10', page: '0' });
    this.setState({
      posts: posts.data,
      new_message: is_new_message,
      userInfo: userInfo.data,
      compitionsList: compitionsList.data.data,
      postList: postList.data
    })

  }
  hide = () => {
    this.setState({
      visible: false,
    });
  }

  setParentName = (name) => {
    this.setState({
      name: name,
    });
  }
  setParentPhone = (phone) => {
    this.setState({
      phone: phone,
    });
  }
  setParentEmail = (email) => {
    this.setState({
      email: email,
    });
  }
  onVote() {
    this.setState({
      voted: !this.state.voted
    })
  }
  menu = <Menu>

    <Menu.Item icon={<FrownOutlined />}>
      <a onClick={Logout}>
        {"更换头像"}
      </a>
    </Menu.Item>
    <Menu.Item icon={<LogoutOutlined />} danger >
      <a onClick={Logout}>
        {"登出"}
      </a>
    </Menu.Item>
  </Menu>
  async loadCompetictions() {
    try {
      let coms = [];
      let res = await getUserCompetictions();
      for (let com of res.data.data) {
        com.key = com._id;
        coms.push(com);
      }
      console.log(coms);
      this.setState({
        com_list: coms
      })
    } catch (e) {
      console.log(e);
    }
  }
  async loadInvitationLists() {
    try {
      let coms = [];
      let res = await getInvitationLists();
      console.log(res);
      this.setState({
        InvitationList: res.data.data,
        total: res.data.totalNum,
        page: res.data.page,
      })
    } catch (e) {
      console.log(e);
    }
  }
  tabChange = (activeKey) => {
    if (activeKey == 2) {
      this.loadCompetictions();
    } else if (activeKey === '4') {
      this.loadInvitationLists()
    }
    console.log(activeKey);
  }

  updateImg(result, msg) {
    this.imgRef.src = msg;
  }

  onChangePage = (page, pageSize) => {
  }

  imgRef = React.createRef();

}

 
 
export default Page
