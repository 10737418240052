import React from 'react';
export type LoginState = {
    id: string
    uid: number
    userName: string
    phone: string
    email: string
    avatar: URL
    login: boolean
    points: number
    joinTime: Date
    competition_range: string
    forum_range: string
    tiers: string
    tiers_image_key: number
}
export const defaultLoginStateState: LoginState = {
    id: "0",
    uid: 0,
    userName: "unlogin",
    phone: "",
    email: "",
    avatar: new URL(document.location.href),
    login: false,
    points: 0,
    joinTime: new Date(),
    competition_range: "",
    forum_range: "",
    tiers: "",
    tiers_image_key: 0,
}
export const Provider_LOGIN_STATE = React.createContext(defaultLoginStateState);