import { Tabs, Radio, Table, Avatar } from 'antd';
import { PureComponent } from 'react';
const { TabPane } = Tabs;
import './index.less';

import {Competition_leadboard} from '@/services/competion'
const { Column, ColumnGroup } = Table;
type State = {
}

const columns = [
  {
    title: '排名',
    dataIndex: 'num',
    align: 'center',
    width: 100,
  },
  {
    title: '队伍名称',
    dataIndex: ['Team', 'Name'],
    align: 'left'
  },
  {
    title: '团队成员',
    align: 'right'
  },
  {
    title: '分数',
    dataIndex: 'Score',
    width: 150,
    align: 'right'
  },
  {
    title: '提交次数',
    dataIndex: 'Entries',
    width: 200,
    align: 'right'
  },
];

const params = [
    // 在前的团队 排名在前
    {
        "Team": {
            "_id": "61beb3a74b63a700179b7be1", // 团队id
            "Description": "",
            "Members": [
                "61beb04c48d37b0017e35b8d", // 团队成员id
            ],
            "Name": "鼠海豚mhn0的团队", // 团队名称
            "Captain": "61beb04c48d37b0017e35b8d", // 队长id
            
        },
        "Entries": 6, // 提交次数
        "Score": 77.84896790981293, // 提交分数
        "BestSubmissionDate": "2021-12-30T04:32:28.786Z",
        "LastSubmissionDate": "2021-12-30T04:32:28.786Z",
        "Results": [],
        "num": 1
    },
    {
        "Team": {
            "_id": "61beb3a74b63a700179b7be1", // 团队id
            "Description": "",
            "Members": [
                "61beb04c48d37b0017e35b8d", // 团队成员id
            ],
            "Name": "鼠海豚mhn0的团队", // 团队名称
            "Captain": "61beb04c48d37b0017e35b8d", // 队长id
            
        },
        "Entries": 6, // 提交次数
        "Score": 77.84896790981293, // 提交分数
        "BestSubmissionDate": "2021-12-30T04:32:28.786Z",
        "LastSubmissionDate": "2021-12-30T04:32:28.786Z",
        "Results": [],
        "num": 2
    }
];


class RankList extends PureComponent<{}, State>  {
  state = { size: 'small', loading: true, data: [],  };

  componentDidMount() {
      const org_id = sessionStorage.getItem('org_id') || "";
      if(org_id) {
        Competition_leadboard(org_id).then((res)=>{
          res.lb?.forEach((item, index) => {
            item["num"] = index + 1;
          })
            this.setState({
                loading: false,
                data: (res.lb || [])
            })
        })

      }
  }

  render() {
    const { data, loading } = this.state;
    return (
      <div className={"rankList"}>
          <ul>

          </ul>
          <Table
            rowClassName={(record, index) => {}}
            rowKey={record => record.Team?._id}
            dataSource={data}
            loading={loading}
            >
                <Column title="排名" align={"center"} dataIndex="num" key="num" width={100}/>
                <Column title="队伍名称" dataIndex={['Team', 'Name']} align={"left"} />
                <Column
                  title="团队成员"
                  align="right"
                  dataIndex={['Team', 'Members']} 
                  render={(Members,Render, index) => {
                    console.log(Members,Render, index)
                    return <><Avatar.Group>
                         {Array.from(Render.Team.RenderMembers || Members || [], (card, cardKey) => {
                           let src = card.avatar || "http://ubiquant.forum.yubis.net/sites/default/images/default-thumb.png"
                            return <Avatar src={src} />
                            // return <Avatar>{card.substring(0,2)}</Avatar>
                          })}
                      </Avatar.Group>
                    </>
                  }}
                />
                <Column title="分数" dataIndex={"Score"} align={"right"} width={150}/>
                <Column title="提交次数" dataIndex={"Entries"} align={"right"}  width={150}/>

            </Table>
        {/* <Tabs defaultActiveKey="1" size={"default"} style={{ marginBottom: 32 }}>
          <TabPane tab="Public排行榜" key="1">
          
          </TabPane>
          <TabPane tab="Private排行榜" key="2">
            Content of Private排行榜
          </TabPane>
        </Tabs> */}
      </div>
    );
  }
}

export default RankList;