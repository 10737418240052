
import { IconKey } from "../functions/IconLab";

type Menu = {

  tag?: string;
  path?: string;
  href?: string;
  title?: string;
  icon: [
    /**默认图标 */
    IconKey,
    /**激活图标 */
    IconKey,
  ];

  component?: string | (() => any);
  wrappers?: string[];
  routes?: Menu;
}[];

const MENU_CONFIG: Menu = [
  {
    path: '/home',
    href: '/home',
    title: '主页',
    icon: ['Icon_Home', "Icon_HomeBlue"],
  },
  {
    path: '/competition',
    href: '/competition',
    title: '比赛',
    icon: ["Icon_比赛", "Icon_比赛Blue"],
  },
  {
    path: '/discussions',
    href: '/discussions',
    title: '论坛',
    icon: ["Icon_论坛", "Icon_论坛Blue"],
  },
  {
    path: '/rank',
    href: '/rank',
    title: '论坛排名',
    icon: ["Icon_论坛排行", "Icon_论坛排行Blue"],
  },
  {
    tag: 'user',
    path: '/user',
    href: '/user',
    title: '我的帐号',
    icon: ["Icon_我的账号", "Icon_我的账号Blue"],
  },
  {
    path: '',
    href: 'https://comtask.ubiquant.com/mw-org/ubiop/workspace/project',
    title: '项目管理',
    icon: ["Icon_projectManageBlue", "Icon_projectManageBlue"],
    // routes: [
    //   {
    //     path: './home',
    //     title: '子项目1',
    //     component: '@/pages/general/index',
    //   },
    //   {
    //     path: './*',
    //     title: '子项目2',
    //     component: '@/pages/general/index',
    //   },
    // ],
  },
];
export {
  MENU_CONFIG
};

