import { GeneralEditor } from '@/component/Editor';
import { Provider_LOGIN_STATE } from '@/layouts/beforeRender/loginState';
import { Avatar, Input, message, Progress, Select, Skeleton, Tag } from 'antd';
import { PureComponent } from 'react';
import { history as umiHistory, Link } from 'umi';
import { editPost, getForumSection, getPostDetail } from "../../../../services/forum";


export type PostEditing = PostForEdit | PostForNew

export type PostForEdit = {
    behavior: "edit"
    // title: string
    // content: string
    // sectionId: number
    postId: number
}
export type PostForNew = {
    behavior: "new"
    sectionId: number
}
export type PostReEditPageBehaviorType = "edit" | "new"
type PostReEditPageProps = {
    editing: PostEditing
    onDone: () => void
    okText?: string
}
type Section = { label: string, value: number }
type PostReEditPageState = {
    sending: boolean
    sections: Section[]

    sectionId?: number
    title?: string
    content?: string
}

/**重新编辑文章
 * 
 * 默认以弹窗出现 */
export class PostReEditPage extends PureComponent<PostReEditPageProps, PostReEditPageState> {
    constructor(props: PostReEditPageProps) {
        super(props);
        this.state = {
            sending: false,
            sections: []
        }
    }
    render() {
        console.log("PostReEditPage", this.props)
        const okText = this.props.okText || this.props.editing?.behavior === "new" ? "发布主题" : "更新主题"
        const Selector = <Select placeholder="选择版块" value={this.state.sectionId} options={this.state.sections} style={{ width: 120, }} onChange={this.onSectionChange.bind(this)} />
        return <Provider_LOGIN_STATE.Consumer>
            {CONTEXT => {
                return <div className="post-container src\pages\discussions\post\reedit\reEditPost.tsx">
                    <div className="common-header2"></div>
                    <div className={"PostModalReEdit discussions_post_index-main"}>
                        <div id="post_content_main" >
                            <sub>
                                <Progress type="circle" width={64} percent={0}
                                    format={() => <Link to={{ pathname: `/user/${CONTEXT.id }`, query: { "uid": CONTEXT.id } }}>
                                        <Avatar src={CONTEXT.avatar.href} size={50} />
                                    </Link>} />
                                <Tag style={{ margin: 5 }}>
                                    <span>{CONTEXT.userName}</span></Tag>
                                <Tag style={{ margin: 5 }}>
                                    <span>{CONTEXT.tiers}</span></Tag>
                            </sub>
          
                            <section>
                                {typeof this.state.title !== "undefined" ?
                                    <Input
                                        maxLength={80}
                                        showCount={true}
                                        placeholder="主题标题"
                                        value={this.state.title}
                                        onChange={this.onTitleChange.bind(this)}>
                                    </Input> :
                                    <Skeleton.Button />
                                }
                                <span style={{ display: "block" }}>{this.props.editing ?
                                    (this.props.editing.behavior === "new" ? Selector : "") :
                                    <Skeleton.Button />}
                                </span>


                                {this.state.content ?
                                    <GeneralEditor
                                        defalutContent={this.state.content}
                                        feature={"full"}
                                        okText={okText}
                                        onDone={this.sendPost.bind(this)}
                                    /> : <Skeleton active />
                                }


                            </section>
                        </div>
                    </div>
                </div>
            }}
        </Provider_LOGIN_STATE.Consumer>
    }

    async componentDidMount() {
        this.setSectionData()
        if (this.props.editing?.behavior === "edit") {
            this.getPostData()
        }
     
    }
    private onTitleChange(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({ title: event.target.value })
    }
    private onSectionChange(newVlue: number) {
        this.setState({ sectionId: newVlue })
    }

    async setSectionData() {
        const sections = Array.from((await getForumSection()).data || [], (item,) => {
            return { label: item.name, value: item.id }
        })
        this.setState({
            sections
        })
    }
    async getPostData() {
        const editing = this.props.editing
        if (!editing) { return }
        if (editing.behavior === "edit") {
            const response = (await getPostDetail(editing.postId)).data
            this.setState({
                sectionId: response.section_id,
                title: response.title,
                content: response.content,
            })
        }
    }
    async sendPost(html: string) {
        const state = this.state
        const editing = this.props.editing
        if (!state.title) { return }
        if (!state.sectionId) { return }
        if (!editing) { return }
        switch (editing.behavior) {
            case "edit":
                this.setState({ sending: true })
                await editPost({
                    postId: editing.postId,
                    title: this.state.title || "未指定标题",
                    content: html,
                    section: state.sectionId
                })
                this.setState({ sending: false })

                message.info("修改完成")
                umiHistory.push(`/discussions/post/${editing.postId}`)
                break;
            case "new":
                // await addForumComment({
                //     "post_id": editing.postId,
                //     "comment": html,
                //     "replyto_uid": editing.replyToUserId,
                //     "parent_cid": editing.commentId,
                // })
                // message.info("创建完成")
                break;

            default:
                break;
        }
        this.props.onDone()
    }
}
