import { Space, Typography, Modal } from 'antd';
import { PureComponent} from 'react';
import "./index.less";
import { PrivacyPolicy, UserAgreement, InformationReleaseManagementRules} from "../../layouts/textList/text"

const { Link } = Typography;

type State = {
  voted?: boolean
  ModalVisible?: boolean
  modalType?: string
  modalTitle?: string
}

class BottomFooter extends PureComponent<{}, State> {
  constructor(props: {}) {
    super(props);
    this.state = {
        ModalVisible: false,
        modalType: '',
        modalTitle: '',
    };
  }
  onVote() {
    this.setState({
      voted: !this.state.voted
    })
  }

  StyleBase: React.CSSProperties = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: '#666666',
    padding: 10,
    borderRadius: "100%",
    fontSize: 12,
  }
  renderAuthButton = ()=>{
    if(this.state.modalType === 'PrivacyPolicy'){
      return <PrivacyPolicy></PrivacyPolicy>
    } else if (this.state.modalType === 'UserAgreement'){
      return <UserAgreement></UserAgreement>
    } else if(this.state.modalType === "Information"){
      return <InformationReleaseManagementRules></InformationReleaseManagementRules>
    }
  }
  hidePopup() {
    this.setState({
      ModalVisible: false
    })
  }
  render() {
    return <>
      <div className={`box-right-align-bottom  ${this.props.name}`}>
            <div className="box-left">
            <span>
            Copyright 2015-2022 九坤投资 (北京) 有限公司版权所有 京ICP 备案 15026595号
            </span>
            </div>
            <div className="box-right box-right-align">
                <Space size="large">
                {/* <Link  onClick={()=> {this.setState({ModalVisible: true, modalType: "InstructionsContestants",modalTitle: '参赛选手须知'})}}>
                    参赛选手须知
                </Link> */}
                <Link  onClick={()=> {this.setState({ModalVisible: true, modalType: "PrivacyPolicy",modalTitle: '隐私政策'})}}>
                隐私政策
                </Link>
                <Link  onClick={()=> {this.setState({ModalVisible: true, modalType: "UserAgreement",modalTitle: '用户协议'})}}>
                    用户协议
                </Link>
                <Link  onClick={()=> {this.setState({ModalVisible: true, modalType: "Information",modalTitle: '信息发布与管理规则'})}}>
                    信息发布与管理规则
                </Link>
                </Space>
            </div>
        </div>
      
      <Modal
          title={this.state.modalTitle}
          centered
          visible={this.state.ModalVisible}
          // onOk={() => this.hidePopup()}
          width={800}
          cancelText={'取消'} okText={"确认"} 
          className={'modalStyle'}
          onCancel={()=>this.hidePopup()}
          destroyOnClose={true}
          footer={null}
        >
        {this.renderAuthButton()}
      </Modal>
    </>
  }
}

export default BottomFooter
