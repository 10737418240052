import { GENERAL_ALLOWED_IMAGE_TYPES } from '@/config/GeneralConfig';
import { PictureOutlined } from '@ant-design/icons';
import { Button, message, Spin, Upload } from 'antd';
import BraftEditor, {
    ControlType as BraftEditorControlType, ExtendControlType as BraftEditorExtendControlType, HooksType as BraftEditorHooksType
} from 'braft-editor';
//@ts-ignore BraftEditor 就是没有类型的
import { ContentUtils } from 'braft-utils';
import { PureComponent } from 'react';
import { AntdUploadCustomRequestEvent, AntdUploadImage } from "../../functions/upload/image";
import "./index.less"
import "./content.less"

type GeneralEditorState = {
    editorState?: ContentUtils //反正都是any
    sending?: boolean
    previewVisible?: boolean
    previewImage?: string

    focused?: boolean
}
type GeneralEditorProps = {
    expandOnClick?: boolean
    defalutContent: string
    feature?: "full" | "limit"
    okText?: string
    onDone?: (html: string) => Promise<any>
    onref?: (html: string) => Promise<any>
}
export class GeneralEditor extends PureComponent<GeneralEditorProps, GeneralEditorState> {

    constructor(props: GeneralEditorProps) {
        super(props);
        this.state = {
            editorState: BraftEditor.createEditorState(props.defalutContent || ""),
            sending: false,
        };
    }
    render() {
        const classList = ["GeneralEditor"]
        if (this.state.focused && this.props.expandOnClick) {
            classList.push("focused")
        }
        const fontSizes = [
            12, 14, 16, 18, 20, 24,
            28, 30, 32, 36, 40, 48,
            56, 64, 72, 96, 120, 144
        ]
        //@ts-ignore BraftEditor 的异常 
        const Editor = <BraftEditor
            value={this.state.editorState}
            className={classList.join(" ")}
            placeholder={"发布您的评论"}
            fontSizes={fontSizes}
            extendControls={this.BraftEditorExtendControls}
            controls={this.props.feature === "limit" ? LIMITED_BRAFT_EDITOR_CONTROLS : undefined}
            hooks={this.BraftEditorHooks}
            onChange={this.handleEditorChange}
        />
        return <Spin spinning={this.state.sending} >
            <div onFocus={() => { this.setState({ focused: true }) }}>
                {Editor}
                <div style={{ textAlign: "right", padding: 10 }}>
                    <Button
                        disabled={!this.props.onDone}
                        type="primary"
                        loading={this.state.sending}
                        onClick={this.sendPost.bind(this)}
                    >
                        {this.props.okText || "发布"}

                    </Button>
                </div>
            </div>
        </Spin>

    }
    BraftEditorHooks: BraftEditorHooksType = {
        'toggle-link': ({ href, target }) => {
            href = href.indexOf('http') === 0 ? href : `http://${href}`;
            // target = '_blank'
            return { href, target }
        }
    }
    BraftEditorExtendControls: BraftEditorExtendControlType[] = [
        {
            key: 'antd-uploader',
            type: 'component',
            component: (
                <Upload
                    accept={GENERAL_ALLOWED_IMAGE_TYPES.join(",")}
                    showUploadList={false}
                    customRequest={this.uploadImg.bind(this)}
                >
                    <button type="button" className="control-item button upload-button" data-title="插入图片">
                        <PictureOutlined />
                    </button>
                </Upload>
            )
        }
    ]

    async uploadImg(event: AntdUploadCustomRequestEvent) {
        console.log("uploadImg", event)
        const result = await AntdUploadImage(event)
        this.setState({
            editorState: ContentUtils.insertMedias(this.state.editorState, [{
                type: 'IMAGE',
                url: result?.url.href
            }])
        })

        return result
    }

    async sendPost() {
        const html: string = this.state.editorState.toHTML() || ""
        if (html === '<p></p>') {
            message.warn('请先输入内容')
            return
        }
        this.setState({ sending: true })

        if (this.props.onDone) {
            await this.props.onDone(html)
        }

        this.setState({ sending: false })

    }
    handleEditorChange = (editorState: any) => {
        this.setState({ editorState })
    }
    clearContent() {
        this.setState({ editorState: BraftEditor.createEditorState("") })
    }
    componentDidMount() {
        this.props.onref && this.props.onref(this)
    }

}

const LIMITED_BRAFT_EDITOR_CONTROLS: BraftEditorControlType[] = [
    'font-size',
    'separator',
    'text-color', 'bold', 'italic', 'underline',
    'separator',
    'emoji', 'separator',
    'list-ul', 'list-ol', 'blockquote', 'code',
    'separator',
    'link', 'separator', 'hr',
]
