import { v4 as uuidv4 } from 'uuid';
import { syntheticURL } from "@/utils/request"
import { message } from 'antd';

type EventListenerBase<T extends string, D> = { type: T, "data": D, GID: number }
type UnreadChange = EventListenerBase<"unreadChange", number>

export type NotifyEvents = UnreadChange
let eventListeners: {
    id: string
    token: string
    func: (event: NotifyEvents) => void
    str: string
}[] = []

const supportSharedWorker = "SharedWorker" in globalThis
const supportWorker = "Worker" in globalThis
var myWorker: SharedWorker | Worker | undefined
if (supportSharedWorker) {
    myWorker = new SharedWorker("/workers/index.js?v=13");
    myWorker.port.start();
} else if (supportWorker) {
    myWorker = new Worker("/workers/index.js?v=13");
}

export const addNotifyEventListener = async (callback: (event: NotifyEvents) => void): Promise<string> => {
    if (!myWorker) {
        message.error("浏览器已阻止部分功能的使用")
        throw "!myWorker"
    }
    const listenerId = uuidv4();
    const token = startService(myWorker)
    if ("port" in myWorker) {
        myWorker.port.start();
    }

    eventListeners.push({
        id: listenerId,
        token,
        func: callback,
        str: callback.toString()
    })

    return listenerId
}
export const removeNotifyEventListener = async (listenerId: string) => {
    for (let listenerListKey in eventListeners) {
        if (eventListeners[listenerListKey].id === listenerId) {
            eventListeners[listenerListKey].func = () => { }
        }
    }
}

const startService = (myWorker: SharedWorker | Worker): string => {
    const port = "port" in myWorker ? myWorker.port : myWorker
    const token = localStorage.getItem("access-token")?.split(" ").pop() || ""
    const url = syntheticURL(`/uf_api/ping/check?token=`, "http://18.167.77.197:30000")


    port.onmessage = (event) => {
        Array.from(eventListeners, listener => {
            const data = event.data as { data: NotifyEvents, token: string }
            const json = data.data as NotifyEvents
            console.error("onmessage",event)
            if (token && token === listener.token) {
                console.error("token !匹配，期望", token, " ，得到", listener.token, "")

            console.error("calling",listener)

                listener.func(json)
            } else {
                console.error("token 不匹配，期望", token, " ，得到", listener.token, "")
            }
        })
    }





    port.postMessage({
        action: "addListener", data: {
            token: token,
            urlPrefix: url
        },
    });

    return token
}
