import { Provider_LOGIN_STATE } from '@/layouts/beforeRender/loginState';
import { PostDetail } from '@/types/post';
import { PureComponent } from 'react';
import { getPostDetail } from "../../../services/forum";
import './global.less';
import './index.less';
import { PostForEdit, PostReEditPage } from "./reedit/reEditPost";


type State = {
  detail?: PostDetail
  editing?: PostForEdit
}

class Page extends PureComponent<{}, State> {
  readonly pid: number
  constructor(props: {}) {
    super(props);

    this.pid = Number(document.location.pathname.split("/")[3])

    this.state = {
    };
  }

  render() {
    const detail = this.state.detail;

    return <Provider_LOGIN_STATE.Consumer>
      {CONTEXT => {
        if (detail && CONTEXT.uid !== detail?.author.id) {
          return "你无权访问此页面"
        }
        return <PostReEditPage
          editing={{ behavior: "edit", postId: this.pid }}
          onDone={() => { }}
        />
      }}
    </Provider_LOGIN_STATE.Consumer>

  }
  async componentDidMount() {
    // const str = history.location.pathname.split("/")[3];
    // const tid = str.split('?')[0];


    // const response = await getPostDetail(this.pid)
    // const item = response.data
    // this.setState({ detail: item })

    // setTimeout(() => {
    //   this.setState({
    //     editing: {
    //       behavior: "edit",
    //       content: item.content,
    //       postId: item.id,
    //       title: item.title,
    //       sectionId: item.section_id,
    //     }
    //   })
    // }, 0);


  }



  async onDone() {
    console.log("onDone",)
    document.location.replace(`/discussions/post/${this.state.detail?.id}`)
  }
}


export default Page
