const gatewayPrefix = 'https://anyrelay.bootdev.workers.dev/-----';
const envAPIPrefix = 'https://ubiquant.forum.yubis.net';

export { gatewayPrefix, envAPIPrefix };

export type UFConfig = {
    "api_url": string
    "domain_prefix_add": boolean
    "auth_mode": "jwt" | "cookie"
}

export const getUFConfig = (): UFConfig => {
    //@ts-ignore
    return UF_CONFIG
}

export const ufURL = (path: string): URL => {
    const config = getUFConfig()
    return new URL(`${config.api_url}${path}`)
}