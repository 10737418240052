import { Col, Row, Skeleton } from 'antd';
import { PureComponent } from 'react';

import { getPostList, UserActivityDataAtDiscussion, UserPublicInfo } from '@/services/user';
import { List } from 'antd';
import moment from 'moment';
import { history as umiHistory } from 'umi';




type UserBillboard_ActivityProps = {
    uid: number
    userInfo?: UserPublicInfo
}
type UserBillboard_ActivityState = {
    activity?: UserActivityDataAtDiscussion[]
}

export class UserBillboard_Activity extends PureComponent<UserBillboard_ActivityProps, UserBillboard_ActivityState> {
    constructor(props: UserBillboard_ActivityProps) {
        super(props);
        this.state = {

        };
    }
    render() {
        const userInfo = this.props.userInfo
        const activity = this.state.activity
        return <div className="UserBillboard_Activity base-info">
            <div className="baseInfo-header">
                {"论坛活跃"}
            </div>
            <div className='awardInfo_self' style={{ textAlign: "center" }}>
                <div className="text">排名</div>
                {!userInfo ? <Skeleton.Button active /> : <>
                    <div className="text rankNumber">{userInfo.forum_range_array?.position || '-'}</div>
                    <div className="text number1">{userInfo.forum_range_array?.total || '-'}</div>
                </>}


            </div>
            <Row className="row_top3">
                <Col flex={2} style={{ display: 'flex', alignItems: "center", justifyContent: 'center' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: "center" }}>
                        <img src="/assets/images/goldl@2x.png" style={{ marginBottom: 5 }} height={"20px"} />
                        {!userInfo ? <Skeleton.Button active size='small' /> : userInfo.dis_awards?.gold || '-'}
                    </div>
                </Col>
                <Col flex={2} style={{ display: 'flex', alignItems: "center", justifyContent: 'center' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: "center" }}>
                        <img src="/assets/images/silverl@2x.png" style={{ marginBottom: 5 }} height={"20px"} />
                        {!userInfo ? <Skeleton.Button active size='small' /> : userInfo.dis_awards?.silver || '-'}
                    </div>
                </Col>
                <Col flex={2} style={{ display: 'flex', alignItems: "center", justifyContent: 'center' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: "center" }}>
                        <img src="/assets/images/bronzel@2x.png" style={{ marginBottom: 5 }} height={"20px"} />
                        {!userInfo ? <Skeleton.Button active size='small' /> : userInfo.dis_awards?.bronze || '-'}
                    </div>
                </Col>
            </Row>
            <List
                className="list_"
                itemLayout="vertical"
                size="large"
                pagination={{
                    pageSize: 8,
                    hideOnSinglePage: true,
                    showSizeChanger: false
                }}
                dataSource={activity}
                footer={null}
                renderItem={item => {
                    const isComment = item.type === "comment"
                    const award = typeof item.award === "object" ? item.award : undefined
                    return <a href={this.getURL(item)} onClick={(event) => this.itemClicked(event, item)}>
                        <Row style={{ padding: '15px 30px', flexWrap: 'nowrap', cursor: 'pointer' }}>
                            <Col span={18} style={{ display: 'flex', alignItems: "center" }}>
                                <div style={{ display: 'flex', flexDirection: 'column', wordBreak: 'break-all' }} >
                                    <div className="title_">
                                        {"title" in item ? item.title : item.post_title}
                                    </div>
                                    <div>
                                        {award?.metal_type === 1 ? <img src="/assets/images/goldl@2x.png" style={{ marginBottom: 5, marginRight: 5 }} height={"18px"} /> : ""}
                                        {award?.metal_type === 2 ? <img src="/assets/images/silverl@2x.png" style={{ marginBottom: 5, marginRight: 5 }} height={"18px"} /> : ""}
                                        {award?.metal_type === 3 ? <img src="/assets/images/bronzel@2x.png" style={{ marginBottom: 5, marginRight: 5 }} height={"18px"} /> : ""}
                                        <span className="timeCa">
                                            {isComment ? "评论于" : "发表于"} {" "}
                                            {moment(item.create_date).format('YYYY-MM-DD')}
                                        </span>
                                    </div>
                                </div>
                            </Col>
                            <Col span={6} style={{ display: 'flex', alignItems: "center", justifyContent: 'flex-end' }}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <span className="topNumber">{item.like_count}</span> <span className="right_text">{"点赞数"}</span>
                                </div>
                            </Col>
                        </Row>
                    </a>
                }}
            />
        </div>
    }
    async componentDidMount() {
        const response = await getPostList({ uid: this.props.uid })
        console.log("getPostList", response)
        this.setState({ activity: response.data })
    }
    itemClicked(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, item: UserActivityDataAtDiscussion) {
        event.preventDefault()
        umiHistory.push(this.getURL(item))
    }
    getURL(item: UserActivityDataAtDiscussion) {
        if (item.type === "comment") {
            return `/discussions/post/${item.post_id}`
        } else {
            return `/discussions/post/${item.id}`
        }
    }

}
