import { PureComponent } from 'react';
import { Menu as AntdMenu, Button, Input, Avatar, Drawer } from 'antd';
import styles from './index.less';
import '../global.less';
import { MENU_CONFIG } from '../../config/menu';
import { Icon } from '../../functions/IconLab';
import { history } from 'umi';
import { Link } from 'umi';
import { MenuInfo } from 'rc-menu/lib/interface';
import React from 'react';
import { Provider_LOGIN_STATE } from '../beforeRender/loginState';
import { FlashingLOGO } from '@/component/FlashingLOGO';

const { SubMenu } = AntdMenu;
// const ThemeContext = React.createContext('light');


type MenuProps = {
  mode: "horizontal" | "inline"
  onWidthChange: (newWidth: number) => void
};
type MenuState = {
  menuHide: boolean;
};
export class Menu extends PureComponent<MenuProps, MenuState> {
  constructor(props: MenuProps) {
    super(props);
    this.state = {
      menuHide: false,
    };
  }
  static contextType = Provider_LOGIN_STATE;
  leftLogoWidth = 180;

  logo = <div style={{ display: 'inline-block' }}>
  <FlashingLOGO />
</div>
  width1 = 80
  width2 = 260


  componentDidMount() {
    let syncState = () => {
      const state = this.state.menuHide
      if (typeof (this.props.onWidthChange) === "function") {
        this.props.onWidthChange(state ? this.width1 : this.width2)
      }else{
        console.error("syncState failed")
      }
    }
    syncState()
  }


  render() {

    const style1 = {
      width: `calc(100% - ${this.leftLogoWidth}px)`,
    }
    const style2 = {
      height: '100%',
      width: this.state.menuHide ? this.width1 : this.width2,
    }
    const InlineLOGOArea = <div
      style={{
        height: 48,
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        overflow: 'hidden',
        // justifyContent: inlineCollapsed ? "center" : "flex-start"
      }}
    >
      <div
        style={{ display: 'inline-block', minWidth: 80, textAlign:'center' }}
        onClick={() => {
          const nextState = !this.state.menuHide
          if (typeof (this.props.onWidthChange) === "function") {
            this.props.onWidthChange(nextState ? this.width1 : this.width2)
          }else{
            console.error("onWidthChange failed")
          }

          this.setState({ menuHide: nextState });
        }}
      >
        {this.state.menuHide ? <Icon id={"Icon_nav_inside"} /> : <Icon id={"MenuFoldOutlined"} />}
      </div>
      <span
        style={{ opacity: this.state.menuHide ? 0 : 1 }}
      >
        {this.logo}
      </span>
    </div>

    let currentActiveKey = ""
    const location = history.location.pathname

    Array.from(MENU_CONFIG, (item, id) => {
      const itemPath = item.path?.toLocaleLowerCase()

      if (itemPath) {
        if (location.toLocaleLowerCase().startsWith(itemPath)) {
          currentActiveKey = String(id)
        }
      }
    })

    console.log("this.context", this.context)
    return (
      <>

        {this.props.mode === "inline" ? InlineLOGOArea : ""}
        <AntdMenu
          className={'layouts/frontend/left/heder/menu'}
          mode={this.props.mode}
          style={this.props.mode === "inline" ? style2 : style1}
          // defaultSelectedKeys={['1']}
          // defaultOpenKeys={['sub1']}
          selectedKeys={[currentActiveKey]}
          inlineCollapsed={this.state.menuHide}
        >

          {Array.from(MENU_CONFIG, (item, id) => {
            const push = (href?: string) => {
              console.debug("pushing", href)
              href ? history.push(href) : ""
              this.forceUpdate()
            }
            //**暂时没有子菜单 */
            // if (item.routes) {
            //   return <SubMenu key={id} icon={<Icon id={item.icon} />} title={'更多'}>
            //     {Array.from(item.routes, (childItem, childId) => {
            //       return <AntdMenu.Item onClick={() => { push(item.href) }}
            //         key={`${id}-_-${childId}`}>
            //         <Link to={item.href || "/404"}>
            //           {childItem.title}
            //         </Link>
            //       </AntdMenu.Item>
            //     })}
            //   </SubMenu>
            // }
            
                // 项目管理 自定义按钮
                if (id === 5) {
                  return <>
                    
                  </>
                }
            return <AntdMenu.Item key={id} onClick={() => { push(item.href) }}
              icon={<Icon id={item.icon[0]} />}>
              <Link to={item.href || "/404"}>
                <div style={{paddingLeft:4}}>
                {item.title}
                </div>
              </Link>
            </AntdMenu.Item>

          })}
        </AntdMenu>
      </>
    );
  }
}


export default AntdMenu;
