import request from '@/utils/request';
import { API } from './default';



// 上传文件(图片)
export async function API_uploadImage(data: ImageFile, encodedFileName: string): Promise<API<ContentImage>> {
  return request('/uf_file/upload/node/post/content_image',
    {
      method: 'post',
      headers: {
        "Content-Type": `application/octet-stream`,
        "Content-Disposition": `file; filename="${encodedFileName}"`
      },
      data,
    });
}



type ImageFile = Blob | ArrayBuffer

export interface Fid {
	value: number;
}

export interface Uuid {
	value: string;
}

export interface Langcode {
	value: string;
}

export interface Uid {
	target_id: number;
	target_type: string;
	target_uuid: string;
	url: string;
}

export interface Filename {
	value: string;
}

export interface Uri {
	value: string;
	url: string;
}

export interface Filemime {
	value: string;
}

export interface Filesize {
	value: number;
}

export interface Statu {
	value: boolean;
}

export interface Created {
	value: string;
	format: string;
}

export interface Changed {
	value: string;
	format: string;
}

export interface ContentImage {
	fid: Fid[];
	uuid: Uuid[];
	langcode: Langcode[];
	uid: Uid[];
	filename: Filename[];
	uri: Uri[];
	filemime: Filemime[];
	filesize: Filesize[];
	status: Statu[];
	created: Created[];
	changed: Changed[];
}
