import { history } from 'umi';
import { IconKey } from "../functions/IconLab"

export type ChildrenConfigItem = {
    startWith: string;
    description?: string;
    title: string;
    buttonText?: string;
    buttonIcon?: IconKey;
    buttonPosition: "bottom" | "right" | "none";
    buttonType?: "primary" | "text";
    buttonHref?: string;
    hide?:boolean
}

type ChildrenConfig = ChildrenConfigItem[];

const GeneralChildrenConfig: ChildrenConfig = [
    {
        startWith: '/',
        title: "默认title",
        description: "默认description",
        buttonText: "默认buttonText",
        buttonPosition: "bottom"
    },
    {
        startWith: '/discussions',
        title: "论坛",
        description: "In the process of internal desktop applications development",
        buttonText: "你的讨论",
        buttonPosition: "bottom",
        hide:true
    },
    {
        startWith: '/discussions/zone',
        title: "通用板块",
        description: "Cost of design differences and implementation and liberate the resources2",
        buttonText: "新建主题",
        buttonIcon: "PlusOutlined",
        buttonPosition: "right",
        buttonType:"primary",
        buttonHref:"/discussions/new",
        hide:true
    },
    {
        startWith: '/discussions/post',
        title: "[TITLE_POST_TITLE]",
        // description: "Cost of design differences and implementation and liberate the resources",
        buttonText: "新建话题",
        buttonIcon: "PlusOutlined",
        buttonPosition: "right",
        buttonHref:"/discussions/new",
        hide:true
    },
    {
        startWith: '/competition',
        title: "比赛",
        description: "Cost of design differences and implementation and liberate the resources",
        // buttonText: "新建话题",
        // buttonIcon: "PlusOutlined",
        buttonPosition: "none",
    },
    // {
    //     startWith: '/discussions/post',
    //     title: "通用板块",
    //     // description: "Cost of design differences and implementation and liberate the resources",
    //     buttonText: "新建话题",
    //     buttonIcon: "PlusOutlined",
    //     buttonPosition: "right",
    // },
];

const getCurrentChildrenConfig = function (location?: string) {
    if (!location) {
        location = history.location.pathname.toLocaleLowerCase()
    }
    
    let lastPareWeights = 0
    let result: ChildrenConfigItem = GeneralChildrenConfig[0]
    for (let item of GeneralChildrenConfig) {
        let startWith = item.startWith.toLocaleLowerCase()
        if (location.startsWith(startWith)) {
            lastPareWeights = startWith.length
            result = item
        }
    }
    return result
}

export { GeneralChildrenConfig, getCurrentChildrenConfig };
