import { Table } from 'antd';
import { PureComponent } from 'react';
import './index.less';
import { MathComponent } from 'mathjax-react';
import MathJax from 'react-mathjax';
type State = {
}


class Record extends PureComponent<{}, State>  {
    state = {};
    // componentDidMount() {
    //     // window.addEventListener('scroll', this.handleScroll)
    //     if (MathJax.isMathjaxConfig) {
    //         // 判断是否初始配置，若⽆则配置。
    //         MathJax.initMathjaxConfig()
    //     }
    //     MathJax.TypeSet()
    // }
    // componentDidUpdate() {
    //     if (MathJax.isMathjaxConfig) {
    //         // 判断是否初始配置，若⽆则配置。
    //         MathJax.initMathjaxConfig()
    //       }
    //       MathJax.TypeSet()
    // }
    render() {
        return (
            <div className={"record"}>
                <div id="site-content" className={"sc-iuImSO iOUWdo"}>
                    <div id="site-body" >

                        <div className={"about-rankings"}>
                            <div className={"about-rankings__banner"}>
                                <div className={"about-rankings__content-header"}>
                                    <div className={"about-rankings__content-header-title-wrapper"}>
                                        <h1 className={"about-rankings__content-header-heading"}>
                                        积分规则&活跃评级系统
                                        </h1>

                                        <div className={"about-rankings__content-header-body"}>
                                        九坤赛事平台的排名积分规则，用于给每位参赛选手、论坛活跃用户以多个方面多个维度的积分、排名、与评级反馈。通过积极参加比赛、论坛活跃、您可以获得对应的成就解锁评级，并提升全站的排行名次哦！
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={"about-rankings__section-header"}>
                                <h2>积分维度</h2>
                                <div className={"about-rankings__header-description"}>
                                    我们将分别以您的<strong>参赛经历</strong>与<strong>论坛活跃</strong>两个维度，分别维护您的对应积分。两个积分对应两个全站排行榜。积极参赛并获得优秀成绩，将逐步提升您在<strong>赛事排行</strong>中的排名。在论坛中积极活跃发言回复、点赞等，将提升您在<strong>论坛活跃排行</strong>中的排名。
                                </div>
                                <h2>评级等级</h2>
                                <div className={"about-rankings__header-description"}>
                                    我们一共提供5个等级的成就评级，根据您在本平台中的综合表现，您可以逐步解锁
                                    <span className={"about-rankings__header-description--novice"}>星际漫游者、</span>
                                    <span className={"about-rankings__header-description--contributor"}>星际宇航员、</span>
                                    <span className={"about-rankings__header-description--expert"}>星际任务专家、</span>
                                    <span className={"about-rankings__header-description--master"}>星际飞行员</span>和
                                    <span className={"about-rankings__header-description--grandmaster"}>星际指挥官。</span>
                                    在评级等级的解锁条件中，您只需<strong>任意达成一个条件</strong>即可解锁对应的评级等级。
                                </div>


                                    <div className={"about-rankings__section-item about-rankings__section-item--novice"}>
                                        <div className={"about-rankings__section-item-wrapper"}>
                                            <div className={"about-rankings__section-image"}>
                                                <img src={"/genIcon/v1.svg"} width={"96px"}/>
                                            </div>
                                            <div className={"about-rankings__tier-description"}>
                                                <h2 className={"about-rankings__tier-name about-rankings__tier-name--novice"}>
                                                    星际漫游者
                                                </h2>
                                                <div className={"about-rankings__requirements"}>
                                                    <div className={"about-rankings__requirements-grid about-rankings__requirements-grid--non-category"}>
                                                            <div>
                                                                    <div className={"about-rankings__requirement-wrapper"}>
                                                                        <div className={"about-rankings__requirement about-rankings__requirement--non-category"}>
                                                                        注册加入九坤量化赛事平台
                                                                        </div>
                                                                    </div>
                                                            </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div className={"about-rankings__section-item about-rankings__section-item--novice"}>
                                        <div className={"about-rankings__section-item-wrapper"}>
                                            <div className={"about-rankings__section-image"}>
                                                <img src={"/genIcon/v2.svg"} width={"96px"}/>
                                            </div>
                                            <div className={"about-rankings__tier-description"}>
                                                <h2 className={"about-rankings__tier-name about-rankings__tier-name--novice"}>
                                                星际宇航员</h2>
                                                <div className={"about-rankings__requirements"}>
                                                    <div className={"about-rankings__requirements-grid about-rankings__requirements-grid--non-category"}>
                                                            <div>
                                                                    <div className={"about-rankings__requirement-wrapper"}>
                                                                        <div className={"about-rankings__requirement about-rankings__requirement--non-category"}>
                                                                        参加一场比赛
                                                                        </div>
                                                                        <div className={"about-rankings__requirement about-rankings__requirement--non-category"}>
                                                                        在论坛中发表一次回复
                                                                        </div>
                                                                        <div className={"about-rankings__requirement about-rankings__requirement--non-category"}>
                                                                        给某个主题点赞一次
                                                                        </div>
                                                                    </div>
                                                            </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div className={"about-rankings__section-item about-rankings__section-item--novice"}>
                                        <div className={"about-rankings__section-item-wrapper"}>
                                            <div className={"about-rankings__section-image"}>
                                                <img src={"/genIcon/v3.svg"} width={"96px"}/>
                                            </div>
                                            <div className={"about-rankings__tier-description"}>
                                                <h2 className={"about-rankings__tier-name about-rankings__tier-name--novice"}>
                                                    <font style={{verticalAlign: "inherit"}}>星际任务专家</font></h2>
                                                <div className={"about-rankings__requirements"}>
                                                    <div className={"about-rankings__requirements-grid about-rankings__requirements-grid--non-category"}>
                                                            <div>
                                                                    <div className={"about-rankings__requirement-wrapper"}>
                                                                        <div className={"about-rankings__requirement about-rankings__requirement--non-category"}>
                                                                        在参赛经历中获得2块铜牌
                                                                        </div>
                                                                        <div className={"about-rankings__requirement about-rankings__requirement--non-category"}>
                                                                        在论坛中获得50块铜牌
                                                                        </div>
                                                                    </div>
                                                            </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div className={"about-rankings__section-item about-rankings__section-item--novice"}>
                                        <div className={"about-rankings__section-item-wrapper"}>
                                            <div className={"about-rankings__section-image"}>
                                                <img src={"/genIcon/v4.svg"} width={"96px"}/>
                                            </div>
                                            <div className={"about-rankings__tier-description"}>
                                                <h2 className={"about-rankings__tier-name about-rankings__tier-name--novice"}>
                                                    <font style={{verticalAlign: "inherit"}}>星际飞行员</font></h2>
                                                <div className={"about-rankings__requirements"}>
                                                    <div className={"about-rankings__requirements-grid about-rankings__requirements-grid--non-category"}>
                                                            <div>
                                                                    <div className={"about-rankings__requirement-wrapper"}>
                                                                        <div className={"about-rankings__requirement about-rankings__requirement--non-category"}>
                                                                        在参赛经历中获得1块金牌
                                                                        </div>
                                                                        <div className={"about-rankings__requirement about-rankings__requirement--non-category"}>
                                                                        在参赛经历中获得2块银牌
                                                                        </div>
                                                                        <div className={"about-rankings__requirement about-rankings__requirement--non-category"}>
                                                                        在论坛中获得50块银牌
                                                                        </div>
                                                                        <div className={"about-rankings__requirement about-rankings__requirement--non-category"}>
                                                                        在论坛中总共获得200块奖牌
                                                                        </div>
                                                                    </div>
                                                            </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className={"about-rankings__section-item about-rankings__section-item--novice"}>
                                        <div className={"about-rankings__section-item-wrapper"}>
                                            <div className={"about-rankings__section-image"}>
                                                <img src={"/genIcon/v5.svg"} width={"96px"}/>
                                            </div>
                                            <div className={"about-rankings__tier-description"}>
                                                <h2 className={"about-rankings__tier-name about-rankings__tier-name--novice"}>
                                                    <font style={{verticalAlign: "inherit"}}>星际指挥官</font></h2>
                                                <div className={"about-rankings__requirements"}>
                                                    <div className={"about-rankings__requirements-grid about-rankings__requirements-grid--non-category"}>
                                                            <div>
                                                                    <div className={"about-rankings__requirement-wrapper"}>
                                                                        <div className={"about-rankings__requirement about-rankings__requirement--non-category"}>
                                                                        在参赛经历中获得5块金牌
                                                                        </div>
                                                                        <div className={"about-rankings__requirement about-rankings__requirement--non-category"}>
                                                                        单人参赛并获得1块金牌
                                                                        </div>
                                                                        <div className={"about-rankings__requirement about-rankings__requirement--non-category"}>
                                                                        在论坛中获得50块金牌
                                                                        </div>
                                                                        <div className={"about-rankings__requirement about-rankings__requirement--non-category"}>
                                                                        在论坛中总共获得500块奖牌
                                                                        </div>
                                                                    </div>
                                                            </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className={"about-rankings__section-header"}>
                                        <h2>奖牌</h2>
                                        <div className={"about-rankings__header-description"}>
                                        根据您的活跃表现，将对应在<strong>参赛经历</strong>与<strong>论坛活跃</strong>两个维度获得包含金牌、银奖、铜牌三种奖牌。每一枚奖牌都代表您在本平台中积极、活跃且优秀的表现！
                                        </div>
                                    </div>

                                    <div className={"about-rankings__section-item"}>
                                        <div className={"about-rankings__section-item-wrapper"}>
                                            <div className={"about-rankings__section-image"}>
                                                <div className={"about-rankings__medal-image"}>
                                                    <img src="/assets/images/bronzel@2x.png" height={"50px"} />
                                                </div>
                                                <div className={"about-rankings__medal-image"}>
                                                    <img src="/assets/images/silverl@2x.png" height={"50px"} />
                                                </div>
                                                <div className={"about-rankings__medal-image"}>
                                                    <img src="/assets/images/goldl@2x.png" height={"50px"} />
                                                </div>
                                            </div>
                                            <div>
                                                <h2 className={"about-rankings__category-name"}>比赛奖牌</h2>
                                                <div>比赛奖牌将在赛事结束并公布最终排行榜时，根据您的排行榜位置，获得对应的比赛奖牌。部分小型活动、练习赛等将不发放对应奖牌。</div>

                                                <div className={"about-rankings__table about-rankings__table--large"}>
                                                    <div className={"about-rankings__table-row"} style={{borderBottom: "1px solid #DEDFE0", marginBottom: "20px"}}>
                                                        <div className={"about-rankings__table-column"}>

                                                        </div>
                                                        <div className={"about-rankings__table-column"}>
                                                            0-99 队
                                                        </div>
                                                        <div className={"about-rankings__table-column"}>
                                                            100-249 队
                                                        </div>
                                                        <div className={"about-rankings__table-column"}>
                                                            250-999 队
                                                        </div>
                                                        <div className={"about-rankings__table-column"}>
                                                            1000+ 团队
                                                        </div>
                                                    </div>
                                                    <div className={"about-rankings__table-row"}>
                                                        <div className={"about-rankings__table-column"}>
                                                            <img src="/assets/images/bronzel@2x.png" height={"14px"} />铜牌
                                                        </div>
                                                        <div className={"about-rankings__table-column"}>
                                                            前40%
                                                        </div>
                                                        <div className={"about-rankings__table-column"}>
                                                            前40%
                                                        </div>
                                                        <div className={"about-rankings__table-column"}>
                                                            前100
                                                        </div>
                                                        <div className={"about-rankings__table-column"}>
                                                            前10％
                                                        </div>
                                                    </div>
                                                    <div className={"about-rankings__table-row"}>
                                                        <div className={"about-rankings__table-column"}>
                                                            <img src="/assets/images/silverl@2x.png" height={"14px"} />银牌
                                                        </div>
                                                        <div className={"about-rankings__table-column"}>
                                                            前20%
                                                        </div>
                                                        <div className={"about-rankings__table-column"}>
                                                            前20%
                                                        </div>
                                                        <div className={"about-rankings__table-column"}>
                                                            前50
                                                        </div>
                                                        <div className={"about-rankings__table-column"}>
                                                            前5％
                                                        </div>
                                                    </div>
                                                    <div className={"about-rankings__table-row"}>
                                                        <div className={"about-rankings__table-column"}>
                                                            <img src="/assets/images/goldl@2x.png" height={"14px"} />金牌
                                                        </div>
                                                        <div className={"about-rankings__table-column"}>
                                                            前10％
                                                        </div>
                                                        <div className={"about-rankings__table-column"}>
                                                            前10
                                                        </div>
                                                        <div className={"about-rankings__table-column"}>
                                                            前10 + 0.2%*
                                                        </div>
                                                        <div className={"about-rankings__table-column"}>
                                                            前10 + 0.2%*
                                                        </div>
                                                    </div>
                                                </div>

                                                <div>*（前10 + 0.2%）表示每增加500支参赛队伍，将额外获得一枚金牌。例如，500支队伍的比赛将向前11名的队伍颁发金牌，5000支队伍的比赛将向前20名的队伍颁发金牌。</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className={"about-rankings__section-item"}>
                                        <div className={"about-rankings__section-item-wrapper"}>
                                            <div className={"about-rankings__section-image"}>
                                                <div className={"about-rankings__medal-image"}>
                                                    <img src="/assets/images/bronzel@2x.png" height={"50px"} />
                                                </div>
                                                <div className={"about-rankings__medal-image"}>
                                                    <img src="/assets/images/silverl@2x.png" height={"50px"} />
                                                </div>
                                                <div className={"about-rankings__medal-image"}>
                                                    <img src="/assets/images/goldl@2x.png" height={"50px"} />
                                                </div>
                                            </div>
                                            <div>
                                                <h2 className={"about-rankings__category-name"}>论坛奖牌</h2>
                                                <div>
                                                您在论坛中发表的主题、发表的回复，当获得别人点赞或反对时，根据点赞人/反对人的评级对应的获得加、减分数。您的任意主题、回复达到如下净分数时，授予您对应的奖牌。
                                                </div>

                                                <div className={"about-rankings__table about-rankings__table--small"}>
                                                    <div className={"about-rankings__table-row"}>
                                                        <div className={"about-rankings__table-column"}>
                                                            <img src="/assets/images/bronzel@2x.png" height={"14px"} />铜牌
                                                        </div>
                                                        <div className={"about-rankings__table-column"}>
                                                        1分
                                                        </div>
                                                    </div>
                                                    <div className={"about-rankings__table-row"}>
                                                        <div className={"about-rankings__table-column"}>
                                                            <img src="/assets/images/silverl@2x.png" height={"14px"} />银牌
                                                        </div>
                                                        <div className={"about-rankings__table-column"}>
                                                        5分
                                                        </div>
                                                    </div>
                                                    <div className={"about-rankings__table-row"}>
                                                        <div className={"about-rankings__table-column"}>
                                                            <img src="/assets/images/goldl@2x.png" height={"14px"} />金牌
                                                        </div>
                                                        <div className={"about-rankings__table-column"}>
                                                            10分
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className={"about-rankings__section-item"}>
                                        <div className={"about-rankings__section-item-wrapper"}>
                                            <div>

                                                <div className={"about-rankings__leaderboards-paragraph"}>
                                                    <div>
                                                        <div className={"about-rankings__leaderboards-paragraph"}>
                                                            <div className={"about-rankings__category-name"}>赛事积分计算公式</div>
                                                            <p>您的<strong style={{fontWeight: "600"}}>赛事总积分</strong>是您参与的每场比赛获得积分的算术总和。每场比赛的积分，将根据您所在团队人数、排名情况、赛事权重以及所有参赛队伍数量的综合计算得出。*赛事的积分将按天衰减。</p>
                                                            {/* <img style={{"display":'block', margin: "0 auto"}}  src="/assets/images/math.png" width={'600px'}/> */}
                                                            {/* {<MathComponent tex={String.raw`\left[\frac{100000}{\sqrt{N_{\text{teammates}}}}\right]\left[\text{Rank}^{-0.75}\right]\left[\log_{10}( 1 + \log_{10}(N_{\text{teams}}))\right]\left[e^{-t/500}\right],`} />} */}
                                                            <MathJax.Provider>
                                                                <div>
                                                                    <MathJax.Node formula={'\\left[\\frac{100000}{\\sqrt{N_{\\text {teammates }}}}\\right]\\left[\\operatorname{Rank}^{-0.75}\\right]\\left[\\log _{10}\\left(1+\\log _{10}\\left(N_{\\text {teams }} \\times \\omega\\right)\\right)\\right]\\left[e^{-t / 500}\\right]'} />
                                                                </div>
                                                            </MathJax.Provider>

                                                            <p>N(teammates)：您所在队伍人数，取值>=1，如所在队伍人数为2，则N(teammates)=2</p>
                                                            <p>Rank：排名，取值>=1，如第一名则Rank=1</p>
                                                            <p>N(teams)：该场比赛总报名队伍数量，取值>=1，如总共有20支队伍参赛，则N(teams)=20</p>
                                                            <p>w：该场比赛的权重值，取值>0，由赛事主办方九坤投资根据每场赛事的性质对应配置。</p>
                                                            <p>t：天数，比赛结束日期距今的天数跨度</p>
                                                        </div>

                                                        <div className={"about-rankings__leaderboards-paragraph"}>
                                                            <div className={"about-rankings__category-name"}>论坛活跃积分计算说明</div>
                                                            <p>论坛活跃积分为您所有主题、回复的最终净得分的算术总和。并且将适用赛事积分相同的衰减函数。</p>
                                                            <p>
                                                                {/* <img style={{"display":'block', margin: "0 auto"}} src="/assets/images/shuaijianhanshu.png" width={"100px"} /> */}
                                                                {<MathComponent tex={String.raw`e^{-t/500}`} />}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                            </div>
                        </div>
                    </div></div></div>
                                    );
  }
}

                                    export default Record;