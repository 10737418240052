import { PureComponent } from 'react';


import { Select } from 'antd';
import "./index.less";
const { Option } = Select;

type Selection = {
  displays: React.ReactNode
  value: string
}


type Props<T> = {
  defaultValue?: T
  selection: Selection[]
  onChange: (selected: T) => void
}



export class Selector<T extends string = string> extends PureComponent<Props<T>> {
  constructor(props: Props<T>) {
    super(props);
  }
  render() {
    return <div className={"Selector"} style={{display:"inline-block"}}>
      <Select
       className={"Selector-main"}
        defaultValue={this.props.defaultValue}
        style={{ width: 256 }}
        bordered={false}
        getPopupContainer={triggerNode => (triggerNode.parentElement)}
        onChange={this.props.onChange}>
        {Array.from(this.props.selection, (item) => {
          return <Option value={item.value}>{item.displays}</Option>
        })}
      </Select>
    </div>
    
  }
}