import { CompassOutlined, LoadingOutlined } from '@ant-design/icons';
import { Menu, message, Spin } from 'antd';
import React, { PureComponent } from 'react';
import { history } from 'umi';
import { getUserInfo } from '../../services/user';
import '../global.less';
import { defaultLoginStateState, LoginState, Provider_LOGIN_STATE } from './loginState';
const antIcon = <LoadingOutlined style={{ fontSize: 48 }} spin />;
const antErrIcon = <CompassOutlined style={{ fontSize: 48 }} spin />;
const { SubMenu } = Menu;

type BeforeRenderState = {
  currentPath: string;
  ready: boolean;
  loginState: LoginState;
  serviceError?: boolean;
};
export class GeneralLayout extends PureComponent<{}, BeforeRenderState> {
  constructor(props: {}) {
    super(props);
    this.state = {
      currentPath: "",
      ready: false,
      loginState: defaultLoginStateState
    };
  }
  ignorePath = [
    "/login",
  ]
  privateAreasPath = [
    "/competition/item/submit",
    "/discussions/new",
  ]
  privateAreasExactPath = [
    "/user",
  ]

  SubscribeHistory() {
    history.listen((listener, action) => {
      console.log("SubscribeHistory:", listener)
      this.setState({
        currentPath: listener.pathname
      })
      this.PathCheck(this.state.loginState.login);
      if (action == "REPLACE" && listener.pathname == "/competition/item") {
        window.location.reload()
      }

      if (listener.pathname == "/user") {
        const val = listener.key;
        if(localStorage.getItem('userKey') == val) {
          window.location.reload()
          localStorage.setItem('userKey', '')
        } else {
          localStorage.setItem('userKey', val)
        }
      }
      // 界面跳转 默认滚动回顶部
      if (document.getElementsByTagName('main')[0]) {
        document.getElementsByTagName('main')[0].scrollTop = 0
      }
      console.log("componentDidUpdate",)
    })
  }
  PathCheck(logged: boolean): boolean {
    const pathname = history.location.pathname.toLocaleLowerCase()
    const isIgnorePage = new Set([...Array.from(this.ignorePath, (item) => (pathname.startsWith(item.toLocaleLowerCase())) || undefined), undefined]).size > 1;
    if (isIgnorePage) {
      this.setState({
        ready: true
      })
      return false
    }
    const isPrivatePage_L1 = new Set([...Array.from(this.privateAreasPath, (item) => (pathname.startsWith(item.toLocaleLowerCase())) || undefined), undefined]).size > 1;
    const isPrivatePage_L2 = new Set([...Array.from(this.privateAreasExactPath, (item) => (pathname.toLocaleLowerCase() === (item.toLocaleLowerCase())) || undefined), undefined]).size > 1;
    const redirect = `${history.location.pathname}?${history.location.search}`
    if (!logged) {
      if (isPrivatePage_L1 || isPrivatePage_L2) {
        // history.replace({
        //   pathname: `/login?redirect=${encodeURIComponent(redirect)}`,
        // })
        history.push(`/login?redirect=${encodeURIComponent(redirect)}`)
        return true
      }
    }
    return false
  }
  async LoginCheck() {
    try {
      const response = await getUserInfo()

      if (response.success === true) {
        const data = response.data

        const logged = !!data.uid && (data.uid !== 0)
        if (logged) {
          const newUserLoginState: LoginState = {
            userName: data.full_name || `没有用户名(${data.phone})`,
            id: String(data.uid),
            uid: data.uid,
            phone: data.phone,
            email: data.email || "",
            avatar: new URL(data.picture),
            login: true,
            points: Number(data.points),
            joinTime: new Date(data.join_time),
            competition_range: data.competition_range,
            forum_range: data.forum_range,
            tiers_image_key: data.tiers_image_key,
            tiers: data.tiers,
          }
          this.setState({
            loginState: newUserLoginState
          })
        }
        this.PathCheck(logged)
      } else {
        throw "服务不可用"
      }
      this.setState({
        ready: true
      })
    } catch (error) {
      console.error(error)
      message.error("服务不可用", 99999)
      this.setState({
        serviceError: true
      })
    }
  }
  async componentDidMount() {
    this.SubscribeHistory()
    await this.LoginCheck()
  }
  render() {
    if (this.state.serviceError) {
      return <div style={{ height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
        <Spin size="large" indicator={antErrIcon} />
      </div>
    }
    if (this.state.ready) {
      return <Provider_LOGIN_STATE.Provider value={this.state.loginState}>
        {this.props.children}
      </Provider_LOGIN_STATE.Provider>
    }
    return <div style={{ height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
      <Spin size="large" indicator={antIcon} />
    </div>
  }
}


export default GeneralLayout;
