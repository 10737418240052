import { Logout } from '@/functions/logout';
import { tireKeyToThemeColorHEX } from '@/functions/TireColorTransform';
import { LoginState, Provider_LOGIN_STATE } from '@/layouts/beforeRender/loginState';
import { NationPhone } from '@/layouts/nation-phone';
import { ListMaker } from '@/pages/discussions/zone';
import { API_uploadImage } from '@/services/files';
import { getPowQuestion, getSmsCodeLoginUser, getSmsCodeWithPowAnswer } from '@/services/login';
import { getCompetictionList, getInvitationLists, getPersonalInfo, getPostList, getUserCompetictions, getUserInfo, getUserPosts, Posts, updateUserInfo, UserPublicInfo } from '@/services/user';
import { FrownOutlined, LogoutOutlined } from '@ant-design/icons';
import { Avatar, Badge, Button, ConfigProvider, Input, List, Menu, message, Modal, Popover, Skeleton, Tabs, Upload } from 'antd';
import ImgCrop from 'antd-img-crop';
import zhCN from 'antd/lib/locale/zh_CN';
import Title from 'antd/lib/skeleton/Title';
import moment from 'moment';
import React, { PureComponent } from 'react';
import { history as umiHistory } from 'umi';
import { parsePhoneNumber } from 'react-phone-number-input';
import { UserBillboard } from '../Billboard';
import { UserAvatar } from '../UserAvatar';
import "./render.less";
import { addNotifyEventListener, NotifyEvents, removeNotifyEventListener } from '@/services/notifyEvent';
import { Xavatar } from '@/functions/Xavatar';
import LoginPage, { PowMesage } from '@/pages/login';
import { LoadingOutlined, CheckOutlined } from '@ant-design/icons';

const infoKeyTranslate: {
    [str in InfoKey]: string
} = {
    name: "昵称",
    email: "邮箱",
    phoneNumber: "手机号",
    phoneState: "手机状态",
}
const { TabPane } = Tabs;

export type UserProfileRenderType = "display" | "usercenter"
type UserProfileRenderProps = {
    type: UserProfileRenderType
} & ({
    type: "display"
    uid: number
} | {
    type: "usercenter"
})

type UserProfileRenderState = {
    uid?: number
    info?: UserPublicInfo
    data?: Posts[]
}

export class UserProfileRender extends PureComponent<UserProfileRenderProps, UserProfileRenderState> {
    static contextType = Provider_LOGIN_STATE;
    listenerId: string;
    constructor(props: UserProfileRenderProps) {
        super(props);
        this.listenerId = "0"
        this.state = {
        };
    }
    render() {
        const classList = ["UserProfileRender", "user-content-container",]
        if (this.props.type === "display") {
            classList.push("UserProfileRender-display")
        } else {
            classList.push("UserProfileRender-usercenter")
        }
        const ActiveKey = localStorage.getItem('ActiveKey') + '';

        return <Provider_LOGIN_STATE.Consumer>
            {CONTEXT => {
                let info = this.state.info
                Xavatar(info?.email || "", info?.picture || "")
                return <div className={classList.join(" ")}>
                    <div className="user-header">
                        <div className="user-flex-container">
                            <UserAvatar defaultImage={info?.picture} editable={this.props.type === "usercenter"} />
                            <div className="user-flex-middle">
                                <div className="user-name">{info?.full_name || <Skeleton.Button active size="large" />}</div>
                                <div className="user-detail-list">个人累计积分
                                    <span className="user-dt-val">{info ? info.points : <Skeleton.Button active />}</span>
                                </div>
                                <div className="user-detail-list">赛事积分排名
                                    <span className="user-dt-val">{info ? info.competition_range : <Skeleton.Button active />}</span>
                                </div>
                                <div className="user-detail-list">论坛活跃排名
                                    <span className="user-dt-val">{info ? info.forum_range : <Skeleton.Button active />}</span>
                                </div>
                                <div className="user-detail-list">加入平台日期
                                    <span className="user-dt-val">{info ? new Date(info.join_time).toLocaleDateString() : <Skeleton.Button active />}</span>
                                </div>
                            </div>
                            <div className="user-flex-right">
                                <div className="user-class-box">
                                    {info ? <>
                                        <img src={this.toTiersImage(info.tiers_image_key)} style={{ height: 76 }} />
                                        <span className="user-class-type" style={{ color: tireKeyToThemeColorHEX(info.tiers_image_key) }}>{info?.tiers}</span>
                                    </> : <Skeleton.Avatar active className={"ant-skeleton-avatar-xxl"} />}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="user-content-inner">
                        <Tabs defaultActiveKey={ActiveKey || "5"} onChange={this.tabChange}>
                            <TabPane tab="基本信息" key="5">
                                {this.state.uid ? <UserBillboard uid={this.state.uid} /> : <Skeleton active />}
                            </TabPane>
                            <TabPane tab="帐号信息" key="1">
                                <div className="user-account-info">
                                    <div className="user-account-header">
                                        <div> 帐号信息（用户ID  <span className="user-account-uid">
                                            {CONTEXT.id}
                                        </span>）</div>
                                        <div style={{ textAlign: "right" }}>
                                            <Button onClick={Logout} danger>
                                                {"注销登录"}
                                            </Button>
                                        </div>
                                    </div>
                                    <div className="user-accont-list">
                                        <ul>
                                            <li>
                                                <div className="user-account-flex-container">
                                                    <div className="flex-left">
                                                        我的昵称
                                                    </div>
                                                    <div className="flex-middle">
                                                        <div className="user-attribute-val">{this.state.name || CONTEXT.userName}</div>
                                                        <div className="user-attribute-desc">这是你在系统中对外显示的名称（不作帐号登录使用）</div>
                                                    </div>
                                                    <div className="flex-right">
                                                        <div className="user-edit-action">
                                                            <Pop
                                                                id={"name"}
                                                                typeComponent="uf_name"
                                                                showComponent={this.state.showComponent}
                                                                visible={this.state.visible}
                                                                hideParent={this.hide}
                                                                setParentName={this.setParentName}
                                                            ><a onClick={() => {
                                                                console.log('click uf_name')
                                                                this.setState({
                                                                    showComponent: 'uf_name',
                                                                    visible: true,
                                                                })
                                                            }}>修改</a></Pop>


                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="user-account-flex-container">
                                                    <div className="flex-left">
                                                        我的邮箱
                                                    </div>
                                                    <div className="flex-middle">
                                                        <div className="user-attribute-val">{this.state.email || CONTEXT.email}</div>
                                                    </div>
                                                    <div className="flex-right">
                                                        <div className="user-edit-action">
                                                            <Pop
                                                                id={"email"}
                                                                typeComponent="uf_email"
                                                                visible={this.state.visible}
                                                                hideParent={this.hide}
                                                                setParentEmail={this.setParentEmail}

                                                                showComponent={this.state.showComponent}
                                                            ><a onClick={() => {
                                                                console.log('click uf_email')
                                                                this.setState({
                                                                    showComponent: 'uf_email',
                                                                    visible: true,
                                                                })
                                                            }}>修改</a></Pop>

                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="user-account-flex-container">
                                                    <div className="flex-left">
                                                        我的手机号
                                                    </div>
                                                    <div className="flex-middle">
                                                        <div className="user-attribute-val">{this.state.phone || CONTEXT.phone}</div>
                                                        <div className="user-attribute-desc">您的手机号码作帐号登录与密码找回所用</div>
                                                    </div>
                                                    <div className="flex-right">
                                                        <div className="user-edit-action">
                                                            <Pop
                                                                id={"phoneNumber"}
                                                                typeComponent="uf_phone"
                                                                showComponent={this.state.showComponent}
                                                                visible={this.state.visible}
                                                                hideParent={this.hide}
                                                                setParentPhone={this.setParentPhone}
                                                            ><a onClick={() => {
                                                                console.log('click uf_phone')
                                                                this.setState({
                                                                    showComponent: 'uf_phone',
                                                                    visible: true,
                                                                })
                                                            }}>修改</a></Pop>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="user-account-flex-container">
                                                    <div className="flex-left">
                                                        手机号验证状态
                                                    </div>
                                                    <div className="flex-middle">
                                                        <div className="user-attribute-val">已验证</div>
                                                    </div>
                                                    <div className="flex-right">
                                                        <div className="user-edit-action">
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </TabPane>
                            <TabPane tab="我的比赛" key="2">
                                <List
                                    className="com-list-content"
                                    style={{ marginBottom: 24 }}
                                    itemLayout="vertical"
                                    size="large"
                                    pagination={{
                                        pageSize: 8,
                                        hideOnSinglePage: true,
                                        showSizeChanger: false
                                    }}
                                    dataSource={this.state.com_list}
                                    renderItem={(item, id) => {
                                        return <div key={id} className="list-item" onClick={() => { umiHistory.push(`/competition/item/?org_id=${item._id}`) }}>
                                            <div className="flex-left">
                                                <a>
                                                    <div className="com-image" style={{
                                                        backgroundImage: "url(" + (item.Competition.Image || '/assets/images/com-active-1.png') + ")",
                                                        backgroundPosition: 'top center',
                                                        backgroundSize: 'cover',
                                                        backgroundRepeat: 'no-repeat'
                                                    }}>
                                                    </div>
                                                </a>
                                            </div>
                                            <div className="flex-middle">
                                                <a>
                                                    <Title level={5}>{item.Competition.Name}</Title>
                                                </a>
                                                <div className="desc">{item.Competition.ShortDescription}</div>
                                                <div className="item-bottom">
                                                    <div className="time-close">比赛结束时间 <span>{item.Competition.EndDateRender}</span></div>
                                                </div>
                                            </div>
                                            <div className="flex-right">
                                                <div className="status-box-open">
                                                    <div className="status-text">{item.Competition.StatusRender}</div>
                                                </div>
                                            </div>
                                        </div>
                                    }}
                                />
                            </TabPane>
                            <TabPane tab={
                                this.state.new_message ?
                                    <Badge dot={this.state.new_message}>
                                        <span style={{ fontSize: 16, lineHeight: '24px' }}>
                                            我的讨论
                                        </span>
                                    </Badge> : <span style={{ fontSize: 16, lineHeight: '24px' }}>
                                        我的讨论
                                    </span>
                            } key="3">
                                <List<Posts>
                                    className="com-list-content my_discussion"
                                    style={{ paddingLeft: 30, paddingRight: 30, paddingTop: 20, boxSizing: 'border-box' }}
                                    itemLayout="vertical"
                                    size="large"
                                    pagination={{
                                        pageSize: 8,
                                        hideOnSinglePage: true,
                                        showSizeChanger: false
                                    }}
                                    dataSource={this.state.posts}
                                    renderItem={item => {
                                        return <ListMaker
                                            loading={false}
                                            type={item.type}
                                            pageId={item.id}
                                            tid={item.type === 'post' ? item.id : item.post_id}
                                            title={item.type === 'post' ? item.title : item.post_title}
                                            section={{
                                                name: (item.type === 'post' ? item.section_name : "") + " " + moment(item.create_date).format("YYYY/MM/DD HH:mm:ss")
                                                //name: new Date(item.create_date).toLocaleString()

                                            }}
                                            content={item.type === 'post' ?
                                                <span style={{ color: '#003C83' }}>发表于 </span> :
                                                <span style={{ color: '#DA6F0F' }}>评论于 </span>}
                                            description={item.type === 'post' ? item.content : item.comment_body}
                                            like_count={item.like_count}
                                            new_message={item.new_message}
                                            comment_count={item.comment_count}
                                        />
                                    }}
                                />
                            </TabPane>
                            <TabPane tab="我的邀请" key="4">
                                <div className="my_invitation com-list-content">
                                    <List
                                        className="forum-search-list"
                                        itemLayout="horizontal"
                                        pagination={{
                                            pageSize: 8,
                                            hideOnSinglePage: true,
                                            showSizeChanger: false
                                        }}
                                        dataSource={(this.state.InvitationList)}
                                        renderItem={item => (
                                            <List.Item key={item.id} style={{ display: "flex", alignItems: "center", padding: 10 }}>
                                                <List.Item.Meta
                                                    style={{ display: "flex", alignItems: "center" }}
                                                    avatar={<Avatar src={item.picture} />}
                                                    title={<div style={{ fontSize: "14px", color: "#333" }}>{item.invitor_name}</div>}
                                                />
                                                <div style={{ fontSize: "14px", color: "#808695" }}>
                                                    {moment(item.invite_time).format("YYYY.MM.DD hh:mm:ss")}
                                                </div>
                                            </List.Item>
                                        )}
                                    />
                                </div>

                            </TabPane>
                        </Tabs>

                    </div>
                </div>
            }
            }
        </Provider_LOGIN_STATE.Consumer >

    }
    async componentDidMount() {
        const CONTEXT = this.context as LoginState
        let uid = 0
        if (this.props.type === "usercenter") {
            uid = CONTEXT.uid
        } else {
            uid = this.props.uid
        }

        this.setState({ uid })
        const response = await getPersonalInfo(uid)
        setTimeout(() => {
            this.setState({ info: response.data })
        }, 1000);


        if (this.props.type === "display") {
            return
        }

        await this.fetchData()
        setTimeout(() => {
            this.listenNotify()
        }, 1000);


    }
    componentWillUnmount(): void {
        removeNotifyEventListener(this.listenerId)
    }
    async fetchData() {
        const posts = await getUserPosts({ 'with_comments': '1' })
        let is_new_message = posts.data.filter(x => x.new_message > 0).length > 0;
        const compitionsList = await getCompetictionList({ size: '10', page: '0' });
        const userInfo = await getUserInfo();
        const postList = await getPostList({ size: 10, page: 0 });


        this.setState({
            posts: posts.data,
            new_message: is_new_message,
            userInfo: userInfo.data,
            compitionsList: compitionsList.data.data,
            postList: postList.data
        })
    }
    toTiersImage(tireId?: number) {
        return tireId ? "/genIcon/v" + tireId + ".svg" : ""
    }
    hide = () => {
        this.setState({
            visible: false,
        });
    }

    setParentName = (name) => {
        console.log('call setParentName, name => %s', name)
        this.setState({
            name: name,
        });
    }
    setParentPhone = (phone) => {
        console.log('call setParentPhone, phone => %s', phone)
        this.setState({
            phone: phone,
        });
    }
    setParentEmail = (email) => {
        console.log('call setParentEmail, email => %s', email)
        this.setState({
            email: email,
        });
    }
    onVote() {
        // alert(String(this.state.voted))
        this.setState({
            voted: !this.state.voted
        })
    }
    menu = <Menu>

        <Menu.Item icon={<FrownOutlined />}>
            <a onClick={Logout}>
                {"更换头像"}
            </a>
        </Menu.Item>
        <Menu.Item icon={<LogoutOutlined />} danger >
            <a onClick={Logout}>
                {"登出"}
            </a>
        </Menu.Item>
    </Menu>
    async loadCompetictions() {
        try {
            let coms = [];
            let res = await getUserCompetictions();
            console.log(res);
            for (let com of res.data.data) {
                com.key = com._id;
                coms.push(com);
            }
            console.log(coms);
            this.setState({
                com_list: coms
            })
        } catch (e) {
            console.log(e);
        }
    }
    async loadInvitationLists() {
        try {
            let coms = [];
            let res = await getInvitationLists();
            console.log(res);
            this.setState({
                InvitationList: res.data.data,
                total: res.data.totalNum,
                page: res.data.page,
            })
        } catch (e) {
            console.log(e);
        }
    }
    tabChange = (activeKey) => {
        if (activeKey == 2) {
            this.loadCompetictions();
            console.log(this.state.com_list)
        } else if (activeKey === '4') {
            this.loadInvitationLists()
        }
        console.log(activeKey);
        if (activeKey == '3') {
            localStorage.setItem('ActiveKey', activeKey)
        } else {
            localStorage.setItem('ActiveKey', '')
        }
    }

    updateImg(result, msg) {
        this.imgRef.src = msg;
    }

    onChangePage = (page, pageSize) => {
        console.log('page, ', page);
        console.log('pageSize, ', pageSize);
    }

    imgRef = React.createRef();

    async listenNotify() {
        this.listenerId = await addNotifyEventListener(this.notifyEventHandle.bind(this))
    }

    notifyEventHandle(event: NotifyEvents) {
        switch (event.type) {
            case "unreadChange":
                this.fetchData()
                break;
                1
            default:
                break;
        }
    }
}


type ChangeAvatarProps = {
    id: InfoKey
    onChange: (newValue: string) => void
}
type ChangeAvatarState = {


}
class ChangeAvatar extends PureComponent<ChangeAvatarProps, ChangeAvatarState> {

    constructor(props: PopProps) {
        super(props);
        this.state = {

        };

    }

    // 方法：图片预览
    handlePreview = (file) => {
        console.log(' handlePreview:' + JSON.stringify(file));
        this.setState({
            previewImage: file.url || file.thumbUrl,
            previewVisible: true,
        });
    };

    // 参考链接：https://www.jianshu.com/p/f356f050b3c9
    handleBeforeUpload = (file) => {
        console.log(' handleBeforeUpload file:' + JSON.stringify(file));
        console.log(' handleBeforeUpload file.file:' + JSON.stringify(file.file));
        console.log(' handleBeforeUpload file type:' + JSON.stringify(file.type));

        //限制图片 格式、size、分辨率
        const isJPG = file.type === 'image/jpeg';
        const isJPEG = file.type === 'image/jpeg';
        const isGIF = file.type === 'image/gif';
        const isPNG = file.type === 'image/png';
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!(isJPG || isJPEG || isPNG)) {
            Modal.error({
                getContainer: '#root',
                title: '只能上传JPG、JPEG、PNG格式的图片~',
            });
        } else if (!isLt2M) {
            Modal.error({
                getContainer: '#root',
                title: '图片超过2M限制，不允许上传~',
            });
        }
        return (isJPG || isJPEG || isPNG) && isLt2M;
    };

    doImgUpload = (options) => {
        const { onSuccess, onError, file, onProgress } = options;
        console.log('calling doImgUpload');
        console.log(file);
        console.log(file.name);
        // start：进度条相关

        const reader = new FileReader();
        // reader.readAsBinaryString(file); // 读取图片文件
        // reader.readAsDataURL(file); // 读取图片文件
        reader.readAsArrayBuffer(file); // 读取图片文件

        reader.onload = (progress) => {
            console.log("progress", progress);
            // console.log(file.name);
            // console.log(progress.target.result);

            // 上传图片的binariy编码，调接口后，返回 imageId
            API_uploadImage(progress.target.result, encodeURIComponent(file.name))
                // uploadImage(file, file.name)
                .then((res) => {
                    console.log(res);
                    console.log('doImgUpload:' + JSON.stringify(res));
                    if (res.success && res.data.uri.length < 1) {
                        console.log('图片上传失败:' + JSON.stringify(res));
                        message.error('图片上传失败，请重试');
                    } else {
                        let image_uri = res.data['uri'][0]['url'];
                        console.log('图片上传成功：' + image_uri);
                        let image_url = UF_CONFIG.api_url + image_uri;

                        updateUserInfo({
                            user_picture: res.data.fid[0]['value']
                        }).then(res => {
                            this.props.parent.updateImg(this, image_url);
                        })
                    }
                })
                .catch((e) => {
                    console.log('smyhvae 图片上传失败:' + JSON.stringify(e || ''));
                    message.error('图片上传失败，请重试');
                });
        };
    };

    handleChange = ({ file, fileList }) => {
        console.log('smyhvae handleChange file:' + JSON.stringify(file));
        console.log('smyhvae handleChange fileList:' + JSON.stringify(fileList));

        if (file.status == 'removed') {
            this.setState({
                imgList: [],
            });
        }
    };
    render() {
        return <div>

            <ConfigProvider locale={zhCN}>
                <ImgCrop rotate onModalOk={(event) => {
                    console.log("event", event)
                }}>

                    <Upload
                        accept="image/*"
                        showUploadList={false}
                        customRequest={this.doImgUpload}
                        onPreview={this.handlePreview}
                        beforeUpload={this.handleBeforeUpload}
                        onChange={this.handleChange}
                    >

                        {this.props.children}
                    </Upload>

                </ImgCrop>
            </ConfigProvider>


        </div>
    }

}



type PopProps = {
    id: InfoKey
    onChange: (newValue: string) => void
}
type PopState = {
    input: string
    input2: string
    buttonLoading?: boolean
    count?: number
    liked?: boolean
    visible?: boolean
    INPUT_country: string,
    INPUT_countryCallingCode: string,
    INPUT_nationalNumber: string
    //
    /**是否显示伪人机验证滑动条 */
    showReactSlider?: boolean
    modalType?: string
    modalTitle?: string
    /**短信倒计时的时间
     * 
     * 单位 秒
     * 
     * kan0518wei, 写的老代码
     */
    time?: string | number
    smsSendCoolDownTime?: string | Date
    //pow
    powDone?: boolean
    powResolveKey?: string
    waitingPost?: boolean
    SMSCodeSentTime: Date
    SMSCodeSending?: boolean
}
class Pop extends PureComponent<PopProps, PopState> {
    state: any;
    SMSLimit = 60
    constructor(props: PopProps) {
        super(props);
        this.state = {
            input: "",
            input2: "",
            count: 60,
            liked: true,
            visible: false
        }
    }
 
    render() {
        const func = () => {
            if (this.props.id === 'phoneNumber') {
                return <NationPhone onChange={this.phone_change.bind(this)} value={this.state.input} style={{ marginBottom: 10, display: this.props.id !== "phoneNumber" ? "none" : "flex", width: 250 }} />
                // <InputNumber value={this.state.input} onChange={(event) => {
                //   console.log(event);
                //   const input = event;
                //   this.setState({ input })
                // }}
                // maxLength={11}
                // placeholder={"请输入"}
                // style={{ display: "block", marginBottom: 10, width: 250 }} />
            } else {
                return <Input.TextArea
                    value={this.state.input}
                    onChange={(event) => {
                        const input = event.target.value;
                        this.setState({ input })
                    }}
                    maxLength={this.props.id == 'name' ? 20 : 100}
                    showCount={this.props.id === 'name'}
                    placeholder={"请输入"}
                    style={{ display: "block", marginBottom: 10, width: 250 }} />
            }
        }
        return <Popover
            placement="topRight"
            getPopupContainer={() => document.getElementById('root')}
            visible={this.state.visible}
            title={`${"修改"}${infoKeyTranslate[this.props.id]}`}
            // trigger={() => this.showHover()}
            trigger="click"
            content={<div style={{ textAlign: "right" }}>
                {func()}
                <Input
                    value={this.state.input2}
                    onChange={(event) => {
                        const input2 = event.target.value
                        this.setState({ input2 })
                    }}
                    placeholder={"请输入验证码"}
                    style={{ marginBottom: 10, display: this.props.id !== "phoneNumber" ? "none" : "block", width: 250 }} />
                <Button onClick={() => this.hide()} type="default" style={{ marginRight: 10 }}>取消</Button>
                <Button onClick={() => this.handleClick()} type="default"
                    style={{ display: this.props.id !== "phoneNumber" ? "none" : "unset", marginRight: 10 }}>
                    {
                        this.state.liked
                            ? '获取验证码'
                            : `${this.state.count} 秒后重发`
                    }
                </Button>



                <Button
                    loading={this.state.buttonLoading}
                    onClick={this.handleSubmit}
                    type="primary"
                    disabled={(this.props.id === "phoneNumber" && !this.state.input2) || !this.state.input}>
                    {"确认"}
                </Button>
                <Modal open={this.state.waitingPost && !this.state.powDone} footer={null} title={null} closable={false}>
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
          <LoadingOutlined style={{ fontSize: 48, marginBottom: 24 }} />
          <div>{"正在检查浏览器环境..."}</div>
        </div>
      </Modal>
            </div>}
        >
            {this.props.children}
        </Popover>
    }
    componentDidMount(): void {
       this.powRound()
    }
    componentWillReceiveProps(nextProps) {

        if (nextProps.visible == true) {
            if (nextProps.showComponent == this.props.typeComponent) {
                console.log('watch: showComponent => %s, typeComponent => %s ', nextProps.showComponent, this.props.typeComponent)
                this.setState({ visible: true });
            } else {
                this.setState({ visible: false });
            }
        }
    }
    hide = () => {
        console.log('hide')
        this.props.hideParent()
        this.setState({
            visible: false,
        });
    }

    setName = (name) => {
        this.props.setParentName(name)
    }
    setPhone = (phone) => {
        this.props.setParentPhone(phone)
    }
    setEmail = (email) => {
        this.props.setParentEmail(email)
    }
    update() {
        updateUserInfo()
    }
    // componentDidMount(){
    //   this.props.onRef(this)
    // }
    countDown() {
        let res = this.state.count;
        if (res === 1) {
            this.setState({
                count: 60,
                liked: true,
            });
        } else {
            res ? this.setState({
                count: res - 1,
                liked: false,
            }) : console.log("count为undefined");
            setTimeout(this.countDown.bind(this), 1000);
        }
    }

    handleClick = async () => {
        // const {sendMsg} = this.props;

        // if(!(new RegExp(/^1(3|4|5|6|7|8|9)\d{9}$/, "g").test(this.state.input)) && this.props.id === 'phoneNumber'){
        //   message.error('请输入正确手机号码')
        //   return
        // }
        // const regMobile= /^(0|86|17951)?(13[0-9]|15[0123456789]|17[678]|18[0-9]|14[57])[0-9]{8}$/
        // if(regMobile.test(this.state.input)) {

        // } else {
        //   message.error('请输入正确手机号')
        // }
        const { liked } = this.state;
        if (!liked) {
            // alert("请稍后重试")
            return;
        }
        this.countDown();
        try {

            await this.sendSMSCode()

            await getSmsCodeLoginUser({
                phone: this.state.input,
                countryCallingCode: this.state.INPUT_countryCallingCode,
                nationalNumber: this.state.INPUT_nationalNumber,
                check_duplciate: true
            })
        } catch (e) {
            console.log(e.toString());
            console.log('failed to get sms.');
            // this.setState({
            // count: 1,
            // liked: true,
            // });
        }
    };

    handleSubmit = async () => {
        if (!(this.state.input.trim())) {
            return
        }
        const input = this.state.input.trim()
        const input2 = input.replaceAll("=", "").replaceAll("+", "").replaceAll("_", "")
        let isTrueEmail = (value: string) => {
            value = String(value)
            value = value.trim();
            let reg = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
            // let reg = /^([a-zA-Z\d][.][\w-]{2,})@(\w{2,})\.([a-z]{2,})(\.[a-z]{2,})?$/;
            return reg.test(value);
        };
        if ((!isTrueEmail(input) && !isTrueEmail(input2)) && this.props.id === 'email') {
            message.error('请输入正确邮件地址' + input2)
            return
        };
        this.setState({
            buttonLoading: true
        })
        switch (this.props.id) {
            case "email":
                // this.setState({ email: this.state.input })
                try {
                    await updateUserInfo({
                        email: input
                    })
                    this.setEmail(input)
                    this.hide()
                    this.setState({
                        input: '',
                        buttonLoading: false,
                    })
                    this.hide();
                } catch (err) {
                    console.log(err);
                    this.setState({
                        visible: true,
                        buttonLoading: false,
                    })
                }

                break;
            case "name":
                try {
                    await updateUserInfo({
                        full_name: input
                    })
                    this.setName(input)
                    this.hide()
                    // this.setState({ name: this.state.input })
                    console.log('hit 1');
                    this.setState({
                        input: '',
                        // visible: false,
                        buttonLoading: false,
                    })

                    // this.hide();
                } catch (err) {
                    console.log(err);

                    this.setState({
                        visible: true,
                        buttonLoading: false,
                    })
                }
                break;
            case "phoneNumber":
                try {
                    await updateUserInfo({
                        phone: input,
                        code: this.state.input2.trim(),
                        "countryCallingCode": this.state.INPUT_countryCallingCode,
                        "nationalNumber": this.state.INPUT_nationalNumber,
                    })
                    this.setPhone(input)
                    this.hide()
                    this.setState({
                        input: '',
                        input2: '',
                        count: 1,
                        buttonLoading: false,
                    })
                    this.hide();
                } catch (err) {
                    this.setState({
                        visible: true,
                        buttonLoading: false,
                    })
                }
                break;
            default:
                break;
        }
    }

    phone_change(phone) {

        // phoneChange
        if (phone) {
            const phoneNumberParse = parsePhoneNumber(phone) || {};

            this.setState({
                input: phone,
                INPUT_country: phoneNumberParse['country'],
                INPUT_countryCallingCode: phoneNumberParse['countryCallingCode'],
                INPUT_nationalNumber: phoneNumberParse['nationalNumber']
            })
        }

    }


    /** 从ubiquant_forum_front\src\pages\login\index.tsx复制来的 */
    async sendSMSCode() {
        const limitTime = this.SMSLimit
        const phone = this.state.input


        const answer = await this.getPowAnswer()
        console.log('phone: %s', phone);
        console.log('answer: %s', answer);


        this.setState({ smsSendCoolDownTime: new Date(), SMSCodeSending: true })
        const timer = setInterval(() => {
            if (this.state.smsSendCoolDownTime) {
                if ((new Date().valueOf() - new Date(this.state.smsSendCoolDownTime).valueOf()) > limitTime * 1000) {
                    clearInterval(timer)
                    this.setState({ SMSCodeSending: false })
                }
            }
            this.setState({ time: performance.now() })
        }, 1000)

        // return
        try {
            let res = await getSmsCodeWithPowAnswer({
                "phone": phone || "",
                "countryCallingCode": this.state.INPUT_countryCallingCode || "",
                "nationalNumber": this.state.INPUT_nationalNumber || "",
                check_duplciate: true,
                key: answer
            });
            console.log("getSmsCodeWithPowAnswer response", res)
        } catch (error) {
            console.log(error);
            this.setState({
                SMSCodeSending: false
            })
            return
        }

        setTimeout(() => {
            this.setState({
                //SMSCodeSending: false,
                SMSCodeSentTime: new Date()
            })
        }, 3000);

    }

    /** 从ubiquant_forum_front\src\pages\login\index.tsx复制来的 */
    async powRound() {
        const question = await getPowQuestion()
        const { prefix, salt } = question.data

        const powWorker = new Worker("/workers/pow.js?v=4", { type: "module" });
        console.log("powWorker", powWorker)

        powWorker.postMessage({
            action: "findAnswer",
            data: {
                salt, answerPrefix: prefix
                // salt, answerPrefix: "12345"
            },
        });
        powWorker.addEventListener("message", (event: MessageEvent<PowMesage>) => {
            console.log("event,message", event.data)
            if (event.data.done) {
                this.setState({
                    powDone: true,
                    powResolveKey: event.data.key,
                })
                powWorker.terminate()
            }

        })
    }
    /** 从ubiquant_forum_front\src\pages\login\index.tsx复制来的 */
    getPowAnswer(): Promise<string> {
        return new Promise((resolve, reject) => {
            if (this.state.powDone && this.state.powResolveKey) {
                resolve(this.state.powResolveKey)
                this.powRound()
                this.setState({ waitingPost: false, powResolveKey: undefined, powDone: undefined })
            } else {
                this.setState({ waitingPost: true })
                setInterval(() => {
                    if (this.state.powDone && this.state.powResolveKey) {
                        resolve(this.state.powResolveKey)
                        this.powRound()
                        this.setState({ waitingPost: false, powResolveKey: undefined, powDone: undefined })
                    }
                }, 100)
            }
        })
    }
}

