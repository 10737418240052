import { PureComponent, useState } from 'react';
import { Layout, Row, Col, Modal, Button, Tabs, Progress } from 'antd';

import { Typography, List, Skeleton, Avatar, Dropdown } from 'antd';
import { LineDecoration } from "../../component/lineDecoration"
import { QuestionCircleOutlined } from '@ant-design/icons';
import {
  DoubleRightOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import "./index.less"
import { Link, history } from 'umi';

import GoldIcon from "../../../public/assets/Icons/goldl@1x.png"
import SilverIcon from "../../../public/assets/Icons/silverl@1x.png"
import BronzeIcon from "../../../public/assets/Icons/bronzel@1x.png"
import { CompSearchResultItem, searchCompetiction } from "../../../src/services/search"
import { Input, Space } from 'antd';
import { searchTopics, TopicSearchResultItem } from '@/services/forum';
import React from 'react';
import { ListMaker } from '../discussions/zone';
const { TabPane } = Tabs;
const { Title, Text, Paragraph } = Typography;
type Props = {
}
type State = {
  searchContent?: string
  currentSearchment?: string
  currentDisplayingSearchment?: [TopicSearchResultData[], CompSearchResultData[]]
  currentDisplayingTabId: "0" | "1"
  loading: boolean
}
type TopicSearchResultData = (TopicSearchResultItem & { isTopic: true, isComp?: false })
type CompSearchResultData = (CompSearchResultItem & { isTopic?: false, isComp: true })
class Page extends PureComponent<{}, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      searchContent: this.getKeyWord(),
      currentSearchment: "",
      currentDisplayingTabId: "1",
      loading : false,
    };
  }

  render() {
    const right = <Button style={{ pointerEvents: "none" }}>
      {this.state.currentDisplayingSearchment ?
        `搜索 ${this.state.currentDisplayingSearchment[this.state.currentDisplayingTabId].length} 结果` :
        ""}
    </Button>
    return <>
      <div className={"SEARCH_page_box"}>
        <Input
          style={{ marginTop: 2 }}
          value={this.state.searchContent}
          placeholder="搜索:量化联赛、kaggle、交易"
          suffix={<SearchOutlined
            style={{ fontSize: 16, color: "#A8AEB7" }} onClick={() => {history.push(`/search?wd=${this.state.searchContent}`)}}
          />}
          onChange={(event) => {
            this.setState({
              searchContent: event.target.value
            })
          }}
          onPressEnter={((event) => {
            const target = event.target as HTMLInputElement
            const input = target.value
            history.push(`/search?wd=${input}`)
          })}

        />
      </div>
      <div className="SEARCH_page_main" style={{minHeight:100}}>
        <Tabs tabBarExtraContent={{right: right}}
          defaultActiveKey={"1"}
          style={{ display: !!this.state.searchContent ? "block" : "none" }}
          //  accessKey={this.state.currentDisplayingTabId}
          onChange={(nextId) => {
            const unsafeNextId = nextId as "0" | "1"
            this.setState({ currentDisplayingTabId: unsafeNextId })
          }}>
          <TabPane tab="比赛" key="1">
            {this.ResultMaker((this.state.currentDisplayingSearchment)?.[1])}
          </TabPane>
          <TabPane tab="论坛" key="0">
            {this.ResultMaker((this.state.currentDisplayingSearchment)?.[0])}
          </TabPane>
        </Tabs>

      </div>
    </>
  }
  componentDidMount() {



    let _this = this;
    history.listen((event) => {
      console.log("event%%%%%%%%%%%%%%", event);
      _this.setState({
        loading: true
      })
      _this.freshSearchResult(event.query.wd)
    })
    
    _this.freshSearchResult(this.props.location.query?.wd)
  }
  getKeyWord() {
    const search = history.location.search
    try {
      const url = new URL(`http://www.example.com/${search}`)
      const wd = url.searchParams.get("wd")
      return wd || undefined
    } catch (error) {
      return
    }
  }
  async freshSearchResult(keyword: string) {
    
    if (this.state.loading) {
      return
    } else {
      this.setState({
        currentSearchment: keyword,
        searchContent: keyword,
      })
    }
    // CompSearchResultData TopicSearchResultData
    
    const comp = await (await searchCompetiction(keyword)).data.data
    const compData: CompSearchResultData[] = Array.from(comp, (item) => { return { ...item, isComp: true } })

    const post = await (await searchTopics(keyword)).data.data
    const postData: TopicSearchResultData[] = Array.from(post, (item) => { return { ...item, isTopic: true } })

    const res: State["currentDisplayingSearchment"] = [postData, compData]
    console.log({
      comp,
      post
    })

    this.setState({
      currentDisplayingSearchment: res,
      loading:false
    })
  }


  ResultMaker(items?: CompSearchResultData[] | TopicSearchResultData[]) {
    console.log(items,"******************")
    // const listData = [];
    // for (let i = 0; i < 23; i++) {
    //   listData.push({
    //     href: 'https://ant.design',
    //     title: `ant design part ${i}`,
    //     avatar: 'https://joeschmoe.io/api/v1/random',
    //     description:
    //       'Ant Design, a design language for background applications, is refined by Ant UED Team.',
    //     content:
    //       'We supply a series of design principles, practical patterns and high quality design resources (Sketch and Axure), to help people create their product prototypes beautifully and efficiently.',
    //   });
    // }



    return <List<CompSearchResultData | TopicSearchResultData>
      itemLayout="vertical"
      size="large"
      loading={this.state.loading || !items}
      pagination={{
        onChange: page => {
          console.log(page);
        },
        pageSize: 8,
      }}
      dataSource={items}
      footer={
        <div></div>
      }
      renderItem={item => {
        if (item.isTopic) {

          return <ListMaker
            loading={false}
            tid={item.id}
            title={item.title}
            author={{
              avatar: {
                picture: new URL(item.author?.picture)
              },
              name: item.author.name
            }}
            section={{
              name: item.section_name || "ERR"
            }}
            latest_action={{
              author: {
                name: item.latest_action.author
              },
              time: item.latest_action.time,
              type: item.latest_action.type
            }}
          />
        } else if (item.isComp) {
          return <List.Item key={`Topic-${item.id}`}
            style={{ cursor: "pointer" }}
            onClick={() => {
              history.push(`/competition/item?org_id=${item.uf_portal_id}`)
            }}>
            <div style={{
              display: "inline-block",
              width: 250, height: 160,
              borderRadius: 4,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundImage: `url(${item.uf_competition_Image})`,
              backgroundColor: "#e9e9e9",
            }} />
            <div style={{
              display: "inline-block",
              width: `calc(100% - ${250}px)`, height: 160,
              position: "relative",
              verticalAlign: "top",
              paddingTop: 8,
              paddingLeft: 32,

            }}>
              <Title style={{ fontWeight: 400, fontSize: 20, lineHeight: "30px" }}>
                {item.uf_competition_Name}
              </Title>
              <Paragraph ellipsis={{ rows: 2, expandable: false }}
                style={{ fontWeight: 400, fontSize: 16, lineHeight: "24px", color: "#808695" }}>
                {item.uf_competition_ShortDescription}
              </Paragraph>
              <div style={{ position: "absolute", bottom: 0, fontSize: 16 }}>
                <Text type="secondary" style={{ fontSize: 16, marginRight: 12 }}>
                  {"比赛结束时间"}
                </Text>
                <Text style={{ color: "#333", marginRight: 20 }}>
                  {new Date(item.uf_competition_StartDate).toLocaleDateString()}
                </Text>
                <Text type="secondary" style={{ fontSize: 16, marginRight: 12 }}>
                  {"参赛团队"}
                </Text>
                <Text style={{ color: "#333", marginRight: 20 }}>
                  {item.uf_competition_TeamsNumber || "0"}

                </Text>
              </div>
            </div>
          </List.Item>
        }
        return "err" + JSON.stringify(item)
      }}
    />
  }
}

export default Page
