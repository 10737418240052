import { Icon } from "@/functions/IconLab";
import { Provider_LOGIN_STATE } from '@/layouts/beforeRender/loginState';
import { addNotifyEventListener, NotifyEvents, removeNotifyEventListener } from "@/services/notifyEvent";
import { PostDetail } from '@/types/post';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Avatar, Button, Divider, Modal, Progress, Skeleton, Space, Spin, Tag, Typography } from 'antd';
import moment from 'moment';
import { PureComponent } from 'react';
import { history, history as umiHistory, Link } from "umi";
import { deletePost, getPostDetail } from "../../../services/forum";
import { Dislike } from "../ListVotes";
import { RenderComment } from './Comment';
import './global.less';
import './index.less';
import styles from './index.less';
import { CommentForEdit, CommentModalReEdit } from './reedit';

moment.locale("zh-cn")
const { Title, Paragraph } = Typography;


type State = {
  detail?: PostDetail
  editing?: CommentForEdit
}

class Page extends PureComponent<{}, State> {
  readonly pid: number
  listenerId: string;
  constructor(props: {}) {
    super(props);
    this.pid = Number(document.location.pathname.split("/")[3]);
    this.listenerId = "0"
    this.state = {
    };
  }

  render() {
    const detail = this.state.detail;

    return <Provider_LOGIN_STATE.Consumer>
      {CONTEXT => {
        const Delete = <Button type="text" icon={<Icon id="DeleteFilled" />} onClick={() => this.action("delete")}>{"删除"}</Button>
        const Edit = <Button type="text" icon={<Icon id="EditFilled" />} onClick={() => this.action("edit")}>{"编辑"}</Button>
        return (
          <div className="post-container src\pages\discussions\post\index.tsx">
            <div className="common-header2">
              <div className="left">
                <h1>{detail?.section_name}</h1>
              </div>
              <div className="right">
                <Link to={`/discussions/new/${detail?.section_id}`}>
                  <Button style={{ color: '#333' }} shape="round" icon={<Icon id="PlusOutlined" />}>新建话题</Button>
                </Link>
              </div>
            </div>

            <div className={styles["discussions_post_index-main"]}>
              <div id="post_content_main" >
                <sub>
                  <Progress
                    type="circle" width={64} percent={0}
                    format={() => <Link to={{ pathname: `/user/${detail?.author.id}`, query: { "uid": detail?.author.id } }}>
                      <Avatar size={50} src={detail?.author.picture} />
                    </Link>} />
                  <Tag style={{ margin: 5 }}><span style={{ lineHeight: 1.3 }}>{detail?.author.name || "-"}</span></Tag>
                  <Tag style={{ margin: 5 }}><span>{detail?.author.tiers || "-"}</span></Tag>
                </sub>
                <section>
                  <header>
                    <Title level={4} style={{ marginRight: 250, wordBreak: "break-all" }}>
                      {detail?.title || <Skeleton.Button block={true} active size={"large"} />}
                    </Title>
                    <div style={{ position: "absolute", right: 10 }}>
                      {detail ? <Dislike targetId={detail.id} targetType={"post"} defaultState={detail.is_like} votes={detail.like_count} /> : <Skeleton.Button active />}
                    </div>
                  </header>
                  <Paragraph>
                    {detail?.title ? <div style={{ wordBreak: 'break-all' }} dangerouslySetInnerHTML={{ __html: detail.content }} /> : <Skeleton active />}
                  </Paragraph>
                  <Space style={{ cursor: 'pointer' }} split={<Divider type="vertical" />}>
                    {detail && CONTEXT.login && CONTEXT.uid === detail.author.id ? <>
                      {Edit}
                      {Delete}
                    </> : ""}
                  </Space>
                </section>
              </div>
              {(() => {
                if (detail) {
                  return <>
                    <div id="post_content_comment">
                      <header>
                        <Title level={4}>{"评论"}</Title>
                      </header>
                      <article>
                        <div >
                          <sub style={{ textAlign: "center", width: 160 }}>
                            <Progress
                              type="circle"
                              width={64}
                              percent={0}
                              format={() => <Link to={{ pathname: `/user/${CONTEXT.id}`, query: { "uid": CONTEXT.id } }}><Avatar
                                src={CONTEXT.avatar.href}
                                size={50}
                              /></Link>} /><br></br>
                            <Tag style={{ margin: 5 }}><span style={{ lineHeight: 1.3 }}>{CONTEXT.userName || CONTEXT.phone}</span></Tag><br></br>
                            <Tag style={{ margin: 5 }}><span>{CONTEXT.tiers}</span></Tag>
                          </sub>
                          <section >
                            <Spin
                              spinning={!CONTEXT.login}
                              indicator={<span></span>}
                              tip={<span style={{ color: "#808695" }}>
                                {"发表评论需要先"}
                                <Link to={`/login?redirect=${encodeURIComponent(history.location.pathname)}`}>{"登录"}</Link>
                              </span>}>
                              <CommentModalReEdit
                                editing={{
                                  behavior: "new",
                                  content: "",
                                  postId: detail.id
                                }}
                                onDone={this.onDone.bind(this)}
                                notModal={true}
                                okText={"发表评论"}
                              />
                            </Spin>
                          </section>
                        </div>
                        <RenderComment tid={detail.id} onref={ref => this.commentRef = ref} />
                      </article>
                    </div>
                    <div>
                    </div>
                  </>
                }
              })()}
            </div>
          </div>
        );
      }}
    </Provider_LOGIN_STATE.Consumer>

  }
  async componentDidMount() {
    await this.fetchData()
    this.listenNotify()
    if (this.props.location.query.commentId) {
      setTimeout(() => {
        // 评论中间位置
        // document.getElementById(this.props.location.query.commentId)?.scrollIntoView({block: 'center'})
        // 评论顶部位置
        document.getElementById(this.props.location.query.commentId)?.scrollIntoView(true)
      }, 800)
    }
  }
  componentWillUnmount(): void {
    removeNotifyEventListener(this.listenerId)
}
  async fetchData() {
    const response = await getPostDetail(this.pid)
    const item = response.data
    this.setState({ detail: item })
  }
  private action(action: "delete" | "edit") {
    if (!this.state.detail) {
      return
    }
    const detail = this.state.detail
    switch (action) {
      case "delete":
        Modal.confirm({
          getContainer: '#root',
          icon: <ExclamationCircleOutlined />,
          content: '是否确认删除当前主题？',
          okText: '确认',
          cancelText: '取消',
          onOk: async () => {
            await deletePost(detail.id)
            umiHistory.replace(`/discussions/zone/${detail.section_id}`)
          }
        });


        break;
      case "edit":
        umiHistory.push(`/discussions/post/${detail.id}/edit`)

        break;
      default:
        break;
    }
  }

  async onDone() {
    console.log("onDone",) 
    this.commentRef && this.commentRef.updateList()
  }

  async listenNotify() {
    this.listenerId = await    addNotifyEventListener(this.notifyEventHandle.bind(this))
  }
  notifyEventHandle(event: NotifyEvents) {
    switch (event.type) {
        case "unreadChange":
            this.fetchData()
            break;
            1
        default:
            break;
    }
}
}


export default Page
