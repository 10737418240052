import { PureComponent } from 'react';
import { Button, Typography } from 'antd';
import { Menu, Input, Avatar, Image, Drawer } from 'antd';
import { DownloadOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import styles from './index.less';
import '../../global.less';
// import { getRandomPictrueURL } from '../../../functions/getPictrue';
import { history } from 'umi';
import { getCurrentChildrenConfig, ChildrenConfigItem } from '../../../config/general_children_config';
import { Icon, IconKey } from "../../../functions/IconLab"
import store from '@/redux/store/store'
import actionCreator from '@/redux/store/actionCreator'
import {
  getForumSection
} from "@/services/forum";


const { Title, Text } = Typography;

type GeneralChildrenLayoutState = {
  // hedaerBg?: string;
  title?: string
  description?: ChildrenConfigItem["description"]
  buttonText?: ChildrenConfigItem["buttonText"]
  buttonIcon?: ChildrenConfigItem["buttonIcon"]
  buttonPosition?: ChildrenConfigItem["buttonPosition"]
  buttonType?: ChildrenConfigItem["buttonType"]
  buttonHref?: ChildrenConfigItem["buttonHref"]
  currentPagePathname?: string
  hide?: boolean
};
export class GeneralChildrenLayout extends PureComponent<
  {},
  GeneralChildrenLayoutState
> {
  constructor(props: {}) {
    super(props);
    this.state = {
    };
  }

  async updateInfo() {
    let { sections } = store.getState()
    if (sections.length == 0) {
      const result = await getForumSection()
      const data = result.data
      sections = []
      for (let item of data) {
        sections.push({
          id: item.id,
          key: item.id,
          icon: (item.icon ? new URL(item.icon) : ''),
          name: item.name,
          description: item.description || "",
          topics: item.last_posts || [],
        })
      }
      actionCreator.setSections(sections);
    }

    const config = getCurrentChildrenConfig()
    this.setState({
      title: config?.title,
      description: config?.description,
      buttonText: config?.buttonText,
      buttonIcon: config?.buttonIcon,
      buttonPosition: config?.buttonPosition,
      buttonType: config?.buttonType,
      buttonHref: config?.buttonHref,
      hide: config?.hide
    });

    // const url = await getRandomPictrueURL();

    const currentPagePathname = history.location.pathname

    this.setState({
      // hedaerBg: url,
      currentPagePathname,
    });


  }

  async componentDidMount() {
    await this.updateInfo()
  }

  async componentDidUpdate() {
    if (this.state.currentPagePathname !== history.location.pathname) {
      await this.updateInfo()
    }
  }

  render() {
    const widthLeft = this.state.buttonPosition === "bottom" || this.state.buttonPosition === "none" ? "100%" : "calc(100% - 160px)"
    const widthRight = this.state.buttonPosition === "bottom" || this.state.buttonPosition === "none" ? 0 : 160
    const paddingRight = this.state.buttonPosition === "bottom" || this.state.buttonPosition === "none" ? 0 : 5


    return (
      <div className={styles.GeneralChildrenLayout}>
        <header
          style={{
            overflow: 'hidden', ...(this.state.hide ? {
              height: 0, margin: 0, padding: 0
            } : {})
          }}
        >
          <div style={{ display: "inline-block", width: widthLeft }}>

            <Title style={{ marginBottom: 10 }} id={"layouts_general_children_index_title"}>
              {this.state.title}
            </Title>
            <Title level={4} style={{ marginTop: 0 }}>
              <Text type="secondary">
                {this.state.description}
              </Text>
            </Title>
            {(() => {
              if (this.state.buttonPosition === "bottom")
                return <Button shape="round" icon={<Icon id={this.state.buttonIcon} />}
                  onClick={() => {
                    if (this.state.buttonHref) {
                      history.push(this.state.buttonHref)
                    }
                  }}
                >{this.state.buttonText}</Button>
            })()}
          </div>

          <div style={{
            display: "inline-block",
            width: widthRight,
            overflow: "hidden",
            padding: paddingRight,
            textAlign: "right"
          }}>

            <Button shape="round" icon={<Icon id={this.state.buttonIcon} />} type={this.state.buttonType}
              onClick={() => {
                if (this.state.buttonHref) {
                  history.push(this.state.buttonHref)
                }
              }}
            >新建话题</Button>
          </div>
        </header>
        <article>{this.props.children}</article>
      </div>
    );
  }
}

export default GeneralChildrenLayout;
