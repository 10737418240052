import { PureComponent } from 'react';
import './index.less';
import FileRead  from "../file_read/file_read";

export class PrivacyPolicy extends PureComponent<{}, {}> {

    render() {
        return <div className="com-header-innner">
        <FileRead fileType={"PrivacyPolicy"} fileState={"block"}></FileRead>
    </div>
    }
}
export class UserAgreement extends PureComponent<{}, {}> {
    render() {
        return <>
        <FileRead fileType={"UserAgreement"} fileState={"block"}></FileRead>
        </>
    }
}
export class InstructionsContestants extends PureComponent<{}, {}> {
    render() {
        return <div className="com-header-innner">
        <FileRead fileType={"contestantsNotes"} fileState={"block"}></FileRead>
    </div>
    }
}
export class InformationReleaseManagementRules extends PureComponent<{}, {}> {
    render() {
        return <>
        <FileRead fileType={"InformationRelease"} fileState={"block"}></FileRead>
        </>
    }
}