import React, { PureComponent } from "react";
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import source from "./data.json"
import './index.less'
import { Input, Select } from "antd";
type NationPhoneState = {
  phoneCode: string
  phoneNumber: string
}


type NationPhoneProps = {
  style?: React.CSSProperties
  /** NationPhoneCodeSelector 的style */
  leftStyle?: React.CSSProperties
  /** Input 的style */
  rightStyle?: React.CSSProperties

  size?: 'small' | 'middle' | 'large'
  defaultPhoneString?: string
  defaultPhoneCode?: string
  defaultPhoneNumber?: string | number
  onChange: (legacyValue: string, nextValue: { code: string, number: string }) => void
}
export class NationPhone extends PureComponent<NationPhoneProps, NationPhoneState>  {
  constructor(props: NationPhoneProps) {
    super(props);
    console.log("NationPhone", this.props)
    this.state = {
      phoneNumber: props.defaultPhoneNumber ? String(props.defaultPhoneNumber) : "",
      phoneCode: props.defaultPhoneCode ? String(props.defaultPhoneCode) : "86"
    }
  }

  render() {
    return <div className="NationPhone" style={{ marginBottom: 5, ...this.props.style }}>
      <NationPhoneCodeSelector
        size={this.props.size}
        style={{ verticalAlign: "bottom", ...this.props.leftStyle }}
        defaultPhoneCode={this.state.phoneCode}
        onChange={this.onCodeChange.bind(this)}
      />

      <Input
        size={this.props.size}
        style={{ verticalAlign: "bottom", width: 161, ...this.props.rightStyle }}
        placeholder={"请输入手机号"}
        onChange={this.onInputChange.bind(this)}
      />
    </div>
  }
  componentDidUpdate() {
    this.sync()
  }
  onCodeChange(code: string) {
    this.setState({ phoneCode: code })
  }

  onInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    this.setState({ phoneNumber: event.target.value })
  }
  sync() {
    const legacyValue = `+${this.state.phoneCode}${this.state.phoneNumber}`
    if (this.props.onChange) {
      this.props.onChange(legacyValue, {
        code: this.state.phoneCode,
        number: this.state.phoneNumber,
      })
    }
  }


}


type NationPhoneCodeSelectorProps = {
  style?: React.CSSProperties
  size?: 'small' | 'middle' | 'large'
  defaultPhoneCode: string
  onChange: (value: string) => void
}
export class NationPhoneCodeSelector extends PureComponent<NationPhoneCodeSelectorProps>  {
  render() {
    return <Select
      size={this.props.size}
      className="NationPhoneCodeSelector"
      onSelect={this.onSelect.bind(this)}
      showSearch
      defaultValue={this.phoneCode2Key(this.props.defaultPhoneCode || "86")}
      filterOption={true}
      placement={"bottomRight"}
      dropdownMatchSelectWidth={false}
      style={{ width: 84, marginRight: 5, ...this.props.style }}>
      {Array.from(source, (group, gid) => {
        return <Select.OptGroup key={`NationPhoneCodeSelector-${gid}`} label={group.key}>
          {Array.from(group.data, (region, rid) => {
            return <Select.Option key={`NationPhoneCodeSelector-${gid}-${rid}`}
              value={this.region2Key(region)} >
              <span className="NationPhoneCodeSelector-Option ifOption">{region.countryName}</span>
              <span className="NationPhoneCodeSelector-Option ifInput">+{region.phoneCode}</span>
            </Select.Option>
          })}
        </Select.OptGroup>
      })}
    </Select>
  }

  onSelect(value: string,) {
    const phoneCode = value.split("-").shift() || ""
    this.props.onChange(phoneCode)
  }

  phoneCode2Key(phoneCode: string) {
    for (let group of source) {
      for (let region of group.data) {
        if (phoneCode === region.phoneCode) {
          return this.region2Key(region)
        }
      }
    }
  }

  region2Key(region: { countryName: string; countryPinyin: string; phoneCode: string; countryCode: string; }) {
    return `${region.phoneCode}-${region.countryCode}-${region.countryName}-${region.countryPinyin}`
  }
}